import React, { useState, useEffect } from 'react';
import { Star, CheckCircle, XCircle, AlertCircle } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import AdminNavigation from '../../components/navigation/AdminNavigation';

interface Temoignage {
  id: string;
  content: string;
  rating: number;
  created_at: string;
  is_approved: boolean;
  is_featured: boolean;
  sponsor: {
    name: string;
    photo_url: string | null;
    is_anonymous: boolean;
  };
  child: {
    name: string;
    photo_url: string | null;
  };
}

const AdminTestimonials = () => {
  const [temoignages, setTemoignages] = useState<Temoignage[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'pending' | 'approved'>('pending');

  useEffect(() => {
    fetchTemoignages();
  }, []);

  const fetchTemoignages = async () => {
    try {
      const { data, error } = await supabase
        .from('temoignage')
        .select(`
          *,
          sponsor:sponsors(name, photo_url, is_anonymous),
          child:children(name, photo_url)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setTemoignages(data || []);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      toast.error('Erreur lors du chargement des témoignages');
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (id: string) => {
    try {
      const { error } = await supabase
        .from('temoignage')
        .update({ is_approved: true })
        .eq('id', id);

      if (error) throw error;

      setTemoignages(temoignages.map(t =>
        t.id === id ? { ...t, is_approved: true } : t
      ));
      toast.success('Témoignage approuvé');
    } catch (error) {
      console.error('Error approving testimonial:', error);
      toast.error('Erreur lors de l\'approbation');
    }
  };

  const handleReject = async (id: string) => {
    try {
      const { error } = await supabase
        .from('temoignage')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setTemoignages(temoignages.filter(t => t.id !== id));
      toast.success('Témoignage rejeté');
    } catch (error) {
      console.error('Error rejecting testimonial:', error);
      toast.error('Erreur lors du rejet');
    }
  };

  const pendingTemoignages = temoignages.filter(t => !t.is_approved);
  const approvedTemoignages = temoignages.filter(t => t.is_approved);

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />
      
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Gestion des Témoignages</h1>
        <p className="mt-2 text-gray-600">
          Validez et gérez les témoignages des parrains
        </p>
      </div>

      {/* Tabs */}
      <div className="mb-8">
        <nav className="flex space-x-4 border-b border-gray-200">
          <button
            onClick={() => setActiveTab('pending')}
            className={`px-3 py-2 text-sm font-medium border-b-2 ${
              activeTab === 'pending'
                ? 'border-purple-500 text-purple-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            En attente ({pendingTemoignages.length})
          </button>
          <button
            onClick={() => setActiveTab('approved')}
            className={`px-3 py-2 text-sm font-medium border-b-2 ${
              activeTab === 'approved'
                ? 'border-purple-500 text-purple-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Approuvés ({approvedTemoignages.length})
          </button>
        </nav>
      </div>

      {/* Content */}
      <div className="space-y-6">
        {activeTab === 'pending' ? (
          pendingTemoignages.length > 0 ? (
            pendingTemoignages.map((temoignage) => (
              <div
                key={temoignage.id}
                className="bg-white rounded-lg shadow-lg p-6 border-l-4 border-yellow-500"
              >
                <div className="flex justify-between items-start">
                  <div className="flex items-center space-x-4">
                    <img
                      src={temoignage.sponsor.photo_url || 'https://via.placeholder.com/40'}
                      alt={temoignage.sponsor.name}
                      className="h-12 w-12 rounded-full object-cover"
                    />
                    <div>
                      <h3 className="font-medium text-gray-900">{temoignage.sponsor.name}</h3>
                      <p className="text-sm text-gray-500">
                        Parrain de {temoignage.child.name}
                      </p>
                      <p className="text-xs text-gray-400">
                        {format(new Date(temoignage.created_at), 'PPP', { locale: fr })}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleApprove(temoignage.id)}
                      className="p-2 text-green-600 hover:text-green-800 bg-green-100 rounded-full"
                      title="Approuver"
                    >
                      <CheckCircle className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleReject(temoignage.id)}
                      className="p-2 text-red-600 hover:text-red-800 bg-red-100 rounded-full"
                      title="Rejeter"
                    >
                      <XCircle className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="flex space-x-1 mb-2">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`h-4 w-4 ${
                          star <= temoignage.rating
                            ? 'text-yellow-400 fill-current'
                            : 'text-gray-300'
                        }`}
                      />
                    ))}
                  </div>
                  <p className="text-gray-600">{temoignage.content}</p>
                </div>

                <div className="mt-4 flex items-center text-yellow-600 bg-yellow-50 rounded-lg p-3">
                  <AlertTriangle className="h-5 w-5 mr-2" />
                  <span className="text-sm">En attente de validation</span>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-12 bg-white rounded-lg shadow">
              <Star className="mx-auto h-12 w-12 text-gray-300" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                Aucun témoignage en attente
              </h3>
            </div>
          )
        ) : (
          approvedTemoignages.map((temoignage) => (
            <div
              key={temoignage.id}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <div className="flex justify-between items-start">
                <div className="flex items-center space-x-4">
                  <img
                    src={temoignage.sponsor.photo_url || 'https://via.placeholder.com/40'}
                    alt={temoignage.sponsor.name}
                    className="h-12 w-12 rounded-full object-cover"
                  />
                  <div>
                    <h3 className="font-medium text-gray-900">{temoignage.sponsor.name}</h3>
                    <p className="text-sm text-gray-500">
                      Parrain de {temoignage.child.name}
                    </p>
                    <p className="text-xs text-gray-400">
                      {format(new Date(temoignage.created_at), 'PPP', { locale: fr })}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex space-x-1 mb-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      className={`h-4 w-4 ${
                        star <= temoignage.rating
                          ? 'text-yellow-400 fill-current'
                          : 'text-gray-300'
                      }`}
                    />
                  ))}
                </div>
                <p className="text-gray-600">{temoignage.content}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AdminTestimonials;
import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Users, Star, ArrowRight } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Child } from '../types/database.types';
import FeaturedGallery from '../components/home/FeaturedGallery';
import TestimonialSection from '../components/home/TestimonialSection';

const Home = () => {
  const [stats, setStats] = React.useState({
    totalSponsored: 0,
    totalSponsors: 0,
    totalAvailable: 0
  });
  const [loading, setLoading] = React.useState(true);
  const [images, setImages] = React.useState<any[]>([]);
  const isMobile = window.innerWidth <= 768;

  React.useEffect(() => {
    fetchStats();
    fetchHomeImages();

    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      if (newIsMobile !== isMobile) {
        fetchHomeImages();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchStats = async () => {
    try {
      const { data: children, error: childrenError } = await supabase
        .from('children')
        .select('status');

      if (childrenError) throw childrenError;

      const { count: sponsorsCount, error: sponsorsError } = await supabase
        .from('sponsors')
        .select('*', { count: 'exact' });

      if (sponsorsError) throw sponsorsError;

      const sponsored = children?.filter(c => c.status === 'sponsored').length || 0;
      const available = children?.filter(c => c.status === 'available').length || 0;

      setStats({
        totalSponsored: sponsored,
        totalSponsors: sponsorsCount || 0,
        totalAvailable: available
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchHomeImages = async () => {
    try {
      const { data, error } = await supabase
        .from('home_images')
        .select('*')
        .eq('is_mobile', window.innerWidth <= 768);

      if (error) throw error;
      setImages(data || []);
    } catch (error) {
      console.error('Error fetching home images:', error);
    }
  };

  const getBackgroundImage = (position: 'main' | 'secondary') => {
    const image = images.find(img => img.position === position);
    return image?.url || 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&q=80';
  };

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 min-h-[calc(100vh-64px)]">
        {/* Left Panel */}
        <div 
          className="relative bg-gradient-to-br from-havana-400 to-tobacco-400 p-8 lg:p-12 flex flex-col justify-center pattern-dots"
        >
          <div className="max-w-xl mx-auto relative z-10">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 text-shadow-lg">
              Changez une vie, parrainez un enfant cubain
            </h1>
            <p className="text-xl text-white/90 mb-8">
              Offrez un avenir meilleur à un enfant dans le besoin. Votre soutien peut faire la différence.
            </p>
            <div className="flex flex-wrap gap-4">
              <Link
                to="/available-children"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-tobacco-600 bg-white hover:bg-tobacco-50 transition-colors"
              >
                Devenir Parrain
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <Link
                to="/sponsored-children"
                className="inline-flex items-center px-6 py-3 border border-white text-base font-medium rounded-lg text-white hover:bg-white/10 transition-colors"
              >
                Voir les Enfants Parrainés
              </Link>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-3 gap-6 mt-12">
              <div className="text-center">
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-white/20 rounded-full">
                  <Heart className="h-6 w-6 text-white" />
                </div>
                <p className="mt-4 text-3xl font-bold text-white">{stats.totalSponsored}</p>
                <p className="mt-1 text-sm text-white/90">Enfants Parrainés</p>
              </div>
              <div className="text-center">
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-white/20 rounded-full">
                  <Users className="h-6 w-6 text-white" />
                </div>
                <p className="mt-4 text-3xl font-bold text-white">{stats.totalSponsors}</p>
                <p className="mt-1 text-sm text-white/90">Parrains Actifs</p>
              </div>
              <div className="text-center">
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-white/20 rounded-full">
                  <Star className="h-6 w-6 text-white" />
                </div>
                <p className="mt-4 text-3xl font-bold text-white">{stats.totalAvailable}</p>
                <p className="mt-1 text-sm text-white/90">Enfants Disponibles</p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div 
          className="bg-white p-8 lg:p-12 overflow-y-auto pattern-waves"
        >
          <div className="max-w-xl mx-auto">
            {/* Featured Gallery */}
            <div className="mb-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Photos des Parrains</h2>
              <FeaturedGallery />
            </div>

            {/* Testimonial Section */}
            <TestimonialSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Star, Quote, ChevronDown, ChevronUp, Heart } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Child } from '../../types/database.types';

interface TestimonialCollapseProps {
  sponsorId: string;
  children: Child[];
}

interface Testimonial {
  id: string;
  content: string;
  rating: number;
  created_at: string;
  child: {
    name: string;
    photo_url: string | null;
  };
}

const TestimonialCollapse: React.FC<TestimonialCollapseProps> = ({
  sponsorId,
  children
}) => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);
  const [showNewForm, setShowNewForm] = useState(false);
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const [content, setContent] = useState('');
  const [rating, setRating] = useState(5);

  useEffect(() => {
    fetchTestimonials();
  }, [sponsorId]);

  const fetchTestimonials = async () => {
    try {
      const { data, error } = await supabase
        .from('temoignage')
        .select(`
          id,
          content,
          rating,
          created_at,
          child:children!inner(
            name,
            photo_url
          )
        `)
        .eq('sponsor_id', sponsorId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setTestimonials(data || []);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      toast.error('Erreur lors du chargement des témoignages');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedChild) return;

    try {
      const { error } = await supabase
        .from('temoignage')
        .insert({
          sponsor_id: sponsorId,
          child_id: selectedChild.id,
          content,
          rating,
          is_approved: false
        });

      if (error) throw error;

      toast.success('Témoignage envoyé avec succès');
      setShowNewForm(false);
      setContent('');
      setRating(5);
      setSelectedChild(null);
      fetchTestimonials();
    } catch (error) {
      console.error('Error submitting testimonial:', error);
      toast.error('Erreur lors de l\'envoi du témoignage');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-havana-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Existing Testimonials */}
      {testimonials.length > 0 && (
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-gray-900">Mes témoignages</h3>
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white p-6 rounded-lg shadow-sm border border-gray-200"
            >
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-gray-500">
                    Pour {testimonial.child.name}
                  </p>
                  <div className="flex space-x-1 mt-1">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`h-4 w-4 ${
                          star <= testimonial.rating
                            ? 'text-yellow-400 fill-current'
                            : 'text-gray-300'
                        }`}
                      />
                    ))}
                  </div>
                </div>
                <span className="text-sm text-gray-500">
                  {format(new Date(testimonial.created_at), 'PPP', { locale: fr })}
                </span>
              </div>
              <p className="mt-4 text-gray-600">{testimonial.content}</p>
            </div>
          ))}
        </div>
      )}

      {/* New Testimonial Form */}
      <div>
        <button
          onClick={() => setShowNewForm(!showNewForm)}
          className="flex items-center justify-between w-full px-4 py-2 bg-white rounded-lg shadow-sm border border-gray-200"
        >
          <span className="font-medium text-gray-900">
            Ajouter un témoignage
          </span>
          {showNewForm ? (
            <ChevronUp className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-500" />
          )}
        </button>

        <AnimatePresence>
          {showNewForm && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="mt-4 p-4 bg-white rounded-lg shadow-sm border border-gray-200">
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Pour quel enfant ?
                    </label>
                    <select
                      value={selectedChild?.id || ''}
                      onChange={(e) => {
                        const child = children.find(c => c.id === e.target.value);
                        setSelectedChild(child || null);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-havana-500 focus:ring-havana-500"
                      required
                    >
                      <option value="">Sélectionner un enfant</option>
                      {children.map((child) => (
                        <option key={child.id} value={child.id}>
                          {child.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Note
                    </label>
                    <div className="flex space-x-2 mt-1">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <button
                          key={value}
                          type="button"
                          onClick={() => setRating(value)}
                          className="focus:outline-none"
                        >
                          <Star
                            className={`h-6 w-6 ${
                              value <= rating
                                ? 'text-yellow-400 fill-current'
                                : 'text-gray-300'
                            }`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Votre témoignage
                    </label>
                    <textarea
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      rows={4}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-havana-500 focus:ring-havana-500"
                      placeholder="Partagez votre expérience..."
                      required
                    />
                  </div>

                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowNewForm(false)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 text-sm font-medium text-white bg-havana-500 hover:bg-havana-600 rounded-md"
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TestimonialCollapse;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, Users, Calendar, Gift, Camera } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useSponsorStore } from '../../stores/useSponsorStore';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';
import SponsoredChildList from '../../components/sponsor/SponsoredChildList';
import BirthdayManager from '../../components/sponsor/BirthdayManager';
import PhotoGallery from '../../components/gallery/PhotoGallery';
import AddChildRequest from '../../components/sponsor/AddChildRequest';

const SponsorDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [sponsoredChildren, setSponsoredChildren] = useState<Child[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddChildRequest, setShowAddChildRequest] = useState(false);
  const { sponsor } = useSponsorStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sponsor) {
      navigate('/login');
      return;
    }
    fetchSponsoredChildren();
  }, [sponsor, navigate]);

  const fetchSponsoredChildren = async () => {
    if (!sponsor) return;

    try {
      const { data, error } = await supabase
        .from('sponsorships')
        .select(`
          child:children(*)
        `)
        .eq('sponsor_id', sponsor.id)
        .eq('status', 'active');

      if (error) throw error;

      const children = data?.map(d => d.child).filter(Boolean) || [];
      setSponsoredChildren(children);
    } catch (error) {
      console.error('Error fetching sponsored children:', error);
      toast.error('Erreur lors du chargement des enfants parrainés');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Profile Overview */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-8">
        <div className="bg-gradient-to-r from-red-500 to-red-600 px-6 py-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="h-16 w-16 rounded-full bg-white flex items-center justify-center">
                <Users className="h-8 w-8 text-red-500" />
              </div>
              <div className="ml-6">
                <h1 className="text-2xl font-bold text-white">
                  Bienvenue, {sponsor?.name}
                </h1>
                <p className="text-red-100">Votre espace parrain</p>
              </div>
            </div>
            <button
              onClick={() => setShowAddChildRequest(true)}
              className="flex items-center px-4 py-2 bg-white/10 rounded-md text-white hover:bg-white/20"
            >
              <Heart className="h-5 w-5 mr-2" />
              Ajouter un enfant
            </button>
          </div>
        </div>

        {/* Navigation Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`px-6 py-4 text-sm font-medium ${
                activeTab === 'overview'
                  ? 'border-b-2 border-red-500 text-red-600'
                  : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Vue d'ensemble
            </button>
            <button
              onClick={() => setActiveTab('children')}
              className={`px-6 py-4 text-sm font-medium ${
                activeTab === 'children'
                  ? 'border-b-2 border-red-500 text-red-600'
                  : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Mes Filleuls
            </button>
            <button
              onClick={() => setActiveTab('birthdays')}
              className={`px-6 py-4 text-sm font-medium ${
                activeTab === 'birthdays'
                  ? 'border-b-2 border-red-500 text-red-600'
                  : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Anniversaires
            </button>
            <button
              onClick={() => setActiveTab('photos')}
              className={`px-6 py-4 text-sm font-medium ${
                activeTab === 'photos'
                  ? 'border-b-2 border-red-500 text-red-600'
                  : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Photos
            </button>
          </nav>
        </div>

        {/* Content */}
        <div className="p-6">
          {activeTab === 'overview' && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <div className="flex items-center">
                  <Heart className="h-10 w-10 text-red-500" />
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">Filleuls</h3>
                    <p className="text-3xl font-bold text-red-600">{sponsoredChildren.length}</p>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <div className="flex items-center">
                  <Calendar className="h-10 w-10 text-green-500" />
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">Anniversaires</h3>
                    <p className="text-3xl font-bold text-green-600">À venir</p>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
                <div className="flex items-center">
                  <Camera className="h-10 w-10 text-blue-500" />
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">Photos</h3>
                    <p className="text-3xl font-bold text-blue-600">Galerie</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'children' && (
            <SponsoredChildList children={sponsoredChildren} />
          )}

          {activeTab === 'birthdays' && (
            <BirthdayManager />
          )}

          {activeTab === 'photos' && (
            <PhotoGallery />
          )}
        </div>
      </div>

      {/* Add Child Request Modal */}
      {showAddChildRequest && sponsor && (
        <AddChildRequest
          sponsorId={sponsor.id}
          onClose={() => setShowAddChildRequest(false)}
        />
      )}
    </div>
  );
};

export default SponsorDashboard;
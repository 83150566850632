import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../lib/supabase';
import type { Sponsor } from '../types/database.types';

interface AuthState {
  sponsor: Sponsor | null;
  isLoading: boolean;
  error: string | null;
  isAuthenticated: boolean;
  isFirstLogin: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateProfile: (data: Partial<Sponsor>) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
  clearError: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      sponsor: null,
      isLoading: false,
      error: null,
      isAuthenticated: false,
      isFirstLogin: false,

      login: async (email: string, password: string) => {
        set({ isLoading: true, error: null });
        try {
          const { data: sponsor, error } = await supabase
            .from('sponsors')
            .select('*')
            .eq('email', email.toLowerCase().trim())
            .eq('password_hash', password)
            .single();

          if (error) throw error;
          if (!sponsor) {
            throw new Error('Email ou mot de passe incorrect');
          }

          // Update last login
          await supabase
            .from('sponsors')
            .update({ 
              last_login: new Date().toISOString()
            })
            .eq('id', sponsor.id);

          set({ 
            sponsor,
            isAuthenticated: true,
            isFirstLogin: password === 'touspourcuba',
            error: null 
          });

          // Return early to avoid redirect if password change is required
          if (password === 'touspourcuba') {
            throw new Error('CHANGE_PASSWORD_REQUIRED');
          }

          // Determine redirect path based on role
          let redirectPath = '/profile';
          if (sponsor.role === 'admin') {
            redirectPath = '/admin';
          } else if (sponsor.role === 'assistant') {
            redirectPath = '/assistant';
          }

          // Use window.location for hard redirect to ensure clean state
          window.location.href = redirectPath;

        } catch (error) {
          const message = error instanceof Error ? error.message : 'Une erreur est survenue';
          set({ error: message, isAuthenticated: false });
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      logout: async () => {
        set({ 
          sponsor: null,
          isAuthenticated: false,
          error: null,
          isFirstLogin: false
        });
        window.location.href = '/';
      },

      updateProfile: async (data: Partial<Sponsor>) => {
        const { sponsor } = get();
        if (!sponsor) throw new Error('Non authentifié');

        try {
          const { error } = await supabase
            .from('sponsors')
            .update(data)
            .eq('id', sponsor.id);

          if (error) throw error;

          set({ sponsor: { ...sponsor, ...data } });
        } catch (error) {
          const message = error instanceof Error ? error.message : 'Une erreur est survenue';
          set({ error: message });
          throw error;
        }
      },

      changePassword: async (currentPassword: string, newPassword: string) => {
        const { sponsor } = get();
        if (!sponsor) throw new Error('Non authentifié');

        try {
          // Verify current password
          const { data: verifyData, error: verifyError } = await supabase
            .from('sponsors')
            .select('id')
            .eq('id', sponsor.id)
            .eq('password_hash', currentPassword)
            .maybeSingle();

          if (verifyError || !verifyData) {
            throw new Error('Mot de passe actuel incorrect');
          }

          // Update password hash
          const { error: updateError } = await supabase
            .from('sponsors')
            .update({ 
              password_hash: newPassword,
              last_login: new Date().toISOString()
            })
            .eq('id', sponsor.id);

          if (updateError) throw updateError;

          set({ 
            sponsor: { 
              ...sponsor,
              password_hash: newPassword
            },
            isFirstLogin: false
          });

          // Redirect based on role after password change
          let redirectPath = '/profile';
          if (sponsor.role === 'admin') {
            redirectPath = '/admin';
          } else if (sponsor.role === 'assistant') {
            redirectPath = '/assistant';
          }
          window.location.href = redirectPath;

        } catch (error) {
          const message = error instanceof Error ? error.message : 'Une erreur est survenue';
          set({ error: message });
          throw error;
        }
      },

      clearError: () => set({ error: null })
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ 
        sponsor: state.sponsor,
        isAuthenticated: state.isAuthenticated,
        isFirstLogin: state.isFirstLogin
      })
    }
  )
);
import React, { useState, useEffect } from 'react';
import { Table, Users, Search, Filter, ChevronDown, ChevronUp, Edit2, Trash2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import type { Sponsor } from '../types/database.types';
import EditSponsorModal from '../components/sponsors/EditSponsorModal';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../hooks/usePermissions';

const SponsorsTable = () => {
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<keyof Sponsor>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [selectedSponsor, setSelectedSponsor] = useState<Sponsor | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const { hasRole } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasRole('admin')) {
      navigate('/');
      return;
    }
    fetchSponsors();
  }, [hasRole, navigate]);

  const fetchSponsors = async () => {
    try {
      const { data, error } = await supabase
        .from('sponsors')
        .select('*')
        .order('name');

      if (error) throw error;
      setSponsors(data || []);
    } catch (error) {
      console.error('Error fetching sponsors:', error);
      toast.error('Erreur lors du chargement des parrains');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (sponsorId: string) => {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce parrain ?')) return;

    try {
      const { error } = await supabase
        .from('sponsors')
        .delete()
        .eq('id', sponsorId);

      if (error) throw error;

      setSponsors(prev => prev.filter(s => s.id !== sponsorId));
      toast.success('Parrain supprimé avec succès');
    } catch (error) {
      console.error('Error deleting sponsor:', error);
      toast.error('Erreur lors de la suppression');
    }
  };

  const handleSave = async (sponsorData: Partial<Sponsor>) => {
    try {
      if (selectedSponsor) {
        const { error } = await supabase
          .from('sponsors')
          .update(sponsorData)
          .eq('id', selectedSponsor.id);

        if (error) throw error;

        setSponsors(prev => prev.map(s =>
          s.id === selectedSponsor.id ? { ...s, ...sponsorData } : s
        ));
        toast.success('Parrain mis à jour avec succès');
      } else {
        const { data, error } = await supabase
          .from('sponsors')
          .insert([sponsorData])
          .select()
          .single();

        if (error) throw error;

        setSponsors(prev => [...prev, data]);
        toast.success('Parrain ajouté avec succès');
      }

      setShowEditModal(false);
      setSelectedSponsor(null);
    } catch (error) {
      console.error('Error saving sponsor:', error);
      toast.error('Erreur lors de l\'enregistrement');
    }
  };

  const filteredSponsors = sponsors.filter(sponsor =>
    (sponsor?.name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (sponsor?.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (sponsor?.city?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  ).sort((a, b) => {
    const aValue = a[sortField]?.toString() || '';
    const bValue = b[sortField]?.toString() || '';
    return sortDirection === 'asc' 
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <Table className="h-6 w-6 text-purple-600 mr-2" />
            <h1 className="text-2xl font-bold text-gray-900">
              Table des Parrains
            </h1>
          </div>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <button
              onClick={() => {
                setSelectedSponsor(null);
                setShowEditModal(true);
              }}
              className="px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-md"
            >
              Ajouter un parrain
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Utilisateur
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ville
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rôle
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Statut
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredSponsors.map((sponsor) => (
                <tr key={sponsor.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <Users className="h-10 w-10 text-gray-400" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {sponsor.name || 'N/A'}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{sponsor.email || 'N/A'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{sponsor.city || 'N/A'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      sponsor.role === 'admin' ? 'bg-purple-100 text-purple-800' :
                      sponsor.role === 'assistant' ? 'bg-blue-100 text-blue-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {sponsor.role === 'admin' ? 'Admin' :
                       sponsor.role === 'assistant' ? 'Assistant' :
                       'Parrain'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      sponsor.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {sponsor.is_active ? 'Actif' : 'Inactif'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => {
                        setSelectedSponsor(sponsor);
                        setShowEditModal(true);
                      }}
                      className="text-blue-600 hover:text-blue-900 mr-4"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(sponsor.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showEditModal && (
        <EditSponsorModal
          sponsor={selectedSponsor}
          isOpen={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setSelectedSponsor(null);
          }}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default SponsorsTable;
import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { User, LogOut, Settings, Shield, Users, Heart } from 'lucide-react';
import { useAuthStore } from '../../stores/useAuthStore';
import { usePermissions } from '../../hooks/usePermissions';
import { toast } from 'sonner';

const UserMenu = () => {
  const { sponsor, logout } = useAuthStore();
  const { hasRole } = usePermissions();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate('/');
      toast.success('Déconnexion réussie');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Erreur lors de la déconnexion');
    }
  }, [logout, navigate]);

  if (!sponsor) {
    return (
      <Link
        to="/login"
        className="text-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium"
      >
        Se connecter
      </Link>
    );
  }

  return (
    <div className="flex items-center">
      <Menu as="div" className="relative ml-3">
        <Menu.Button className="flex items-center p-2 text-gray-700 hover:text-red-500 rounded-full hover:bg-gray-100">
          <span className="sr-only">Menu utilisateur</span>
          <User className="h-6 w-6" />
        </Menu.Button>

        <Menu.Items className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50 py-1">
          <Menu.Item>
            {({ active }) => (
              <Link
                to="/profile"
                className={`${
                  active ? 'bg-gray-50' : ''
                } flex items-center px-4 py-2 text-sm text-gray-700`}
              >
                <Settings className="h-4 w-4 mr-2" />
                Mon Profil
              </Link>
            )}
          </Menu.Item>

          {hasRole('admin') && (
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/admin"
                  className={`${
                    active ? 'bg-gray-50' : ''
                  } flex items-center px-4 py-2 text-sm text-purple-600`}
                >
                  <Shield className="h-4 w-4 mr-2" />
                  Administration
                </Link>
              )}
            </Menu.Item>
          )}

          <div className="border-t border-gray-100">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleLogout}
                  className={`${
                    active ? 'bg-gray-50' : ''
                  } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
                >
                  <LogOut className="h-4 w-4 mr-2" />
                  Déconnexion
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default UserMenu;
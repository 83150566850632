import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../stores/useAuthStore';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Heart } from 'lucide-react';
import { toast } from 'sonner';
import PasswordChange from '../components/sponsor/PasswordChange';

const loginSchema = z.object({
  email: z.string().email('Email invalide'),
  password: z.string().min(1, 'Mot de passe requis')
});

type LoginFormData = z.infer<typeof loginSchema>;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isLoading, error, clearError, isFirstLogin, isAuthenticated, sponsor } = useAuthStore(state => ({
    login: state.login,
    isLoading: state.isLoading,
    error: state.error,
    clearError: state.clearError,
    isFirstLogin: state.isFirstLogin,
    isAuthenticated: state.isAuthenticated,
    sponsor: state.sponsor
  }));
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema)
  });

  useEffect(() => {
    clearError();
    if (isAuthenticated && !isFirstLogin) {
      // Use setTimeout to avoid state updates during render
      setTimeout(() => {
        const destination = location.state?.from?.pathname || getDefaultRoute(sponsor?.role);
        navigate(destination, { replace: true });
      }, 0);
    }
  }, [isAuthenticated, isFirstLogin, sponsor, navigate, location, clearError]);

  const getDefaultRoute = (role?: string) => {
    switch (role) {
      case 'admin':
        return '/admin';
      case 'assistant':
        return '/assistant';
      default:
        return '/sponsor-dashboard';
    }
  };

  const onSubmit = async (data: LoginFormData) => {
    try {
      await login(data.email, data.password);
      // Move toast to next tick to avoid state updates during render
      setTimeout(() => {
        toast.success('Connexion réussie');
      }, 0);
    } catch (error) {
      if (error instanceof Error) {
        setTimeout(() => {
          toast.error(error.message);
        }, 0);
      } else {
        setTimeout(() => {
          toast.error('Erreur de connexion');
        }, 0);
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="flex justify-center">
            <Heart className="h-12 w-12 text-red-500" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Espace Personnel
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Connectez-vous pour accéder à votre espace
          </p>
          {error && (
            <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
              {error}
            </div>
          )}
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">Email</label>
              <input
                id="email"
                type="email"
                {...register('email')}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Email"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Mot de passe</label>
              <input
                id="password"
                type="password"
                {...register('password')}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Mot de passe"
              />
              {errors.password && (
                <p className="mt-1 text-sm text-red-600">{errors.password.message}</p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
              ) : (
                'Se connecter'
              )}
            </button>
          </div>

          <div className="text-sm text-center">
            <p className="text-gray-600">
              Mot de passe par défaut : <span className="font-medium">touspourcuba</span>
            </p>
          </div>
        </form>
      </div>

      {isFirstLogin && sponsor && (
        <PasswordChange
          onClose={() => {
            navigate(getDefaultRoute(sponsor.role), { replace: true });
          }}
          isFirstLogin={true}
        />
      )}
    </div>
  );
};

export default Login;
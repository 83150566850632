import { supabase } from '../lib/supabase';

export async function sendMessage({
  recipientId,
  subject,
  content,
  senderRole = 'system',
  parentId = null
}: {
  recipientId: string;
  subject: string;
  content: string;
  senderRole?: 'admin' | 'assistant' | 'sponsor' | 'system';
  parentId?: string | null;
}) {
  try {
    const { error } = await supabase
      .from('messages')
      .insert({
        recipient_id: recipientId,
        subject,
        content,
        sender_role: senderRole,
        parent_id: parentId,
        is_read: false,
        created_at: new Date().toISOString()
      });

    if (error) throw error;

    // Create notification for recipient
    await supabase
      .from('notifications')
      .insert({
        recipient_id: recipientId,
        type: 'message',
        title: 'Nouveau message',
        content: `Vous avez reçu un nouveau message : ${subject}`,
        link: '/messages',
        created_at: new Date().toISOString()
      });

  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
}

export async function markAsRead(messageId: string) {
  try {
    const { error } = await supabase
      .from('messages')
      .update({ is_read: true })
      .eq('id', messageId);

    if (error) throw error;
  } catch (error) {
    console.error('Error marking message as read:', error);
    throw error;
  }
}

export async function toggleStarred(messageId: string, currentValue: boolean) {
  try {
    const { error } = await supabase
      .from('messages')
      .update({ is_starred: !currentValue })
      .eq('id', messageId);

    if (error) throw error;
  } catch (error) {
    console.error('Error toggling starred status:', error);
    throw error;
  }
}

export async function toggleArchived(messageId: string, currentValue: boolean) {
  try {
    const { error } = await supabase
      .from('messages')
      .update({ is_archived: !currentValue })
      .eq('id', messageId);

    if (error) throw error;
  } catch (error) {
    console.error('Error toggling archived status:', error);
    throw error;
  }
}

export async function deleteMessage(messageId: string) {
  try {
    const { error } = await supabase
      .from('messages')
      .delete()
      .eq('id', messageId);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting message:', error);
    throw error;
  }
}
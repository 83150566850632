import React, { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = ['#3B82F6', '#10B981', '#8B5CF6', '#F59E0B', '#EF4444'];

interface EngagementAnalyticsProps {
  dateRange: {
    start: string;
    end: string;
  };
}

const EngagementAnalytics: React.FC<EngagementAnalyticsProps> = ({ dateRange }) => {
  const [analytics, setAnalytics] = useState({
    testimonialStats: [] as any[],
    photoEngagement: [] as any[],
    sponsorActivity: [] as any[]
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnalytics();
  }, [dateRange]);

  const fetchAnalytics = async () => {
    try {
      // Fetch testimonial statistics
      const { data: testimonials, error: testimonialError } = await supabase
        .from('testimonials')
        .select('rating, created_at')
        .gte('created_at', dateRange.start)
        .lte('created_at', dateRange.end);

      if (testimonialError) throw testimonialError;

      // Group testimonials by rating
      const testimonialStats = testimonials.reduce((acc: any, curr) => {
        const rating = curr.rating.toString();
        if (!acc[rating]) {
          acc[rating] = { rating: `${rating} étoiles`, count: 0 };
        }
        acc[rating].count++;
        return acc;
      }, {});

      // Fetch photo engagement
      const { data: photos, error: photoError } = await supabase
        .from('album_media')
        .select('created_at, type')
        .gte('created_at', dateRange.start)
        .lte('created_at', dateRange.end);

      if (photoError) throw photoError;

      // Group photos by month
      const photoEngagement = photos.reduce((acc: any, curr) => {
        const month = new Date(curr.created_at).toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'long'
        });
        if (!acc[month]) {
          acc[month] = { month, photos: 0, videos: 0 };
        }
        if (curr.type === 'image') {
          acc[month].photos++;
        } else {
          acc[month].videos++;
        }
        return acc;
      }, {});

      // Fetch sponsor activity
      const { data: activity, error: activityError } = await supabase
        .from('sponsorships')
        .select('created_at, status')
        .gte('created_at', dateRange.start)
        .lte('created_at', dateRange.end);

      if (activityError) throw activityError;

      // Group activity by month
      const sponsorActivity = activity.reduce((acc: any, curr) => {
        const month = new Date(curr.created_at).toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'long'
        });
        if (!acc[month]) {
          acc[month] = { month, active: 0, pending: 0 };
        }
        if (curr.status === 'active') {
          acc[month].active++;
        } else if (curr.status === 'pending') {
          acc[month].pending++;
        }
        return acc;
      }, {});

      setAnalytics({
        testimonialStats: Object.values(testimonialStats),
        photoEngagement: Object.values(photoEngagement),
        sponsorActivity: Object.values(sponsorActivity)
      });
    } catch (error) {
      console.error('Error fetching analytics:', error);
      toast.error('Erreur lors du chargement des analyses');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Testimonial Statistics */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Distribution des Témoignages
        </h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={analytics.testimonialStats}
                dataKey="count"
                nameKey="rating"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {analytics.testimonialStats.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Photo Engagement */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Engagement Média
        </h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={analytics.photoEngagement}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="photos" name="Photos" fill="#3B82F6" />
              <Bar dataKey="videos" name="Vidéos" fill="#10B981" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Sponsor Activity */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Activité des Parrains
        </h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={analytics.sponsorActivity}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="active"
                stroke="#10B981"
                name="Parrainages Actifs"
              />
              <Line
                type="monotone"
                dataKey="pending"
                stroke="#F59E0B"
                name="En Attente"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default EngagementAnalytics;

import React, { useState } from 'react';

const AddChildModal: React.FC = () => {
  const [language, setLanguage] = useState<'fr' | 'es'>('fr');

  const translations = {
    fr: {
      title: "Ajouter un enfant",
      name: "Nom",
      age: "Âge",
      submit: "Soumettre",
      cancel: "Annuler"
    },
    es: {
      title: "Añadir un niño",
      name: "Nombre",
      age: "Edad",
      submit: "Enviar",
      cancel: "Cancelar"
    }
  };

  const t = translations[language];

  return (
    <div className="modal">
      <div className="language-switcher">
        <button onClick={() => setLanguage('fr')}>FR</button>
        <button onClick={() => setLanguage('es')}>ES</button>
      </div>
      <h1>{t.title}</h1>
      <form>
        <label>{t.name}</label>
        <input type="text" name="name" />
        <label>{t.age}</label>
        <input type="number" name="age" />
        <button type="submit">{t.submit}</button>
        <button type="button">{t.cancel}</button>
      </form>
    </div>
  );
};

export default AddChildModal;

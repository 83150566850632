import React, { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Gift, MapPin, Users, Camera, ChevronDown, ChevronUp } from 'lucide-react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const DonationTable = () => {
  const [donations, setDonations] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [expandedDonation, setExpandedDonation] = useState<string | null>(null);
  const [currentImage, setCurrentImage] = useState(-1);
  const [photos, setPhotos] = useState<any[]>([]);

  useEffect(() => {
    fetchDonations();
  }, []);

  const fetchDonations = async () => {
    try {
      const { data, error } = await supabase
        .from('donations')
        .select(`
          *,
          photos:donation_photos(*)
        `)
        .order('donation_date', { ascending: false });

      if (error) throw error;
      setDonations(data || []);
    } catch (error) {
      console.error('Error fetching donations:', error);
      toast.error('Erreur lors du chargement des dons');
    } finally {
      setLoading(false);
    }
  };

  const handleExpandPhotos = async (donationPhotos: any[]) => {
    const transformedPhotos = await Promise.all(
      donationPhotos.map(async (photo) => {
        const img = new Image();
        img.src = photo.url;
        await new Promise((resolve) => {
          img.onload = resolve;
        });
        return {
          src: photo.url,
          width: img.width || 800,
          height: img.height || 600,
          title: photo.title
        };
      })
    );
    setPhotos(transformedPhotos);
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {donations.length > 0 ? (
        donations.map((donation) => (
          <div
            key={donation.id}
            className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="p-4">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-sm text-gray-500">
                    {format(new Date(donation.donation_date), 'PPP', { locale: fr })}
                  </p>
                  <div className="mt-1 flex items-center text-sm text-gray-500">
                    <MapPin className="h-4 w-4 mr-1" />
                    {donation.city}
                  </div>
                  <div className="mt-1 flex items-center text-sm text-gray-500">
                    <Users className="h-4 w-4 mr-1" />
                    {donation.people_helped} personnes aidées
                  </div>
                </div>
                <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                  donation.status === 'completed' ? 'bg-green-100 text-green-800' :
                  donation.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-gray-100 text-gray-800'
                }`}>
                  {donation.status === 'completed' ? 'Complété' :
                   donation.status === 'pending' ? 'En attente' : 'Annulé'}
                </span>
              </div>
              {donation.comments && (
                <p className="mt-2 text-sm text-gray-600">{donation.comments}</p>
              )}

              {/* Photos Section */}
              {donation.photos?.length > 0 && (
                <div className="mt-4">
                  <button
                    onClick={() => {
                      if (expandedDonation === donation.id) {
                        setExpandedDonation(null);
                      } else {
                        setExpandedDonation(donation.id);
                        handleExpandPhotos(donation.photos);
                      }
                    }}
                    className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
                  >
                    <Camera className="h-4 w-4 mr-1" />
                    Voir les photos ({donation.photos.length})
                    {expandedDonation === donation.id ? (
                      <ChevronUp className="h-4 w-4 ml-1" />
                    ) : (
                      <ChevronDown className="h-4 w-4 ml-1" />
                    )}
                  </button>

                  {expandedDonation === donation.id && (
                    <div className="mt-4">
                      <PhotoAlbum
                        photos={photos}
                        layout="rows"
                        spacing={8}
                        onClick={({ index }) => setCurrentImage(index)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <Gift className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun don</h3>
          <p className="mt-1 text-gray-500">Aucun don n'a encore été enregistré.</p>
        </div>
      )}

      <Lightbox
        open={currentImage >= 0}
        index={currentImage}
        close={() => setCurrentImage(-1)}
        slides={photos}
      />
    </div>
  );
};

export default DonationTable;
import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/database.types';

const supabaseUrl = 'https://pedwjkjpckjlwbxfsmth.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBlZHdqa2pwY2tqbHdieGZzbXRoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE4NzMxODcsImV4cCI6MjA0NzQ0OTE4N30.O7MUaPJkpNG1JekWOH3BaXsx5QxvAPRQKmsoANzEQiw';
const supabaseServiceKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBlZHdqa2pwY2tqbHdieGZzbXRoIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczMTg3MzE4NywiZXhwIjoyMDQ3NDQ5MTg3fQ.KUIA7wkS3Pf9egDpomlni6b3NDhBCP-brVepM3PboKQ';

// Create regular client for normal operations
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});

// Create admin client with service role for privileged operations
export const supabaseAdmin = createClient(supabaseUrl, supabaseServiceKey, {
  auth: {
    autoRefreshToken: false,
    persistSession: false
  }
});

// Helper function to handle Supabase errors
export function handleSupabaseError(error: unknown): string {
  if (error instanceof Error) {
    if (error.message === 'CHANGE_PASSWORD_REQUIRED') {
      return error.message;
    }
    if (error.message.includes('PGRST116')) {
      return 'Aucun résultat trouvé';
    }
    if (error.message.includes('Failed to fetch')) {
      return 'Erreur de connexion au serveur. Veuillez vérifier votre connexion internet.';
    }
    return error.message;
  }
  return 'Une erreur inattendue est survenue';
}
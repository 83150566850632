import { supabase } from '../lib/supabase';
import { toast } from 'sonner';

export type NotificationType = 
  | 'message'
  | 'sponsorship_approved'
  | 'sponsorship_rejected'
  | 'photo_added'
  | 'need_update'
  | 'child_update'
  | 'system';

export async function createNotification({
  recipientId,
  type,
  title,
  content,
  link
}: {
  recipientId: string;
  type: NotificationType;
  title: string;
  content: string;
  link?: string;
}) {
  try {
    const { error } = await supabase
      .from('notifications')
      .insert({
        recipient_id: recipientId,
        type,
        title,
        content,
        link,
        is_read: false
      });

    if (error) throw error;
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
}

export async function sendMessageNotification(
  recipientId: string,
  senderName: string,
  subject: string
) {
  try {
    await createNotification({
      recipientId,
      type: 'message',
      title: `Nouveau message de ${senderName}`,
      content: `Vous avez reçu un nouveau message : ${subject}`,
      link: '/messages'
    });
  } catch (error) {
    console.error('Error sending message notification:', error);
    throw error;
  }
}

export async function sendSponsorshipRequestNotification(
  childName: string,
  sponsorName: string,
  adminEmails: string[]
) {
  try {
    // Get admin IDs from emails
    const { data: admins, error: adminsError } = await supabase
      .from('sponsors')
      .select('id')
      .eq('role', 'admin')
      .in('email', adminEmails);

    if (adminsError) throw adminsError;

    // Send notification to each admin
    await Promise.all(
      admins.map(admin =>
        createNotification({
          recipientId: admin.id,
          type: 'system',
          title: 'Nouvelle demande de parrainage',
          content: `${sponsorName} souhaite parrainer ${childName}. Veuillez examiner cette demande.`,
          link: '/admin/pending-sponsorships'
        })
      )
    );
  } catch (error) {
    console.error('Error sending sponsorship request notification:', error);
    throw error;
  }
}

export async function sendSponsorshipDecisionNotification(
  recipientId: string,
  childName: string,
  approved: boolean
) {
  try {
    await createNotification({
      recipientId,
      type: approved ? 'sponsorship_approved' : 'sponsorship_rejected',
      title: approved ? 'Parrainage approuvé' : 'Parrainage refusé',
      content: approved 
        ? `Votre demande de parrainage pour ${childName} a été approuvée.`
        : `Votre demande de parrainage pour ${childName} n'a pas été retenue.`,
      link: approved ? '/sponsor-dashboard' : '/available-children'
    });
  } catch (error) {
    console.error('Error sending sponsorship decision notification:', error);
    throw error;
  }
}
import React, { useState, useEffect } from 'react';
import { Image as ImageIcon, Film } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import type { Child } from '../../types/database.types';

interface AlbumMedia {
  id: string;
  url: string;
  type: 'image' | 'video';
  created_at: string;
}

interface AlbumViewerProps {
  child?: Child;
  canEdit?: boolean;
  isDonationAlbum?: boolean;
}

const AlbumViewer: React.FC<AlbumViewerProps> = ({
  child,
  canEdit = false,
  isDonationAlbum = false
}) => {
  const [media, setMedia] = useState<AlbumMedia[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    fetchAlbumMedia();
  }, [child?.id, isDonationAlbum]);

  const fetchAlbumMedia = async () => {
    try {
      let query = supabase
        .from('album_media')
        .select('*')
        .order('created_at', { ascending: false });

      if (isDonationAlbum) {
        query = query.is('child_id', null);
      } else if (child) {
        query = query.eq('child_id', child.id);
      }

      const { data, error } = await query;

      if (error) throw error;
      setMedia(data || []);
    } catch (error) {
      console.error('Error fetching album:', error);
      toast.error('Erreur lors du chargement de l\'album');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      {media.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {media.map((item, index) => (
            <div
              key={item.id}
              className="relative group cursor-pointer"
              onClick={() => setSelectedIndex(index)}
            >
              {item.type === 'video' ? (
                <div className="relative aspect-square">
                  <video
                    src={item.url}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <Film className="absolute inset-0 m-auto h-8 w-8 text-white" />
                </div>
              ) : (
                <img
                  src={item.url}
                  alt=""
                  className="w-full aspect-square object-cover rounded-lg"
                  loading="lazy"
                />
              )}
              <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity rounded-lg" />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Album vide</h3>
          <p className="mt-1 text-sm text-gray-500">
            Aucune photo ou vidéo n'a encore été ajoutée.
          </p>
        </div>
      )}

      <Lightbox
        open={selectedIndex >= 0}
        index={selectedIndex}
        close={() => setSelectedIndex(-1)}
        slides={media.map(item => ({
          src: item.url,
          type: item.type,
          ...(item.type === 'video' && {
            sources: [{ src: item.url, type: 'video/mp4' }]
          })
        }))}
        plugins={[Video]}
      />
    </div>
  );
};

export default AlbumViewer;
import React, { useState } from 'react';
import { Edit2, Trash2, Book, Gift, ShoppingBag, Apple, Pill, Droplets, HelpCircle, Camera, Calendar, MapPin } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child, Need } from '../../types/database.types';
import { DEFAULT_PROFILE_IMAGE } from '../../constants/images';
import NeedsList from '../needs/NeedsList';
import PhotoUpload from '../shared/PhotoUpload';
import AlbumViewer from '../album/AlbumViewer';

interface ChildDetailsProps {
  child: Child;
  onUpdate: (updatedChild: Child) => void;
  onDelete: (childId: string) => void;
}

const ChildDetails: React.FC<ChildDetailsProps> = ({
  child,
  onUpdate,
  onDelete
}) => {
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);

  const handleNeedsUpdate = async (updatedNeeds: Need[]) => {
    try {
      const { error } = await supabase
        .from('child_needs')
        .upsert(
          updatedNeeds.map(need => ({
            child_id: child.id,
            category: need.category,
            description: need.description,
            is_urgent: need.is_urgent
          }))
        );

      if (error) throw error;

      onUpdate({
        ...child,
        needs: updatedNeeds
      });

      toast.success('Besoins mis à jour avec succès');
    } catch (error) {
      console.error('Error updating needs:', error);
      toast.error('Erreur lors de la mise à jour des besoins');
    }
  };

  const handlePhotoSuccess = async (photoUrl: string) => {
    try {
      const { error } = await supabase
        .from('children')
        .update({ photo_url: photoUrl })
        .eq('id', child.id);

      if (error) throw error;

      onUpdate({
        ...child,
        photo_url: photoUrl
      });

      setShowPhotoUpload(false);
      toast.success('Photo mise à jour avec succès');
    } catch (error) {
      console.error('Error updating photo:', error);
      toast.error('Erreur lors de la mise à jour de la photo');
    }
  };

  const getNeedIcon = (category: string) => {
    switch (category) {
      case 'education':
        return <Book className="h-4 w-4 text-blue-500" />;
      case 'jouet':
        return <Gift className="h-4 w-4 text-purple-500" />;
      case 'vetement':
        return <ShoppingBag className="h-4 w-4 text-green-500" />;
      case 'nourriture':
        return <Apple className="h-4 w-4 text-red-500" />;
      case 'medicament':
        return <Pill className="h-4 w-4 text-pink-500" />;
      case 'hygiene':
        return <Droplets className="h-4 w-4 text-cyan-500" />;
      default:
        return <HelpCircle className="h-4 w-4 text-gray-500" />;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="p-6">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-6">
            <div className="relative">
              <img
                src={child.photo_url || DEFAULT_PROFILE_IMAGE}
                alt={child.name}
                className="h-24 w-24 rounded-lg object-cover"
              />
              <button
                onClick={() => setShowPhotoUpload(true)}
                className="absolute -bottom-2 -right-2 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
              >
                <Camera className="h-4 w-4 text-gray-600" />
              </button>
            </div>
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                {child.name}
              </h3>
              <div className="mt-1 space-y-1">
                <p className="text-gray-600 flex items-center">
                  <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                  {child.age} ans
                </p>
                <p className="text-gray-600 flex items-center">
                  <MapPin className="h-4 w-4 mr-2 text-gray-400" />
                  {child.city}
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <button
              onClick={() => onDelete(child.id)}
              className="p-2 text-red-600 hover:text-red-800 bg-red-100 rounded-full"
              title="Supprimer"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="mt-6">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Besoins :</h4>
          <NeedsList
            childId={child.id}
            needs={child.needs}
            onUpdate={handleNeedsUpdate}
          />
        </div>

        <div className="mt-6">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Album photos :</h4>
          <AlbumViewer child={child} canEdit={true} />
        </div>
      </div>

      {showPhotoUpload && (
        <PhotoUpload
          currentPhotoUrl={child.photo_url}
          onPhotoSelect={handlePhotoSuccess}
          onClose={() => setShowPhotoUpload(false)}
        />
      )}
    </div>
  );
};

export default ChildDetails;
import React, { useState } from 'react';
import { Plus, Edit2, Trash2, Save } from 'lucide-react';
import { EMAIL_TEMPLATES } from '../../lib/emailService';
import { toast } from 'sonner';

interface EmailTemplatesProps {
  onSelect?: (template: typeof EMAIL_TEMPLATES[0]) => void;
}

const EmailTemplates: React.FC<EmailTemplatesProps> = ({ onSelect }) => {
  const [editingTemplate, setEditingTemplate] = useState<string | null>(null);
  const [editedContent, setEditedContent] = useState('');

  const handleEdit = (templateId: string, content: string) => {
    setEditingTemplate(templateId);
    setEditedContent(content);
  };

  const handleSave = async () => {
    try {
      // Save template changes here
      toast.success('Modèle mis à jour avec succès');
      setEditingTemplate(null);
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Erreur lors de la sauvegarde du modèle');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Modèles d'Email</h3>
        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
          <Plus className="h-4 w-4 mr-2" />
          Nouveau modèle
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {EMAIL_TEMPLATES.map((template) => (
          <div
            key={template.id}
            className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden"
          >
            <div className="p-4">
              <div className="flex justify-between items-start mb-4">
                <h4 className="font-medium text-gray-900">{template.name}</h4>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleEdit(template.id, template.content)}
                    className="p-1 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-50"
                    title="Modifier"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  {onSelect && (
                    <button
                      onClick={() => onSelect(template)}
                      className="p-1 text-green-600 hover:text-green-800 rounded-full hover:bg-green-50"
                      title="Utiliser ce modèle"
                    >
                      <Plus className="h-4 w-4" />
                    </button>
                  )}
                  <button className="p-1 text-red-600 hover:text-red-800 rounded-full hover:bg-red-50">
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </div>

              <p className="text-sm text-gray-600 font-medium mb-2">
                {template.subject}
              </p>

              {editingTemplate === template.id ? (
                <div className="space-y-4">
                  <textarea
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                    rows={6}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => setEditingTemplate(null)}
                      className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={handleSave}
                      className="inline-flex items-center px-3 py-1 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md"
                    >
                      <Save className="h-4 w-4 mr-1" />
                      Enregistrer
                    </button>
                  </div>
                </div>
              ) : (
                <div className="bg-gray-50 rounded-md p-3">
                  <pre className="text-sm text-gray-600 whitespace-pre-wrap font-sans">
                    {template.content}
                  </pre>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailTemplates;
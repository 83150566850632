import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import { format, addYears } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Gift, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Initialize moment locale
moment.locale('fr');

// Create the localizer
const localizer = momentLocalizer(moment);

interface BirthdayEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  child: Child;
}

interface BirthdayCalendarProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectChild: (child: Child) => void;
  sponsorId: string | undefined;
}

const BirthdayCalendar: React.FC<BirthdayCalendarProps> = ({
  isOpen,
  onClose,
  onSelectChild,
  sponsorId
}) => {
  const [events, setEvents] = useState<BirthdayEvent[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sponsorId) {
      fetchSponsoredChildrenBirthdays();
    }
  }, [sponsorId]);

  const fetchSponsoredChildrenBirthdays = async () => {
    if (!sponsorId) return;

    try {
      const { data: sponsorships, error } = await supabase
        .from('sponsorships')
        .select(`
          child:children(*)
        `)
        .eq('sponsor_id', sponsorId)
        .eq('status', 'active');

      if (error) throw error;

      const birthdays = sponsorships
        .map(s => s.child)
        .filter(Boolean)
        .map(child => {
          const birthDate = new Date(child.birth_date);
          const nextBirthday = addYears(birthDate, child.age + 1);
          
          return {
            id: child.id,
            title: `${child.name} fête ses ${child.age + 1} ans!`,
            start: nextBirthday,
            end: nextBirthday,
            child
          };
        });

      setEvents(birthdays);
    } catch (error) {
      console.error('Error fetching birthdays:', error);
      toast.error('Erreur lors du chargement des anniversaires');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center">
            <Gift className="h-6 w-6 text-havana-500 mr-2" />
            Calendrier des Anniversaires
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-havana-500"></div>
          </div>
        ) : (
          <div className="h-[600px]">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              views={['month', 'agenda']}
              defaultView="month"
              onSelectEvent={(event: BirthdayEvent) => onSelectChild(event.child)}
              messages={{
                next: 'Suivant',
                previous: 'Précédent',
                today: 'Aujourd\'hui',
                month: 'Mois',
                agenda: 'Agenda'
              }}
              eventPropGetter={() => ({
                className: 'bg-havana-500 text-white rounded-lg border-none cursor-pointer hover:bg-havana-600'
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BirthdayCalendar;
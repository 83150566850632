import { useCallback } from 'react';
import { useSponsorStore } from '../stores/useSponsorStore';
import type { UserRole, Permissions } from '../types/database.types';

export function usePermissions() {
  const { sponsor } = useSponsorStore();

  const hasPermission = useCallback((permission: keyof Permissions): boolean => {
    if (!sponsor) return false;

    // Admin always has all permissions
    if (sponsor.role === 'admin') return true;

    // Assistant has specific permissions
    if (sponsor.role === 'assistant') {
      const assistantPermissions = [
        'view_children',
        'edit_children',
        'manage_donations',
        'upload_photos',
        'upload_videos',
        'send_messages',
        'view_messages'
      ];
      return assistantPermissions.includes(permission);
    }

    // Check specific permission from sponsor's permissions object
    return !!sponsor.permissions?.[permission];
  }, [sponsor]);

  const hasRole = useCallback((role: UserRole | string): boolean => {
    if (!sponsor) return false;
    
    // Direct role comparison for admin
    if (role === 'admin') {
      return sponsor.role === 'admin';
    }
    
    // Assistant role includes admin
    if (role === 'assistant') {
      return sponsor.role === 'admin' || sponsor.role === 'assistant';
    }
    
    // Sponsor role includes admin and assistant
    if (role === 'sponsor') {
      return ['admin', 'assistant', 'sponsor'].includes(sponsor.role);
    }
    
    // Direct role comparison for other roles
    return sponsor.role === role;
  }, [sponsor]);

  return {
    hasPermission,
    hasRole,
    isAuthenticated: !!sponsor,
    role: sponsor?.role || 'visitor'
  };
}

export default usePermissions;
import { supabase } from '../lib/supabase';
import type { SponsorshipFormData } from '../types/sponsorship.types';
import { sendSponsorshipRequestNotification } from '../utils/notifications';

export async function createSponsorshipRequest(
  childId: string,
  formData: SponsorshipFormData
) {
  try {
    // Check if child is available
    const { data: child, error: childError } = await supabase
      .from('children')
      .select('status, name')
      .eq('id', childId)
      .single();

    if (childError) throw childError;
    if (child.status !== 'available') {
      throw new Error('Cet enfant n\'est plus disponible pour le parrainage');
    }

    // Check if sponsor exists with email
    let sponsorId: string | null = null;
    if (formData.email) {
      const { data: existingSponsor } = await supabase
        .from('sponsors')
        .select('id')
        .eq('email', formData.email.toLowerCase())
        .maybeSingle();

      if (existingSponsor) {
        sponsorId = existingSponsor.id;
        
        // Update existing sponsor
        const { error: updateError } = await supabase
          .from('sponsors')
          .update({
            name: formData.name,
            city: formData.city,
            phone: formData.phone,
            facebook_url: formData.facebook_url,
            is_anonymous: formData.is_anonymous,
            show_name_publicly: formData.show_name_publicly
          })
          .eq('id', sponsorId);

        if (updateError) throw updateError;
      }
    }

    // Create new sponsor if needed
    if (!sponsorId) {
      const { data: newSponsor, error: createError } = await supabase
        .from('sponsors')
        .insert({
          name: formData.name,
          email: formData.email,
          city: formData.city,
          phone: formData.phone,
          facebook_url: formData.facebook_url,
          is_anonymous: formData.is_anonymous,
          show_name_publicly: formData.show_name_publicly,
          role: 'sponsor',
          password_hash: 'touspourcuba'
        })
        .select('id')
        .single();

      if (createError) throw createError;
      if (!newSponsor) throw new Error('Failed to create sponsor');
      sponsorId = newSponsor.id;
    }

    // Create sponsorship request
    const { error: sponsorshipError } = await supabase
      .from('sponsorships')
      .insert({
        child_id: childId,
        sponsor_id: sponsorId,
        status: 'pending',
        start_date: new Date().toISOString()
      });

    if (sponsorshipError) throw sponsorshipError;

    // Update child status
    const { error: updateError } = await supabase
      .from('children')
      .update({ status: 'pending' })
      .eq('id', childId);

    if (updateError) throw updateError;

    // Send notification to admins
    const { data: admins } = await supabase
      .from('sponsors')
      .select('email')
      .eq('role', 'admin')
      .not('email', 'is', null);

    if (admins && admins.length > 0) {
      await sendSponsorshipRequestNotification(
        child.name,
        formData.name,
        admins.map(admin => admin.email as string)
      );
    }

    return { success: true };
  } catch (error) {
    console.error('Error creating sponsorship request:', error);
    throw error;
  }
}
import React, { useState, useEffect } from 'react';
import { Star, Quote, ChevronLeft, ChevronRight, Heart } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { motion, AnimatePresence } from 'framer-motion';

interface Testimonial {
  id: string;
  content: string;
  rating: number;
  sponsor: {
    name: string;
    photo_url: string | null;
    is_anonymous: boolean;
  };
  child: {
    name: string;
    photo_url: string | null;
  };
}

const TestimonialSection = () => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (testimonials.length > 1) {
        setCurrentIndex((prev) => (prev + 1) % testimonials.length);
      }
    }, 8000);
    return () => clearInterval(interval);
  }, [testimonials.length]);

  const fetchTestimonials = async () => {
    try {
      const { data, error } = await supabase
        .from('temoignage')
        .select(`
          id,
          content,
          rating,
          sponsor:sponsors(
            name,
            photo_url,
            is_anonymous
          ),
          child:children(
            name,
            photo_url
          )
        `)
        .eq('is_approved', true)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setTestimonials(data || []);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      toast.error('Erreur lors du chargement des témoignages');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (testimonials.length === 0) {
    return null;
  }

  return (
    <section className="py-12 bg-gradient-to-br from-red-50 to-orange-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">
            Ce que disent nos parrains
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Découvrez l'impact de nos parrainages à travers les témoignages de notre communauté
          </p>
        </div>

        <div className="relative max-w-4xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="bg-white rounded-xl shadow-xl p-8"
            >
              <div className="relative">
                <Quote className="absolute -top-4 -left-4 h-8 w-8 text-red-200 transform -rotate-12" />
                <div className="text-center">
                  <div className="flex justify-center space-x-1 mb-6">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`h-5 w-5 ${
                          star <= testimonials[currentIndex].rating
                            ? 'text-yellow-400 fill-current'
                            : 'text-gray-300'
                        }`}
                      />
                    ))}
                  </div>

                  <blockquote className="text-xl text-gray-900 mb-8">
                    "{testimonials[currentIndex].content}"
                  </blockquote>

                  <div className="flex items-center justify-center space-x-8">
                    <div className="flex items-center">
                      <div className="relative">
                        <img
                          src={testimonials[currentIndex].sponsor.photo_url || 'https://via.placeholder.com/40'}
                          alt={testimonials[currentIndex].sponsor.is_anonymous ? 'Anonyme' : testimonials[currentIndex].sponsor.name}
                          className="h-12 w-12 rounded-full object-cover"
                        />
                        <div className="absolute -bottom-1 -right-1 h-5 w-5 bg-red-500 rounded-full flex items-center justify-center">
                          <Heart className="h-3 w-3 text-white" />
                        </div>
                      </div>
                      <div className="ml-3 text-left">
                        <p className="text-sm font-medium text-gray-900">
                          {testimonials[currentIndex].sponsor.is_anonymous 
                            ? 'Parrain anonyme' 
                            : testimonials[currentIndex].sponsor.name}
                        </p>
                        <p className="text-xs text-gray-500">Parrain</p>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <img
                        src={testimonials[currentIndex].child.photo_url || 'https://via.placeholder.com/40'}
                        alt={testimonials[currentIndex].child.name}
                        className="h-12 w-12 rounded-full object-cover"
                      />
                      <div className="ml-3 text-left">
                        <p className="text-sm font-medium text-gray-900">
                          {testimonials[currentIndex].child.name}
                        </p>
                        <p className="text-xs text-gray-500">Filleul(e)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          {testimonials.length > 1 && (
            <>
              <button
                onClick={() => setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length)}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 p-2 rounded-full bg-white shadow-lg text-gray-600 hover:text-gray-900"
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <button
                onClick={() => setCurrentIndex((prev) => (prev + 1) % testimonials.length)}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 p-2 rounded-full bg-white shadow-lg text-gray-600 hover:text-gray-900"
              >
                <ChevronRight className="h-6 w-6" />
              </button>
            </>
          )}
        </div>

        <div className="flex justify-center mt-8">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`h-2 w-2 rounded-full mx-1 transition-colors ${
                index === currentIndex ? 'bg-red-500' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
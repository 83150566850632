import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'sonner';
import App from './App';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import './index.css';

// Initialize Supabase auth state
import { supabase } from './lib/supabase';

// Set up auth state listener for Supabase
supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_IN') {
    console.log('User signed in:', session?.user?.id);
  } else if (event === 'SIGNED_OUT') {
    console.log('User signed out');
    // Redirect to home page on sign out
    window.location.href = '/';
  }
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

// Render the React application
createRoot(rootElement).render(
  <StrictMode>
    <App />
    <Toaster position="top-right" richColors closeButton />
  </StrictMode>
);

import React from 'react';
import { Settings, Heart, Calendar, Image, Star } from 'lucide-react';
import { motion } from 'framer-motion';

export type TabType = 'profile' | 'children' | 'birthdays' | 'photos' | 'testimonials';

interface ProfileTabsProps {
  activeTab: TabType;
  onTabChange: (tab: TabType) => void;
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ activeTab, onTabChange }) => {
  const tabs = [
    { id: 'profile', label: 'Paramètres', icon: Settings },
    { id: 'children', label: 'Mes Filleuls', icon: Heart },
    { id: 'birthdays', label: 'Anniversaires', icon: Calendar },
    { id: 'photos', label: 'Photos', icon: Image },
    { id: 'testimonials', label: 'Témoignages', icon: Star }
  ] as const;

  return (
    <nav className="bg-havana-600/10 backdrop-blur-sm border-b border-white/10">
      <div className="px-6 flex space-x-8 overflow-x-auto scrollbar-hide">
        {tabs.map(({ id, label, icon: Icon }) => (
          <motion.button
            key={id}
            onClick={() => onTabChange(id as TabType)}
            className={`
              relative py-4 px-1 flex items-center whitespace-nowrap
              text-sm font-medium transition-colors duration-200
              ${activeTab === id 
                ? 'text-white' 
                : 'text-white/70 hover:text-white'
              }
            `}
            whileHover={{ y: -2 }}
            whileTap={{ scale: 0.95 }}
          >
            <Icon className="h-5 w-5 inline-block mr-2" />
            <span className="hidden sm:inline">{label}</span>
            {activeTab === id && (
              <motion.div
                layoutId="activeTab"
                className="absolute bottom-0 left-0 right-0 h-0.5 bg-white"
                initial={false}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              />
            )}
          </motion.button>
        ))}
      </div>
    </nav>
  );
};

export default ProfileTabs;
import React, { useState, useEffect } from 'react';
import { Heart, AlertCircle, ChevronDown, ChevronUp, X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import type { Child, Sponsor } from '../types/database.types';

interface SponsoredChild extends Child {
  sponsor?: Sponsor;
  needs: Array<{ description: string; is_urgent: boolean }>;
  description?: string;
  story?: string;
}

interface ChildCardProps {
  child: SponsoredChild;
}

const calculateAge = (birthDate: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
};

const DetailsModal: React.FC<{ child: SponsoredChild; onClose: () => void }> = ({ child, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>

        <div className="p-6">
          <div className="flex gap-6 mb-6">
            <div className="relative w-32 h-32 flex-shrink-0">
              <img
                src={child.photo_url || 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&q=80'}
                alt={child.name}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-havana-800 mb-2">{child.name}</h2>
              <div className="flex items-center text-sm text-havana-600">
                <span>{calculateAge(new Date(child.birth_date))} ans</span>
                <span className="mx-2">•</span>
                <span>{child.city}</span>
              </div>
            </div>
          </div>

          {child.description && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-havana-800 mb-2">Description</h3>
              <p className="text-havana-700">{child.description}</p>
            </div>
          )}

          {child.story && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-havana-800 mb-2">Son histoire</h3>
              <p className="text-havana-700 whitespace-pre-line">{child.story}</p>
            </div>
          )}

          {child.needs && child.needs.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-havana-800 mb-2">Besoins</h3>
              <div className="space-y-2">
                {child.needs.map((need, index) => (
                  <div 
                    key={index} 
                    className={`flex items-center gap-2 ${
                      need.is_urgent ? 'text-red-600' : 'text-havana-600'
                    }`}
                  >
                    {need.is_urgent && <AlertCircle className="h-4 w-4 flex-shrink-0" />}
                    <span>{need.description}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {child.sponsor && !child.sponsor.is_anonymous && (
            <div className="mt-6 pt-6 border-t">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-colonial-600">Parrainé par</p>
                  <p className="font-medium text-havana-800">{child.sponsor.name}</p>
                  {child.sponsor.city && (
                    <p className="text-sm text-tobacco-600">{child.sponsor.city}</p>
                  )}
                </div>
                <Heart className="h-5 w-5 text-sunset-500" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ChildCard: React.FC<ChildCardProps> = ({ child }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
        <div className="flex items-start p-4 gap-4">
          <div className="relative w-24 h-24 flex-shrink-0">
            <img
              src={child.photo_url || 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&q=80'}
              alt={child.name}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <div className="flex-1">
            <h3 className="text-xl font-bold text-havana-800">{child.name}</h3>
            <div className="flex items-center mt-1 text-sm text-havana-600">
              <span>{calculateAge(new Date(child.birth_date))} ans</span>
              <span className="mx-2">•</span>
              <span>{child.city}</span>
            </div>

            {child.description && (
              <p className="mt-2 text-sm text-havana-700 line-clamp-2">
                {child.description}
              </p>
            )}
            
            {child.needs && child.needs.length > 0 && (
              <div className="mt-3">
                <div className={`space-y-2 ${!isExpanded ? 'line-clamp-2' : ''}`}>
                  {child.needs.map((need, index) => (
                    <div 
                      key={index} 
                      className={`flex items-center text-sm gap-2 ${
                        need.is_urgent ? 'text-red-600' : 'text-havana-600'
                      }`}
                    >
                      {need.is_urgent && <AlertCircle className="h-4 w-4 flex-shrink-0" />}
                      <span>{need.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="mt-3 flex gap-2">
              {(child.needs?.length > 2 || child.story) && (
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="flex items-center text-sm font-medium text-tobacco-600 hover:text-tobacco-700 transition-colors"
                >
                  {isExpanded ? (
                    <>
                      <span>Voir moins</span>
                      <ChevronUp className="h-4 w-4 ml-1" />
                    </>
                  ) : (
                    <>
                      <span>Voir plus</span>
                      <ChevronDown className="h-4 w-4 ml-1" />
                    </>
                  )}
                </button>
              )}
              <button
                onClick={() => setShowDetails(true)}
                className="flex items-center text-sm font-medium text-havana-600 hover:text-havana-700 transition-colors ml-auto"
              >
                <span>Voir tout</span>
                <ChevronDown className="h-4 w-4 ml-1" />
              </button>
            </div>
          </div>
        </div>

        {child.sponsor && !child.sponsor.is_anonymous && (
          <div className="p-4 bg-havana-50 border-t border-havana-100 mt-auto">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-colonial-600">Parrainé par</p>
                <p className="font-medium text-havana-800">{child.sponsor.name}</p>
                {child.sponsor.city && (
                  <p className="text-sm text-tobacco-600">{child.sponsor.city}</p>
                )}
              </div>
              <Heart className="h-5 w-5 text-sunset-500" />
            </div>
          </div>
        )}
      </div>

      {showDetails && (
        <DetailsModal child={child} onClose={() => setShowDetails(false)} />
      )}
    </>
  );
};

const SponsoredChildren: React.FC = () => {
  const [children, setChildren] = useState<SponsoredChild[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSponsoredChildren();
  }, []);

  const fetchSponsoredChildren = async () => {
    try {
      const { data: sponsoredChildren, error: childrenError } = await supabase
        .from('children')
        .select(`
          *,
          needs,
          description,
          story
        `)
        .eq('status', 'sponsored')
        .order('name');

      if (childrenError) throw childrenError;

      const { data: sponsors, error: sponsorsError } = await supabase
        .from('sponsors')
        .select('*')
        .eq('role', 'sponsor');

      if (sponsorsError) throw sponsorsError;

      const childrenWithSponsors = sponsoredChildren.map(child => {
        const sponsor = sponsors.find(s => 
          s.city === child.city && 
          !sponsoredChildren.some(c => c.sponsor?.id === s.id)
        );

        return {
          ...child,
          sponsor: sponsor || undefined
        };
      });

      setChildren(childrenWithSponsors);
    } catch (error) {
      console.error('Error fetching sponsored children:', error);
      toast.error('Erreur lors du chargement des enfants parrainés');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-havana-50 via-colonial-50 to-tobacco-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-havana-800 mb-4">
            Enfants Parrainés
          </h1>
          <p className="max-w-2xl mx-auto text-lg text-havana-600">
            Ces enfants ont déjà trouvé leur parrain ou marraine. Merci à tous nos généreux donateurs !
          </p>
        </div>

        {children.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {children.map((child) => (
              <ChildCard key={child.id} child={child} />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-white rounded-xl shadow-lg">
            <Heart className="mx-auto h-16 w-16 text-sunset-300" />
            <h3 className="mt-4 text-lg font-medium text-havana-800">
              Aucun enfant parrainé
            </h3>
            <p className="mt-2 text-tobacco-600 max-w-sm mx-auto">
              Il n'y a actuellement aucun enfant parrainé. Consultez la liste des enfants disponibles pour commencer un parrainage.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SponsoredChildren;
import React from 'react';
import { Film } from 'lucide-react';
import VideoPlayer from '../components/video/VideoPlayer';
import ShareButton from '../components/shared/ShareButton';

const DonationVideos = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-havana-50 to-tobacco-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-havana-900">
              Vidéos des Dons
            </h1>
            <p className="mt-2 text-havana-600">
              Découvrez l'impact de vos dons à travers nos vidéos
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <Film className="h-8 w-8 text-havana-500" />
            <ShareButton
              url={window.location.href}
              title="Découvrez l'impact de nos dons à Cuba"
              description="Regardez comment nos dons transforment la vie des enfants cubains. Votre aide peut faire la différence !"
              hashtags={['TousPourCuba', 'DonsCuba', 'ImpactPositif']}
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <VideoPlayer />
        </div>
      </div>
    </div>
  );
};

export default DonationVideos;
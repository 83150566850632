import React, { useState, useEffect } from 'react';
import { Plus, Search, Filter } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';
import { CUBAN_CITIES } from '../../constants/cities';
import ChildrenTable from '../../components/assistant/ChildrenTable';
import EditChildModal from '../../components/assistant/EditChildModal';

const AssistantChildren = () => {
  const [children, setChildren] = useState<Child[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [cityFilter, setCityFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    fetchChildren();
  }, []);

  const fetchChildren = async () => {
    try {
      const { data, error } = await supabase
        .from('children')
        .select('*')
        .order('name');

      if (error) throw error;
      setChildren(data || []);
    } catch (error) {
      console.error('Error fetching children:', error);
      toast.error('Erreur lors du chargement des enfants');
    } finally {
      setLoading(false);
    }
  };

  const handleAddChild = () => {
    setSelectedChild(null);
    setShowEditModal(true);
  };

  const handleEditChild = (child: Child) => {
    setSelectedChild(child);
    setShowEditModal(true);
  };

  const handleSaveChild = async (childData: Partial<Child>) => {
    try {
      if (selectedChild) {
        const { error } = await supabase
          .from('children')
          .update(childData)
          .eq('id', selectedChild.id);

        if (error) throw error;
        toast.success('Enfant mis à jour avec succès');
      } else {
        const { error } = await supabase
          .from('children')
          .insert([childData]);

        if (error) throw error;
        toast.success('Enfant ajouté avec succès');
      }

      setShowEditModal(false);
      fetchChildren();
    } catch (error) {
      console.error('Error saving child:', error);
      toast.error('Erreur lors de l\'enregistrement');
    }
  };

  const filteredChildren = children.filter(child => {
    const matchesSearch = child.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         child.city.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCity = cityFilter === 'all' || child.city === cityFilter;
    const matchesStatus = statusFilter === 'all' || child.status === statusFilter;
    return matchesSearch && matchesCity && matchesStatus;
  });

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Gestion des Enfants</h1>
          <button
            onClick={handleAddChild}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Ajouter un Enfant
          </button>
        </div>

        {/* Filters */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher un enfant..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <div className="flex items-center space-x-2">
            <Filter className="h-5 w-5 text-gray-400" />
            <select
              value={cityFilter}
              onChange={(e) => setCityFilter(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="all">Toutes les villes</option>
              {CUBAN_CITIES.map(city => (
                <option key={city.value} value={city.value}>
                  {city.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-2">
            <Filter className="h-5 w-5 text-gray-400" />
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="all">Tous les statuts</option>
              <option value="available">Disponible</option>
              <option value="sponsored">Parrainé</option>
              <option value="pending">En attente</option>
            </select>
          </div>
        </div>

        {/* Children Table */}
        <ChildrenTable
          children={filteredChildren}
          onEdit={handleEditChild}
        />
      </div>

      {/* Edit Modal */}
      {showEditModal && (
        <EditChildModal
          child={selectedChild}
          onClose={() => setShowEditModal(false)}
          onSave={handleSaveChild}
        />
      )}
    </div>
  );
};

export default AssistantChildren;
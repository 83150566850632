import React from 'react';
import AdminDashboard from '../pages/admin/AdminDashboard';
import AdminSponsors from '../pages/admin/AdminSponsors';
import AdminChildren from '../pages/admin/AdminChildren';
import AdminDonations from '../pages/admin/AdminDonations';
import AdminMedia from '../pages/admin/AdminMedia';
import AdminSettings from '../pages/admin/AdminSettings';
import AdminStatistics from '../pages/admin/AdminStatistics';
import AdminEmailTesting from '../pages/admin/AdminEmailTesting';
import AdminTestimonials from '../pages/admin/AdminTestimonials';
import AdminVideoManagement from '../pages/admin/AdminVideoManagement';
import PendingSponsorships from '../pages/PendingSponsorships';
import HomeManager from '../pages/HomeManager';
import MediaEditor from '../pages/MediaEditor';
import SponsorsTable from '../pages/SponsorsTable';
import NewDonation from '../pages/NewDonation';
import RoleManagement from '../pages/RoleManagement';

export const adminRoutes = [
  { path: '/admin', element: <AdminDashboard /> },
  { path: '/admin/sponsors', element: <AdminSponsors /> },
  { path: '/admin/children', element: <AdminChildren /> },
  { path: '/admin/child-needs', element: <AdminChildren /> },
  { path: '/admin/pending-sponsorships', element: <PendingSponsorships /> },
  { path: '/admin/home', element: <HomeManager /> },
  { path: '/admin/media-editor', element: <MediaEditor /> },
  { path: '/admin/sponsors-table', element: <SponsorsTable /> },
  { path: '/admin/donations', element: <AdminDonations /> },
  { path: '/admin/new-donation', element: <NewDonation /> },
  { path: '/admin/media', element: <AdminMedia /> },
  { path: '/admin/videos', element: <AdminVideoManagement /> },
  { path: '/admin/testimonials', element: <AdminTestimonials /> },
  { path: '/admin/email-testing', element: <AdminEmailTesting /> },
  { path: '/admin/statistics', element: <AdminStatistics /> },
  { path: '/admin/analytics', element: <AdminStatistics /> },
  { path: '/admin/roles', element: <RoleManagement /> }
];
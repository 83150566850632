import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useSponsorStore } from '../stores/useSponsorStore';
import { toast } from 'sonner';
import type { Child } from '../types/database.types';

import ProfileLayout from '../components/profile/ProfileLayout';
import ProfileForm from '../components/profile/ProfileForm';
import PhotoUpload from '../components/shared/PhotoUpload';
import PasswordChange from '../components/sponsor/PasswordChange';
import SponsoredChildList from '../components/sponsor/SponsoredChildList';
import BirthdayManager from '../components/sponsor/BirthdayManager';
import PhotoGallery from '../components/gallery/PhotoGallery';
import TestimonialCollapse from '../components/testimonials/TestimonialCollapse';

import type { TabType } from '../components/profile/ProfileTabs';

const ProfileSettings = () => {
  const [activeTab, setActiveTab] = useState<TabType>('profile');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sponsoredChildren, setSponsoredChildren] = useState<Child[]>([]);
  const { sponsor, updateProfile } = useSponsorStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sponsor) {
      navigate('/login');
      return;
    }
    fetchSponsoredChildren();
  }, [sponsor, navigate]);

  const fetchSponsoredChildren = async () => {
    if (!sponsor) return;
    try {
      const { data, error } = await supabase
        .from('sponsorships')
        .select('child:children(*)')
        .eq('sponsor_id', sponsor.id)
        .eq('status', 'active');

      if (error) throw error;
      setSponsoredChildren(data?.map(d => d.child).filter(Boolean) || []);
    } catch (error) {
      console.error('Error fetching sponsored children:', error);
      toast.error('Erreur lors du chargement des enfants parrainés');
    }
  };

  const handlePhotoSuccess = async (photoUrl: string) => {
    try {
      await updateProfile({ photo_url: photoUrl });
      setShowPhotoUpload(false);
      toast.success('Photo mise à jour avec succès');
    } catch (error) {
      console.error('Error updating photo:', error);
      toast.error('Erreur lors de la mise à jour de la photo');
    }
  };

  if (!sponsor) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <ProfileLayout
        sponsor={sponsor}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onPhotoClick={() => setShowPhotoUpload(true)}
      >
        {activeTab === 'profile' && (
          <ProfileForm
            sponsor={sponsor}
            loading={loading}
            onUpdate={updateProfile}
            onPasswordChange={() => setShowPasswordModal(true)}
          />
        )}

        {activeTab === 'children' && (
          <SponsoredChildList children={sponsoredChildren} />
        )}

        {activeTab === 'birthdays' && (
          <BirthdayManager />
        )}

        {activeTab === 'photos' && (
          <PhotoGallery />
        )}

        {activeTab === 'testimonials' && (
          <TestimonialCollapse
            sponsorId={sponsor.id}
            children={sponsoredChildren}
          />
        )}
      </ProfileLayout>

      {showPhotoUpload && (
        <PhotoUpload
          onSuccess={handlePhotoSuccess}
          onClose={() => setShowPhotoUpload(false)}
        />
      )}

      {showPasswordModal && (
        <PasswordChange
          onClose={() => setShowPasswordModal(false)}
        />
      )}
    </div>
  );
};

export default ProfileSettings;
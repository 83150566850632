import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useLanguageDetection } from './hooks/useLanguageDetection';
import MainLayout from './components/layout/MainLayout';

// Page imports
import Home from './pages/Home';
import Login from './pages/Login';
import ProfileSettings from './pages/ProfileSettings';
import AvailableChildren from './pages/AvailableChildren';
import SponsoredChildren from './pages/SponsoredChildren';
import SponsorshipHistory from './pages/SponsorshipHistory';
import DonationTracker from './pages/DonationTracker';
import DonationVideos from './pages/DonationVideos';
import FAQ from './pages/FAQ';
import Messages from './pages/Messages';
import ChildProfile from './pages/ChildProfile';
import SponsorDashboard from './pages/sponsor/SponsorDashboard';
import PublicAlbum from './pages/PublicAlbum';

// Protected route components
import ProtectedRoute from './components/auth/ProtectedRoute';
import AdminRoute from './components/auth/AdminRoute';
import AssistantRoute from './components/auth/AssistantRoute';

// Admin and Assistant routes
import { adminRoutes } from './routes/adminRoutes';
import { assistantRoutes } from './routes/assistantRoutes';

const App = () => {
  useLanguageDetection();

  // Get base path from window
  const basePath = (window as any).__BASE_PATH__ || '/';

  return (
    <Router basename={basePath}>
      <MainLayout>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/available-children" element={<AvailableChildren />} />
          <Route path="/sponsored-children" element={<SponsoredChildren />} />
          <Route path="/sponsorship-history" element={<SponsorshipHistory />} />
          <Route path="/donations" element={<DonationTracker />} />
          <Route path="/donation-videos" element={<DonationVideos />} />
          <Route path="/public-album" element={<PublicAlbum />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/child/:id" element={<ChildProfile />} />
          
          {/* Protected routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<ProfileSettings />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/sponsor-dashboard" element={<SponsorDashboard />} />
          </Route>

          {/* Admin routes */}
          <Route element={<AdminRoute />}>
            {adminRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>

          {/* Assistant routes */}
          <Route element={<AssistantRoute />}>
            {assistantRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>

          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </MainLayout>
    </Router>
  );
};

export default App;
import emailjs from '@emailjs/browser';
import { supabase } from './supabase';

emailjs.init(import.meta.env.VITE_EMAILJS_PUBLIC_KEY);

interface EmailParams {
  to_email: string;
  to_name: string;
  subject: string;
  message: string;
  isHtml?: boolean;
  from_name?: string;
}

interface EmailTemplate {
  id: string;
  name: string;
  subject: string;
  content: string;
}

export const EMAIL_TEMPLATES: EmailTemplate[] = [
  {
    id: 'welcome',
    name: 'Message de bienvenue',
    subject: 'Bienvenue sur TousPourCuba',
    content: `Bienvenue sur TousPourCuba !

Nous sommes ravis de vous compter parmi nous. Votre compte a été créé avec succès.

Vous pouvez dès maintenant accéder à votre espace parrain et découvrir les enfants disponibles pour le parrainage.

L'équipe TousPourCuba`
  },
  {
    id: 'password-reset',
    name: 'Réinitialisation mot de passe',
    subject: 'Réinitialisation de votre mot de passe',
    content: `Réinitialisation de votre mot de passe

Voici votre nouveau mot de passe temporaire : {{password}}

Pour des raisons de sécurité, nous vous recommandons de modifier ce mot de passe dès votre prochaine connexion.

L'équipe TousPourCuba`
  },
  {
    id: 'new-photos',
    name: 'Nouvelles photos',
    subject: 'Nouvelles photos de votre filleul',
    content: `Nouvelles photos disponibles !

De nouvelles photos de {{childName}} ont été ajoutées à l'album.
Connectez-vous à votre espace parrain pour les découvrir.

L'équipe TousPourCuba`
  },
  {
    id: 'sponsorship-approved',
    name: 'Parrainage approuvé',
    subject: 'Votre demande de parrainage a été approuvée',
    content: `Félicitations !

Votre demande de parrainage pour {{childName}} a été approuvée.
Vous pouvez dès maintenant accéder à son profil complet et commencer à suivre son évolution.

L'équipe TousPourCuba`
  },
  {
    id: 'sponsorship-rejected',
    name: 'Parrainage refusé',
    subject: 'Votre demande de parrainage n\'a pas été retenue',
    content: `Bonjour,

Nous sommes désolés de vous informer que votre demande de parrainage pour {{childName}} n'a pas pu être retenue.
Nous vous invitons à consulter les autres enfants disponibles pour le parrainage.

L'équipe TousPourCuba`
  },
  {
    id: 'urgent-need',
    name: 'Besoin urgent',
    subject: 'Besoin urgent pour {{childName}}',
    content: `Bonjour,

Un nouveau besoin urgent a été identifié pour {{childName}} :
{{needDescription}}

Merci de votre attention à ce besoin urgent.

L'équipe TousPourCuba`
  },
  {
    id: 'birthday-reminder',
    name: 'Rappel anniversaire',
    subject: 'Anniversaire à venir - {{childName}}',
    content: `Bonjour,

Nous vous rappelons que {{childName}} fêtera ses {{age}} ans le {{date}}.

L'équipe TousPourCuba`
  }
];

export const sendEmail = async (params: EmailParams): Promise<emailjs.EmailJSResponseStatus> => {
  try {
    // Always convert to plain text
    const messageContent = params.message
      .replace(/<[^>]*>/g, '') // Remove HTML tags
      .replace(/&nbsp;/g, ' ') // Replace &nbsp; with spaces
      .replace(/\s+/g, ' ') // Normalize whitespace
      .trim();

    const templateParams = {
      to_email: params.to_email,
      to_name: params.to_name,
      subject: params.subject,
      message: messageContent,
      from_name: params.from_name || 'TousPourCuba'
    };

    await emailjs.send(
      import.meta.env.VITE_EMAILJS_SERVICE_ID,
      import.meta.env.VITE_EMAILJS_TEMPLATE_ID,
      templateParams,
      import.meta.env.VITE_EMAILJS_PUBLIC_KEY
    );

    return { status: 200 };
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export const sendBulkEmail = async (recipients: string[], subject: string, content: string) => {
  try {
    const promises = recipients.map(email => 
      sendEmail({
        to_email: email,
        to_name: 'Cher parrain',
        subject,
        message: content,
        isHtml: false
      })
    );

    await Promise.all(promises);
    return true;
  } catch (error) {
    console.error('Error sending bulk email:', error);
    throw error;
  }
};

export const testEmailConfiguration = async (): Promise<emailjs.EmailJSResponseStatus> => {
  try {
    const response = await sendEmail({
      to_email: 'admin@passionvaradero.ca',
      to_name: 'Admin',
      subject: 'Test de configuration email',
      message: `Test de Configuration

Ceci est un email de test pour vérifier la configuration.
Si vous recevez cet email, la configuration est correcte.

© ${new Date().getFullYear()} TousPourCuba`,
      isHtml: false
    });
    // Log email to history
    const { error } = await supabase
      .from('email_history')
      .insert({
        recipient_email: params.to_email,
        subject: params.subject,
        content: params.message,
        status: 'sent'
      });

    if (error) {
      console.error('Error logging email:', error);
    }

    return response;
  } catch (error) {
    console.error('Error testing email configuration:', error);
    throw error;
  }
};

export const getEmailHistory = async () => {
  try {
    const { data, error } = await supabase
      .from('email_queue')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching email history:', error);
    throw error;
  }
};

export const retryFailedEmails = async () => {
  try {
    const { data: failedEmails, error: fetchError } = await supabase
      .from('email_queue')
      .select('*')
      .eq('status', 'failed');

    if (fetchError) throw fetchError;

    for (const email of failedEmails || []) {
      await sendEmail({
        to_email: email.recipient_email,
        to_name: 'Cher parrain',
        subject: email.subject,
        message: email.content
      });

      const { error: updateError } = await supabase
        .from('email_queue')
        .update({
          status: 'sent',
          attempts: email.attempts + 1,
          last_attempt: new Date().toISOString()
        })
        .eq('id', email.id);

      if (updateError) throw updateError;
    }
  } catch (error) {
    console.error('Error retrying failed emails:', error);
    throw error;
  }
};

export const getEmailQueueStatus = async () => {
  try {
    const { data, error } = await supabase
      .from('email_queue')
      .select('status', { count: 'exact' });

    if (error) throw error;

    const { count: total } = data;
    const { count: pending } = await supabase
      .from('email_queue')
      .select('*', { count: 'exact', head: true })
      .eq('status', 'pending');

    const { count: failed } = await supabase
      .from('email_queue')
      .select('*', { count: 'exact', head: true })
      .eq('status', 'failed');

    return {
      total: total || 0,
      pending: pending || 0,
      failed: failed || 0,
      sent: (total || 0) - (pending || 0) - (failed || 0)
    };
  } catch (error) {
    console.error('Error checking queue status:', error);
    throw error;
  }
};
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, CheckCircle, XCircle, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useSponsorStore } from '../stores/useSponsorStore';
import { usePermissions } from '../hooks/usePermissions';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { sendSponsorshipDecisionNotification } from '../utils/notifications';
import AdminNavigation from '../components/navigation/AdminNavigation';

interface PendingSponsorship {
  id: string;
  child: {
    id: string;
    name: string;
    age: number;
    city: string;
    photo_url?: string;
  };
  sponsor: {
    id: string;
    name: string;
    email?: string;
    phone?: string;
    facebook_url?: string;
  };
  start_date: string;
}

const PendingSponsorships = () => {
  const [sponsorships, setSponsorships] = useState<PendingSponsorship[]>([]);
  const [loading, setLoading] = useState(true);
  const { sponsor } = useSponsorStore();
  const { hasRole } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasRole('admin')) {
      navigate('/');
      return;
    }
    fetchPendingSponsorships();
  }, [hasRole, navigate]);

  const fetchPendingSponsorships = async () => {
    try {
      const { data, error } = await supabase
        .from('sponsorships')
        .select(`
          id,
          start_date,
          child:children(
            id,
            name,
            age,
            city,
            photo_url
          ),
          sponsor:sponsors(
            id,
            name,
            email,
            phone,
            facebook_url
          )
        `)
        .eq('status', 'pending')
        .order('start_date', { ascending: false });

      if (error) throw error;
      setSponsorships(data || []);
    } catch (error) {
      console.error('Error fetching pending sponsorships:', error);
      toast.error('Erreur lors du chargement des demandes');
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (sponsorship: PendingSponsorship) => {
    try {
      // Start a transaction
      const updates = [];

      // 1. Update sponsorship status
      updates.push(
        supabase
          .from('sponsorships')
          .update({ 
            status: 'active',
            updated_at: new Date().toISOString()
          })
          .eq('id', sponsorship.id)
      );

      // 2. Update child status
      updates.push(
        supabase
          .from('children')
          .update({ 
            status: 'sponsored',
            is_sponsored: true,
            updated_at: new Date().toISOString()
          })
          .eq('id', sponsorship.child.id)
      );

      // 3. Update sponsor role to 'sponsor' if not already an admin
      updates.push(
        supabase
          .from('sponsors')
          .update({ 
            role: 'sponsor',
            updated_at: new Date().toISOString()
          })
          .eq('id', sponsorship.sponsor.id)
          .not('role', 'eq', 'admin') // Don't change admin roles
      );

      // Execute all updates
      const results = await Promise.all(updates);

      // Check for errors
      const errors = results.filter(result => result.error);
      if (errors.length > 0) {
        throw errors[0].error;
      }

      // Send notification to sponsor
      await sendSponsorshipDecisionNotification(
        sponsorship.sponsor.id,
        sponsorship.child.name,
        true
      );

      toast.success('Parrainage approuvé avec succès');
      setSponsorships(sponsorships.filter(s => s.id !== sponsorship.id));
    } catch (error) {
      console.error('Error approving sponsorship:', error);
      toast.error('Erreur lors de l\'approbation');
    }
  };

  const handleReject = async (sponsorship: PendingSponsorship) => {
    if (!confirm('Êtes-vous sûr de vouloir rejeter cette demande de parrainage ?')) return;

    try {
      // Start a transaction
      const updates = [];

      // 1. Update sponsorship status
      updates.push(
        supabase
          .from('sponsorships')
          .update({ 
            status: 'rejected',
            updated_at: new Date().toISOString()
          })
          .eq('id', sponsorship.id)
      );

      // 2. Update child status back to available
      updates.push(
        supabase
          .from('children')
          .update({ 
            status: 'available',
            is_sponsored: false,
            updated_at: new Date().toISOString()
          })
          .eq('id', sponsorship.child.id)
      );

      // Execute all updates
      const results = await Promise.all(updates);

      // Check for errors
      const errors = results.filter(result => result.error);
      if (errors.length > 0) {
        throw errors[0].error;
      }

      // Send notification to sponsor
      await sendSponsorshipDecisionNotification(
        sponsorship.sponsor.id,
        sponsorship.child.name,
        false
      );

      toast.success('Demande rejetée');
      setSponsorships(sponsorships.filter(s => s.id !== sponsorship.id));
    } catch (error) {
      console.error('Error rejecting sponsorship:', error);
      toast.error('Erreur lors du rejet');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />
      
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Demandes de Parrainage</h1>
        <p className="mt-2 text-gray-600">
          Gérez les demandes de parrainage en attente
        </p>
      </div>

      {sponsorships.length > 0 ? (
        <div className="space-y-6">
          {sponsorships.map((sponsorship) => (
            <div
              key={sponsorship.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="p-6">
                <div className="flex items-start justify-between">
                  <div className="flex items-center space-x-6">
                    <img
                      src={sponsorship.child.photo_url || 'https://via.placeholder.com/100'}
                      alt={sponsorship.child.name}
                      className="h-24 w-24 rounded-lg object-cover"
                    />
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        {sponsorship.child.name}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {sponsorship.child.age} ans • {sponsorship.child.city}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">
                        Demande reçue le {format(new Date(sponsorship.start_date), 'PPP', { locale: fr })}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => handleApprove(sponsorship)}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                    >
                      <CheckCircle className="h-4 w-4 mr-2" />
                      Approuver
                    </button>
                    <button
                      onClick={() => handleReject(sponsorship)}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                    >
                      <XCircle className="h-4 w-4 mr-2" />
                      Rejeter
                    </button>
                  </div>
                </div>

                <div className="mt-6 border-t pt-6">
                  <h4 className="text-sm font-medium text-gray-900 mb-4">
                    Informations du parrain
                  </h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div>
                      <p className="text-sm font-medium text-gray-500">Nom</p>
                      <p className="mt-1">{sponsorship.sponsor.name}</p>
                    </div>
                    {sponsorship.sponsor.email && (
                      <div>
                        <p className="text-sm font-medium text-gray-500">Email</p>
                        <p className="mt-1">{sponsorship.sponsor.email}</p>
                      </div>
                    )}
                    {sponsorship.sponsor.phone && (
                      <div>
                        <p className="text-sm font-medium text-gray-500">Téléphone</p>
                        <p className="mt-1">{sponsorship.sponsor.phone}</p>
                      </div>
                    )}
                    {sponsorship.sponsor.facebook_url && (
                      <div>
                        <p className="text-sm font-medium text-gray-500">Facebook</p>
                        <a
                          href={sponsorship.sponsor.facebook_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-1 text-blue-600 hover:text-blue-800"
                        >
                          Voir le profil
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-lg">
          <Heart className="mx-auto h-12 w-12 text-gray-300" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">
            Aucune demande en attente
          </h3>
          <p className="mt-1 text-gray-500">
            Il n'y a actuellement aucune demande de parrainage à traiter.
          </p>
        </div>
      )}
    </div>
  );
};

export default PendingSponsorships;
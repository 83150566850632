import React, { useState, useEffect } from 'react';
import { Home, Upload, X, Save } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';

interface HomeImage {
  id: string;
  url: string;
  position: 'main' | 'secondary';
  is_mobile: boolean;
}

const HomeManager = () => {
  const [images, setImages] = useState<HomeImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const { data, error } = await supabase
        .from('home_images')
        .select('*')
        .order('position');

      if (error) throw error;
      setImages(data || []);
    } catch (error) {
      console.error('Error fetching images:', error);
      toast.error('Erreur lors du chargement des images');
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxSize: 5 * 1024 * 1024,
    onDrop: async (acceptedFiles) => {
      setUploading(true);
      try {
        for (const file of acceptedFiles) {
          const fileName = `home/${Date.now()}-${file.name}`;
          const { error: uploadError } = await supabase.storage
            .from('home-images')
            .upload(fileName, file);

          if (uploadError) throw uploadError;

          const { data: { publicUrl } } = supabase.storage
            .from('home-images')
            .getPublicUrl(fileName);

          const { error: dbError } = await supabase
            .from('home_images')
            .insert({
              url: publicUrl,
              position: 'main',
              is_mobile: false
            });

          if (dbError) throw dbError;
        }

        fetchImages();
        toast.success('Images ajoutées avec succès');
      } catch (error) {
        console.error('Error uploading images:', error);
        toast.error('Erreur lors du téléchargement des images');
      } finally {
        setUploading(false);
      }
    }
  });

  const handleDelete = async (imageId: string) => {
    if (!confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) return;

    try {
      const { error } = await supabase
        .from('home_images')
        .delete()
        .eq('id', imageId);

      if (error) throw error;

      setImages(images.filter(img => img.id !== imageId));
      toast.success('Image supprimée avec succès');
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error('Erreur lors de la suppression');
    }
  };

  const handlePositionChange = async (imageId: string, position: 'main' | 'secondary') => {
    try {
      const { error } = await supabase
        .from('home_images')
        .update({ position })
        .eq('id', imageId);

      if (error) throw error;

      setImages(images.map(img =>
        img.id === imageId ? { ...img, position } : img
      ));
      toast.success('Position mise à jour');
    } catch (error) {
      console.error('Error updating position:', error);
      toast.error('Erreur lors de la mise à jour');
    }
  };

  const handleDeviceToggle = async (imageId: string, is_mobile: boolean) => {
    try {
      const { error } = await supabase
        .from('home_images')
        .update({ is_mobile })
        .eq('id', imageId);

      if (error) throw error;

      setImages(images.map(img =>
        img.id === imageId ? { ...img, is_mobile } : img
      ));
      toast.success('Version mise à jour');
    } catch (error) {
      console.error('Error updating device version:', error);
      toast.error('Erreur lors de la mise à jour');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <Home className="h-6 w-6 text-purple-600 mr-2" />
            <h1 className="text-2xl font-bold text-gray-900">
              Gestion de la Page d'Accueil
            </h1>
          </div>
          <div {...getRootProps()} className="cursor-pointer">
            <input {...getInputProps()} />
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
              disabled={uploading}
            >
              {uploading ? (
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
              ) : (
                <>
                  <Upload className="h-4 w-4 mr-2" />
                  Ajouter des images
                </>
              )}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {images.map((image) => (
            <div key={image.id} className="bg-white rounded-lg shadow overflow-hidden">
              <div className="relative aspect-video">
                <img
                  src={image.url}
                  alt="Image d'accueil"
                  className="w-full h-full object-cover"
                />
                <button
                  onClick={() => handleDelete(image.id)}
                  className="absolute top-2 right-2 p-1 bg-red-100 rounded-full text-red-600 hover:bg-red-200"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                  <select
                    value={image.position}
                    onChange={(e) => handlePositionChange(image.id, e.target.value as 'main' | 'secondary')}
                    className="rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  >
                    <option value="main">Image Principale</option>
                    <option value="secondary">Image Secondaire</option>
                  </select>
                  <button
                    onClick={() => handleDeviceToggle(image.id, !image.is_mobile)}
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      image.is_mobile
                        ? 'bg-blue-100 text-blue-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {image.is_mobile ? 'Mobile' : 'Desktop'}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {images.length === 0 && (
          <div className="text-center py-12">
            <Home className="mx-auto h-12 w-12 text-gray-300" />
            <p className="mt-2 text-gray-500">Aucune image</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeManager;
import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Calendar, MapPin, Book, Gift, ShoppingBag, Apple, Pill, Droplets, AlertTriangle, Info } from 'lucide-react';
import type { Child, Need } from '../../types/database.types';
import { DEFAULT_PROFILE_IMAGE } from '../../constants/images';

interface ChildCardProps {
  child: Child;
}

const ChildCard: React.FC<ChildCardProps> = ({ child }) => {
  const getNeedIcon = (category: string) => {
    switch (category) {
      case 'education': return <Book className="h-4 w-4 text-colonial-500" />;
      case 'jouet': return <Gift className="h-4 w-4 text-sunset-500" />;
      case 'vetement': return <ShoppingBag className="h-4 w-4 text-havana-500" />;
      case 'nourriture': return <Apple className="h-4 w-4 text-tobacco-500" />;
      case 'medicament': return <Pill className="h-4 w-4 text-colonial-500" />;
      case 'hygiene': return <Droplets className="h-4 w-4 text-sunset-500" />;
      default: return null;
    }
  };

  const hasUrgentNeeds = Array.isArray(child.needs) && child.needs.some(need => need.is_urgent);

  return (
    <div className="group relative bg-white/90 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
      {/* Urgent Badge */}
      {hasUrgentNeeds && (
        <div className="absolute top-4 right-4 z-10 animate-pulse">
          <div className="bg-tobacco-500 text-white px-3 py-1 rounded-full shadow-lg flex items-center">
            <AlertTriangle className="h-4 w-4 mr-1" />
            <span className="text-sm font-medium">Urgent</span>
          </div>
        </div>
      )}

      {/* Photo Section */}
      <div className="relative aspect-[3/4]">
        <img
          src={child.photo_url || DEFAULT_PROFILE_IMAGE}
          alt={child.name}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
        
        <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
          <h3 className="text-2xl font-bold text-shadow">{child.name}</h3>
          <div className="flex items-center mt-2 text-shadow">
            <Calendar className="h-4 w-4 mr-2" />
            <span>{child.age} ans</span>
            <span className="mx-2">•</span>
            <MapPin className="h-4 w-4 mr-2" />
            <span>{child.city}</span>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-6 space-y-6">
        {/* Description Section */}
        {child.description && (
          <div className="p-4 bg-havana-50 rounded-lg border border-havana-100">
            <div className="flex items-start">
              <Info className="h-5 w-5 text-havana-500 mt-0.5 mr-3 flex-shrink-0" />
              <p className="text-sm text-havana-800">{child.description}</p>
            </div>
          </div>
        )}

        {/* Needs Section */}
        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-3">Besoins :</h4>
          <div className="space-y-2">
            {Array.isArray(child.needs) && child.needs.map((need: Need, index: number) => (
              <div
                key={index}
                className={`flex items-center p-3 rounded-lg ${
                  need.is_urgent 
                    ? 'bg-tobacco-100 border border-tobacco-200 shadow-md animate-pulse' 
                    : 'bg-havana-50 border border-havana-100'
                }`}
              >
                <div className="flex-shrink-0">
                  {getNeedIcon(need.category)}
                </div>
                <div className="ml-3 flex-grow">
                  <p className={`text-sm font-medium ${
                    need.is_urgent ? 'text-tobacco-900' : 'text-havana-900'
                  }`}>
                    {need.category}
                  </p>
                  {need.description && (
                    <p className={`text-sm mt-0.5 ${
                      need.is_urgent ? 'text-tobacco-700' : 'text-havana-700'
                    }`}>
                      {need.description}
                    </p>
                  )}
                </div>
                {need.is_urgent && (
                  <div className="ml-auto">
                    <AlertTriangle className="h-5 w-5 text-tobacco-500" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <Link
          to={`/child/${child.id}`}
          className="block w-full"
        >
          <button className="w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-xl text-white bg-tobacco-400 hover:bg-tobacco-500 transition-colors duration-200">
            <Heart className="h-5 w-5 mr-2" />
            Parrainer {child.name}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ChildCard;
import React, { useState, useEffect } from 'react';
import { Image as ImageIcon, Plus, Star } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { useSponsorStore } from '../../stores/useSponsorStore';

interface Photo {
  id: string;
  url: string;
  title?: string;
  description?: string;
  is_featured: boolean;
  created_at: string;
  child_name?: string;
  sponsor_name?: string;
}

const PhotoGallery = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(-1);
  const { sponsor } = useSponsorStore();

  useEffect(() => {
    if (sponsor?.id) {
      fetchPhotos();
    }
  }, [sponsor?.id]);

  const fetchPhotos = async () => {
    try {
      // Get sponsored children IDs first
      const { data: sponsorships, error: sponsorshipError } = await supabase
        .from('sponsorships')
        .select('child_id')
        .eq('sponsor_id', sponsor?.id)
        .eq('status', 'active');

      if (sponsorshipError) throw sponsorshipError;

      const childIds = sponsorships?.map(s => s.child_id) || [];

      // Then get photos for those children
      const { data, error } = await supabase
        .from('album_media')
        .select(`
          *,
          child:children(name)
        `)
        .eq('type', 'image')
        .in('child_id', childIds)
        .order('created_at', { ascending: false });

      if (error) throw error;

      const transformedPhotos = await Promise.all(
        (data || []).map(async (photo) => {
          const img = new Image();
          img.src = photo.url;
          await new Promise((resolve) => {
            img.onload = resolve;
          });

          return {
            id: photo.id,
            url: photo.url,
            title: photo.title,
            width: img.width || 800,
            height: img.height || 600,
            child_name: photo.child?.name
          };
        })
      );

      setPhotos(transformedPhotos);
    } catch (error) {
      console.error('Error fetching photos:', error);
      toast.error('Erreur lors du chargement des photos');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-havana-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {photos.length > 0 ? (
        <PhotoAlbum
          photos={photos.map(photo => ({
            src: photo.url,
            width: photo.width,
            height: photo.height,
            title: `${photo.title || ''} ${photo.child_name ? `- ${photo.child_name}` : ''}`
          }))}
          layout="masonry"
          spacing={8}
          columns={(containerWidth) => {
            if (containerWidth < 640) return 1;
            if (containerWidth < 1024) return 2;
            return 3;
          }}
          onClick={({ index }) => setCurrentImage(index)}
        />
      ) : (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Aucune photo</h3>
          <p className="mt-1 text-gray-500">
            Aucune photo n'est disponible pour le moment.
          </p>
        </div>
      )}

      <Lightbox
        open={currentImage >= 0}
        index={currentImage}
        close={() => setCurrentImage(-1)}
        slides={photos.map(photo => ({
          src: photo.url,
          title: `${photo.title || ''} ${photo.child_name ? `- ${photo.child_name}` : ''}`
        }))}
      />
    </div>
  );
};

export default PhotoGallery;
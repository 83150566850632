import React from 'react';
import { Heart } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-br from-havana-800 to-tobacco-900 text-white pattern-dots">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col items-center">
          <Heart className="h-8 w-8 text-sunset-400 hover:text-sunset-300 transition-colors mb-2" />
          <span className="text-lg font-bold font-display text-colonial-50">Parrainages Cubains</span>
          <p className="mt-4 text-sm text-colonial-300">
            © {new Date().getFullYear()} Passion Varadero. Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
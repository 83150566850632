import React, { useState } from 'react';
import { X, Heart } from 'lucide-react';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';
import type { SponsorshipFormData } from '../../types/sponsorship.types';
import { createSponsorshipRequest } from '../../services/sponsorshipService';
import SponsorshipForm from './SponsorshipForm';

interface SponsorshipRequestModalProps {
  child: Child;
  isOpen: boolean;
  onClose: () => void;
}

export const SponsorshipRequestModal: React.FC<SponsorshipRequestModalProps> = ({
  child,
  isOpen,
  onClose
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: SponsorshipFormData) => {
    setLoading(true);
    try {
      await createSponsorshipRequest(child.id, data);
      toast.success('Demande de parrainage envoyée avec succès');
      onClose();
    } catch (error) {
      console.error('Error submitting sponsorship request:', error);
      toast.error('Une erreur est survenue lors de l\'envoi de la demande');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center">
            <Heart className="h-5 w-5 text-red-500 mr-2" />
            Parrainer {child.name}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            disabled={loading}
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <SponsorshipForm
          onSubmit={handleSubmit}
          loading={loading}
          onCancel={onClose}
        />
      </div>
    </div>
  );
};

export default SponsorshipRequestModal;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Users, Gift, Image, Film } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useSponsorStore } from '../../stores/useSponsorStore';
import { usePermissions } from '../../hooks/usePermissions';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';
import ChildrenTable from '../../components/assistant/ChildrenTable';
import EditChildModal from '../../components/assistant/EditChildModal';
import DonationTable from '../../components/donations/DonationTable';
import VideoManager from '../../components/video/VideoManager';
import AlbumManager from '../../components/album/AlbumManager';

const AssistantDashboard = () => {
  const [activeTab, setActiveTab] = useState('children');
  const [children, setChildren] = useState<Child[]>([]);
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { sponsor } = useSponsorStore();
  const { hasRole } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sponsor || !hasRole('assistant')) {
      navigate('/login');
      return;
    }
    fetchChildren();
  }, [sponsor, hasRole, navigate]);

  const fetchChildren = async () => {
    try {
      const { data, error } = await supabase
        .from('children')
        .select('*')
        .order('name');

      if (error) throw error;
      setChildren(data || []);
    } catch (error) {
      console.error('Error fetching children:', error);
      toast.error('Erreur lors du chargement des enfants');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header */}
      <div className="bg-white rounded-lg shadow-lg mb-8">
        <div className="px-6 py-4">
          <div className="flex items-center">
            <Shield className="h-10 w-10 text-blue-500" />
            <div className="ml-4">
              <h1 className="text-2xl font-bold text-gray-900">Tableau de Bord Assistant</h1>
              <p className="text-gray-600">Gérez les enfants et les albums photos</p>
            </div>
          </div>

          <nav className="mt-6 flex space-x-4">
            <button
              onClick={() => setActiveTab('children')}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                activeTab === 'children'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
              }`}
            >
              <Users className="h-4 w-4 inline-block mr-2" />
              Enfants
            </button>
            <button
              onClick={() => setActiveTab('photos')}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                activeTab === 'photos'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
              }`}
            >
              <Image className="h-4 w-4 inline-block mr-2" />
              Photos
            </button>
            <button
              onClick={() => setActiveTab('videos')}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                activeTab === 'videos'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
              }`}
            >
              <Film className="h-4 w-4 inline-block mr-2" />
              Vidéos
            </button>
            <button
              onClick={() => setActiveTab('donations')}
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                activeTab === 'donations'
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
              }`}
            >
              <Gift className="h-4 w-4 inline-block mr-2" />
              Dons
            </button>
          </nav>
        </div>
      </div>

      {/* Content */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        {activeTab === 'children' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Gestion des Enfants</h2>
            </div>
            <ChildrenTable
              children={children}
              onEdit={(child) => {
                setSelectedChild(child);
                setShowEditModal(true);
              }}
            />
          </div>
        )}

        {activeTab === 'photos' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Gestion des Photos</h2>
            </div>
            <AlbumManager />
          </div>
        )}

        {activeTab === 'videos' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Gestion des Vidéos</h2>
            </div>
            <VideoManager />
          </div>
        )}

        {activeTab === 'donations' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Gestion des Dons</h2>
            </div>
            <DonationTable isAdmin={false} />
          </div>
        )}
      </div>

      {/* Edit Child Modal */}
      {showEditModal && selectedChild && (
        <EditChildModal
          child={selectedChild}
          onClose={() => setShowEditModal(false)}
          onSave={async (childData) => {
            try {
              const { error } = await supabase
                .from('children')
                .update(childData)
                .eq('id', selectedChild.id);

              if (error) throw error;
              
              fetchChildren();
              setShowEditModal(false);
              toast.success('Enfant mis à jour avec succès');
            } catch (error) {
              console.error('Error updating child:', error);
              toast.error('Erreur lors de la mise à jour');
            }
          }}
        />
      )}
    </div>
  );
};

export default AssistantDashboard;
import React from 'react';
import { Gift } from 'lucide-react';
import AssistantNavigation from '../../components/navigation/AssistantNavigation';
import DonationStats from '../../components/donations/DonationStats';
import DonationTable from '../../components/donations/DonationTable';

const AssistantDonations = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AssistantNavigation />
      
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Gestion des Dons</h1>
            <p className="mt-2 text-gray-600">
              Gérez et suivez les dons enregistrés
            </p>
          </div>
          <Gift className="h-8 w-8 text-blue-600" />
        </div>

        <DonationStats />
        <div className="mt-8">
          <DonationTable isAdmin={false} />
        </div>
      </div>
    </div>
  );
};

export default AssistantDonations;
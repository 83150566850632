import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  Baby,
  Heart, 
  Gift, 
  MessageSquare, 
  Star, 
  BarChart, 
  Settings,
  Mail,
  Film,
  Image,
  AlertCircle,
  Home,
  FileEdit,
  Table,
  Shield
} from 'lucide-react';

export const menuItems = [
  {
    title: "Vue d'ensemble",
    items: [
      { label: "Dashboard", icon: LayoutDashboard, href: "/admin" }
    ]
  },
  {
    title: "Parrainages",
    items: [
      { label: "Gérer les Parrains", icon: Users, href: "/admin/sponsors" },
      { label: "Gérer les Enfants", icon: Baby, href: "/admin/children" },
      { label: "Gérer les Besoins", icon: AlertCircle, href: "/admin/child-needs" },
      { label: "Demandes en Attente", icon: Heart, href: "/admin/pending-sponsorships" }
    ]
  },
  {
    title: "Contenu",
    items: [
      { label: "Page d'Accueil", icon: Home, href: "/admin/home" },
      { label: "Éditeur de Média", icon: FileEdit, href: "/admin/media-editor" },
      { label: "Table des Parrains", icon: Table, href: "/admin/sponsors-table" }
    ]
  },
  {
    title: "Dons",
    items: [
      { label: "Liste des Dons", icon: Gift, href: "/admin/donations" },
      { label: "Nouveau Don", icon: Gift, href: "/admin/new-donation" }
    ]
  },
  {
    title: "Médias",
    items: [
      { label: "Galerie Photos", icon: Image, href: "/admin/media" },
      { label: "Gestion des Vidéos", icon: Film, href: "/admin/videos" },
      { label: "Témoignages", icon: Star, href: "/admin/testimonials" }
    ]
  },
  {
    title: "Communication",
    items: [
      { label: "Messages", icon: MessageSquare, href: "/admin/messages" },
      { label: "Gestion des Emails", icon: Mail, href: "/admin/email-testing" }
    ]
  },
  {
    title: "Statistiques",
    items: [
      { label: "Vue Générale", icon: BarChart, href: "/admin/statistics" },
      { label: "Analyses Détaillées", icon: BarChart, href: "/admin/analytics" }
    ]
  },
  {
    title: "Administration",
    items: [
      { label: "Gestion des Rôles", icon: Settings, href: "/admin/roles" }
    ]
  },
  {
    title: "Espace Assistant",
    items: [
      { label: "Tableau de Bord Assistant", icon: Shield, href: "/assistant" },
      { label: "Gérer les Enfants", icon: Baby, href: "/assistant/children" },
      { label: "Gérer les Dons", icon: Gift, href: "/assistant/donations" },
      { label: "Gérer les Photos", icon: Image, href: "/assistant/media" },
      { label: "Gérer les Vidéos", icon: Film, href: "/assistant/videos" }
    ]
  }
];

const AdminNavigation = () => {
  const location = useLocation();

  return (
    <nav className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {menuItems.map((section) => (
          <div key={section.title} className="space-y-2">
            <h3 className="text-sm font-medium text-gray-500">{section.title}</h3>
            {section.items.map((item) => (
              <Link
                key={item.href}
                to={item.href}
                className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                  location.pathname === item.href
                    ? 'bg-purple-50 text-purple-700'
                    : 'text-gray-700 hover:bg-gray-50 hover:text-purple-600'
                }`}
              >
                <item.icon className="h-5 w-5 mr-2" />
                {item.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </nav>
  );
};

export default AdminNavigation;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Search, Key, Heart, X, Edit2, Trash2 } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { usePermissions } from '../../hooks/usePermissions';
import { sendEmail } from '../../lib/emailService';
import type { Sponsor } from '../../types/database.types';
import EditSponsorForm from '../../components/admin/EditSponsorForm';
import SponsorTable from '../../components/admin/SponsorTable';
import AdminNavigation from '../../components/navigation/AdminNavigation';

const AdminSponsors = () => {
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSponsor, setSelectedSponsor] = useState<Sponsor | null>(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showSponsorships, setShowSponsorships] = useState<string | null>(null);
  const [sponsorships, setSponsorships] = useState<any[]>([]);
  const { hasRole } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasRole('admin')) {
      navigate('/');
      return;
    }
    
    fetchSponsors();
  }, [hasRole, navigate]);

  const fetchSponsors = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('sponsors')
        .select(`
          *,
          sponsorships(
            id,
            status,
            child:children(id, name, city, age)
          )
        `)
        .order('name');

      if (error) throw error;
      setSponsors(data || []);
    } catch (error) {
      console.error('Error fetching sponsors:', error);
      toast.error('Erreur lors du chargement des parrains');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (sponsor: Sponsor) => {
    setSelectedSponsor(sponsor);
    setShowEditForm(true);
  };

  const handleDelete = async (sponsorId: string) => {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce parrain ?')) return;

    try {
      const { error } = await supabase
        .from('sponsors')
        .delete()
        .eq('id', sponsorId);

      if (error) throw error;

      setSponsors(sponsors.filter((s) => s.id !== sponsorId));
      toast.success('Parrain supprimé avec succès');
    } catch (error) {
      console.error('Error deleting sponsor:', error);
      toast.error('Erreur lors de la suppression');
    }
  };

  const handleResetPassword = async (sponsorId: string, email: string) => {
    try {
      const defaultPassword = 'Touspourcuba1';
      
      // Update password in database
      const { error: updateError } = await supabase
        .from('sponsors')
        .update({ password_hash: defaultPassword })
        .eq('id', sponsorId);

      if (updateError) throw updateError;

      // Send email with new password
      await sendEmail({
        to_email: email,
        to_name: 'Cher parrain',
        subject: 'Réinitialisation de votre mot de passe',
        message: `Réinitialisation de votre mot de passe

Votre mot de passe a été réinitialisé.
Votre nouveau mot de passe temporaire est : ${defaultPassword}

Veuillez vous connecter et modifier votre mot de passe dès que possible.`,
        isHtml: false
      });

      toast.success('Mot de passe réinitialisé et email envoyé');
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('Erreur lors de la réinitialisation du mot de passe');
    }
  };

  const handleViewSponsorships = async (sponsorId: string) => {
    try {
      const { data, error } = await supabase
        .from('sponsorships')
        .select(`
          id,
          status,
          start_date,
          child:children(
            id,
            name,
            age,
            city
          )
        `)
        .eq('sponsor_id', sponsorId)
        .eq('status', 'active');

      if (error) throw error;
      setSponsorships(data || []);
      setShowSponsorships(sponsorId);
    } catch (error) {
      console.error('Error fetching sponsorships:', error);
      toast.error('Erreur lors du chargement des parrainages');
    }
  };

  const handleRemoveSponsorship = async (sponsorshipId: string, childId: string) => {
    if (!confirm('Êtes-vous sûr de vouloir retirer ce parrainage ?')) return;

    try {
      // Update sponsorship status
      const { error: sponsorshipError } = await supabase
        .from('sponsorships')
        .update({ 
          status: 'ended',
          end_date: new Date().toISOString()
        })
        .eq('id', sponsorshipId);

      if (sponsorshipError) throw sponsorshipError;

      // Update child status
      const { error: childError } = await supabase
        .from('children')
        .update({ 
          status: 'available',
          is_sponsored: false
        })
        .eq('id', childId);

      if (childError) throw childError;

      // Refresh sponsorships list
      if (showSponsorships) {
        handleViewSponsorships(showSponsorships);
      }

      toast.success('Parrainage retiré avec succès');
    } catch (error) {
      console.error('Error removing sponsorship:', error);
      toast.error('Erreur lors du retrait du parrainage');
    }
  };

  const filteredSponsors = sponsors.filter(
    (sponsor) =>
      sponsor.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sponsor.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sponsor.city?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />

      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Gestion des Parrains</h1>
          <p className="mt-2 text-gray-600">
            Gérez les informations des parrains et leurs parrainages
          </p>
        </div>
        <button
          onClick={() => {
            setSelectedSponsor(null);
            setShowEditForm(true);
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
        >
          <Plus className="h-4 w-4 mr-2" />
          Ajouter un Parrain
        </button>
      </div>

      <div className="mb-8">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher un parrain..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Utilisateur
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ville
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rôle
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredSponsors.map((sponsor) => (
                <tr key={sponsor.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900">
                        {sponsor.name}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{sponsor.email}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{sponsor.city}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      sponsor.role === 'admin' ? 'bg-purple-100 text-purple-800' :
                      sponsor.role === 'assistant' ? 'bg-blue-100 text-blue-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {sponsor.role}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                    <button
                      onClick={() => handleViewSponsorships(sponsor.id)}
                      className="text-blue-600 hover:text-blue-900"
                      title="Voir les parrainages"
                    >
                      <Heart className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleResetPassword(sponsor.id, sponsor.email)}
                      className="text-yellow-600 hover:text-yellow-900"
                      title="Réinitialiser le mot de passe"
                    >
                      <Key className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleEdit(sponsor)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(sponsor.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Sponsorships Modal */}
      {showSponsorships && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">
                Enfants Parrainés
              </h2>
              <button
                onClick={() => setShowSponsorships(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-4">
              {sponsorships.length > 0 ? (
                sponsorships.map((sponsorship) => (
                  <div
                    key={sponsorship.id}
                    className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                  >
                    <div>
                      <h3 className="font-medium text-gray-900">
                        {sponsorship.child.name}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {sponsorship.child.age} ans • {sponsorship.child.city}
                      </p>
                    </div>
                    <button
                      onClick={() => handleRemoveSponsorship(sponsorship.id, sponsorship.child.id)}
                      className="p-2 text-red-600 hover:text-red-800 bg-red-100 rounded-full"
                      title="Retirer le parrainage"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500 py-4">
                  Aucun enfant parrainé
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {showEditForm && (
        <EditSponsorForm
          sponsor={selectedSponsor}
          isOpen={showEditForm}
          onClose={() => setShowEditForm(false)}
          onSuccess={() => {
            setShowEditForm(false);
            fetchSponsors();
          }}
        />
      )}
    </div>
  );
};

export default AdminSponsors;
import React, { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { differenceInMonths } from 'date-fns';

const COLORS = ['#3B82F6', '#10B981', '#8B5CF6', '#F59E0B', '#EF4444'];

interface SponsorAnalyticsProps {
  dateRange: {
    start: string;
    end: string;
  };
}

const SponsorAnalytics: React.FC<SponsorAnalyticsProps> = ({ dateRange }) => {
  const [analytics, setAnalytics] = useState({
    sponsorshipDuration: [] as any[],
    sponsorGrowth: [] as any[],
    cityDistribution: [] as any[]
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnalytics();
  }, [dateRange]);

  const fetchAnalytics = async () => {
    try {
      // Fetch sponsorships data
      const { data: sponsorships, error: sponsorshipsError } = await supabase
        .from('sponsorships')
        .select(`
          start_date,
          end_date,
          status,
          sponsor:sponsors(city)
        `)
        .gte('start_date', dateRange.start)
        .lte('start_date', dateRange.end);

      if (sponsorshipsError) throw sponsorshipsError;

      // Calculate sponsorship durations
      const durations = sponsorships.map(s => {
        const endDate = s.end_date ? new Date(s.end_date) : new Date();
        const duration = differenceInMonths(endDate, new Date(s.start_date));
        return { duration };
      });

      // Group durations
      const durationGroups = durations.reduce((acc: any, curr) => {
        const group = Math.floor(curr.duration / 3) * 3; // Group by quarters
        const label = `${group}-${group + 2} mois`;
        if (!acc[label]) {
          acc[label] = { name: label, value: 0 };
        }
        acc[label].value++;
        return acc;
      }, {});

      // Calculate monthly growth
      const monthlyGrowth = sponsorships.reduce((acc: any, curr) => {
        const month = new Date(curr.start_date).toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'long'
        });
        if (!acc[month]) {
          acc[month] = { month, sponsors: 0 };
        }
        acc[month].sponsors++;
        return acc;
      }, {});

      // Calculate city distribution
      const cityStats = sponsorships.reduce((acc: any, curr) => {
        const city = curr.sponsor?.city || 'Inconnu';
        if (!acc[city]) {
          acc[city] = { name: city, value: 0 };
        }
        acc[city].value++;
        return acc;
      }, {});

      setAnalytics({
        sponsorshipDuration: Object.values(durationGroups),
        sponsorGrowth: Object.values(monthlyGrowth),
        cityDistribution: Object.values(cityStats)
      });
    } catch (error) {
      console.error('Error fetching analytics:', error);
      toast.error('Erreur lors du chargement des analyses');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Sponsorship Duration */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Durée des parrainages
        </h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={analytics.sponsorshipDuration}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {analytics.sponsorshipDuration.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Monthly Growth */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Évolution mensuelle des parrainages
        </h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={analytics.sponsorGrowth}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="sponsors"
                stroke="#3B82F6"
                name="Nouveaux parrains"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* City Distribution */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Distribution géographique
        </h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={analytics.cityDistribution}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#10B981" name="Parrains">
                {analytics.cityDistribution.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default SponsorAnalytics;
import type { CubanCity } from '../types/database.types';

export const CUBAN_CITIES: { value: CubanCity; label: string }[] = [
  { value: 'Varadero', label: 'Varadero' },
  { value: 'Carbonera', label: 'Carbonera' },
  { value: 'Cárdenas', label: 'Cárdenas' },
  { value: 'Santa Marta', label: 'Santa Marta' },
  { value: 'Guásimas', label: 'Guásimas' },
  { value: 'Matanzas', label: 'Matanzas' },
  { value: 'Playa Larga', label: 'Playa Larga' },
  { value: 'Las Morlas', label: 'Las Morlas' },
  { value: 'Camarioca', label: 'Camarioca' },
  { value: 'Buena Vista', label: 'Buena Vista' },
  { value: 'Cantel', label: 'Cantel' },
  { value: 'Peñas Altas', label: 'Peñas Altas' }
];
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Baby, 
  Gift, 
  Image, 
  Film,
  Shield
} from 'lucide-react';

const menuItems = [
  {
    title: "Gestion",
    items: [
      { label: "Tableau de bord", icon: Shield, href: "/assistant" },
      { label: "Gérer les Enfants", icon: Baby, href: "/assistant/children" },
      { label: "Gérer les Dons", icon: Gift, href: "/assistant/donations" },
      { label: "Gérer les Photos", icon: Image, href: "/assistant/media" },
      { label: "Gérer les Vidéos", icon: Film, href: "/assistant/videos" }
    ]
  }
];

const AssistantNavigation = () => {
  const location = useLocation();

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <div className="flex items-center mb-6">
        <Shield className="h-6 w-6 text-blue-500 mr-2" />
        <h2 className="text-lg font-medium text-gray-900">
          Espace Assistant
        </h2>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {menuItems.map((section) => (
          <div key={section.title} className="space-y-2">
            <h3 className="text-sm font-medium text-gray-500">{section.title}</h3>
            {section.items.map((item) => (
              <Link
                key={item.href}
                to={item.href}
                className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                  location.pathname === item.href
                    ? 'bg-blue-50 text-blue-700'
                    : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600'
                }`}
              >
                <item.icon className="h-5 w-5 mr-2" />
                {item.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssistantNavigation;
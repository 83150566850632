import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import enTranslations from '../translations/translations_en.json';
import esTranslations from '../translations/translations_es.json';

type Language = 'fr' | 'en' | 'es';

interface TranslationState {
  currentLanguage: Language;
  translations: {
    en: typeof enTranslations;
    es: typeof esTranslations;
  };
  setLanguage: (language: Language) => void;
  t: (key: string, params?: Record<string, string>) => string;
}

export const useTranslation = create<TranslationState>()(
  persist(
    (set, get) => ({
      currentLanguage: 'fr',
      translations: {
        en: enTranslations,
        es: esTranslations
      },
      setLanguage: (language) => {
        set({ currentLanguage: language });
        // Update HTML lang attribute
        document.documentElement.lang = language;
      },
      t: (key: string, params?: Record<string, string>) => {
        const { currentLanguage, translations } = get();
        
        // Default to French (original text)
        if (currentLanguage === 'fr') return key;

        // Get translation from selected language
        const keys = key.split('.');
        let value = translations[currentLanguage];
        
        for (const k of keys) {
          if (value?.[k] === undefined) return key;
          value = value[k];
        }
        
        if (typeof value !== 'string') return key;

        // Replace parameters if any
        if (params) {
          return Object.entries(params).reduce(
            (str, [key, value]) => str.replace(`{{${key}}}`, value),
            value
          );
        }

        return value;
      }
    }),
    {
      name: 'language-storage'
    }
  )
);
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X, Bell, Mail, Phone } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { useSponsorStore } from '../../stores/useSponsorStore';
import type { Child } from '../../types/database.types';

const reminderSchema = z.object({
  email_enabled: z.boolean(),
  sms_enabled: z.boolean(),
  days_before: z.array(z.number()).min(1, 'Sélectionnez au moins un rappel'),
  email: z.string().email('Email invalide').optional().nullable(),
  phone: z.string().min(10, 'Numéro de téléphone invalide').optional().nullable()
});

type ReminderFormData = z.infer<typeof reminderSchema>;

interface ReminderSettingsProps {
  child: Child;
  isOpen: boolean;
  onClose: () => void;
}

const REMINDER_OPTIONS = [
  { value: 1, label: '1 jour avant' },
  { value: 3, label: '3 jours avant' },
  { value: 7, label: '1 semaine avant' },
  { value: 14, label: '2 semaines avant' },
  { value: 30, label: '1 mois avant' }
];

const ReminderSettings: React.FC<ReminderSettingsProps> = ({
  child,
  isOpen,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const { sponsor } = useSponsorStore();
  const [currentSettings, setCurrentSettings] = useState<ReminderFormData | null>(null);

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<ReminderFormData>({
    resolver: zodResolver(reminderSchema),
    defaultValues: {
      email_enabled: true,
      sms_enabled: false,
      days_before: [7],
      email: sponsor?.email || '',
      phone: sponsor?.phone || ''
    }
  });

  useEffect(() => {
    if (sponsor?.id && child.id) {
      fetchCurrentSettings();
    }
  }, [sponsor?.id, child.id]);

  const fetchCurrentSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('birthday_reminders')
        .select('*')
        .eq('child_id', child.id)
        .eq('sponsor_id', sponsor?.id)
        .single();

      if (error && error.code !== 'PGRST116') throw error;

      if (data) {
        setCurrentSettings(data);
        setValue('email_enabled', data.email_enabled);
        setValue('sms_enabled', data.sms_enabled);
        setValue('days_before', data.days_before);
        setValue('email', data.email);
        setValue('phone', data.phone);
      }
    } catch (error) {
      console.error('Error fetching reminder settings:', error);
      toast.error('Erreur lors du chargement des paramètres');
    }
  };

  const emailEnabled = watch('email_enabled');
  const smsEnabled = watch('sms_enabled');

  const onSubmit = async (data: ReminderFormData) => {
    if (!sponsor?.id) {
      toast.error('Vous devez être connecté pour configurer des rappels');
      return;
    }

    setLoading(true);
    try {
      const { error } = await supabase
        .from('birthday_reminders')
        .upsert({
          child_id: child.id,
          sponsor_id: sponsor.id,
          email_enabled: data.email_enabled,
          sms_enabled: data.sms_enabled,
          days_before: data.days_before,
          email: data.email_enabled ? data.email : null,
          phone: data.sms_enabled ? data.phone : null
        });

      if (error) throw error;

      toast.success('Rappels configurés avec succès');
      onClose();
    } catch (error) {
      console.error('Error saving reminder settings:', error);
      toast.error('Erreur lors de la sauvegarde des rappels');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center">
            <Bell className="h-5 w-5 text-havana-500 mr-2" />
            Rappels pour {child.name}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Mail className="h-5 w-5 text-gray-400" />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  Rappels par email
                </span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register('email_enabled')}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-havana-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-havana-500"></div>
              </label>
            </div>

            {emailEnabled && (
              <input
                type="email"
                {...register('email')}
                placeholder="Votre email"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-havana-500 focus:ring-havana-500"
              />
            )}

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Phone className="h-5 w-5 text-gray-400" />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  Rappels par SMS
                </span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register('sms_enabled')}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-havana-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-havana-500"></div>
              </label>
            </div>

            {smsEnabled && (
              <input
                type="tel"
                {...register('phone')}
                placeholder="Votre numéro de téléphone"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-havana-500 focus:ring-havana-500"
              />
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Quand souhaitez-vous être prévenu ?
            </label>
            <div className="space-y-2">
              {REMINDER_OPTIONS.map((option) => (
                <label key={option.value} className="flex items-center">
                  <input
                    type="checkbox"
                    value={option.value}
                    {...register('days_before')}
                    className="rounded border-gray-300 text-havana-500 focus:ring-havana-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    {option.label}
                  </span>
                </label>
              ))}
            </div>
            {errors.days_before && (
              <p className="mt-1 text-sm text-red-600">{errors.days_before.message}</p>
            )}
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              disabled={loading}
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-havana-500 hover:bg-havana-600 rounded-md"
            >
              {loading ? 'Enregistrement...' : 'Enregistrer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReminderSettings;
import React from 'react';
import { Edit2, Trash2, Book, Gift, ShoppingBag, Apple, Heart, Clock, XCircle } from 'lucide-react';
import type { Child, Need } from '../../types/database.types';

interface ChildrenTableProps {
  children: Child[];
  onEdit: (child: Child) => void;
  onDelete?: (childId: string) => void;
}

const ChildrenTable: React.FC<ChildrenTableProps> = ({
  children,
  onEdit,
  onDelete
}) => {
  const getNeedIcon = (category: string) => {
    switch (category) {
      case 'education':
        return <Book className="h-4 w-4 text-blue-500" />;
      case 'jouet':
        return <Gift className="h-4 w-4 text-purple-500" />;
      case 'vetement':
        return <ShoppingBag className="h-4 w-4 text-green-500" />;
      case 'nourriture':
        return <Apple className="h-4 w-4 text-red-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Photo
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Nom
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Âge
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Genre
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Ville
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Besoins
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Statut
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {children.map((child) => (
            <tr key={child.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <img
                  src={child.photo_url || 'https://via.placeholder.com/40'}
                  alt={child.name}
                  className="h-10 w-10 rounded-full object-cover"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{child.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{child.age} ans</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {child.gender === 'male' ? 'Garçon' : 'Fille'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{child.city}</td>
              <td className="px-6 py-4">
                <div className="space-y-2">
                  {Array.isArray(child.needs) && child.needs.map((need: Need, index: number) => (
                    <div
                      key={index}
                      className="flex items-center bg-gray-50 rounded-lg px-3 py-1 text-sm"
                    >
                      {getNeedIcon(need.category)}
                      <span className="ml-2">{need.description}</span>
                    </div>
                  ))}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  child.status === 'sponsored' ? 'bg-green-100 text-green-800' :
                  child.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-gray-100 text-gray-800'
                }`}>
                  {child.status === 'sponsored' ? (
                    <Heart className="h-4 w-4" />
                  ) : child.status === 'pending' ? (
                    <Clock className="h-4 w-4" />
                  ) : (
                    <XCircle className="h-4 w-4" />
                  )}
                  <span className="ml-1">
                    {child.status === 'sponsored' ? 'Parrainé' :
                     child.status === 'pending' ? 'En attente' : 'Disponible'}
                  </span>
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button 
                  onClick={() => onEdit(child)}
                  className="text-blue-600 hover:text-blue-900 mr-4"
                >
                  <Edit2 className="h-4 w-4" />
                </button>
                {onDelete && (
                  <button 
                    onClick={() => onDelete(child.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChildrenTable;
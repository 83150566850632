import React, { useState, useEffect } from 'react';
import { Heart, Search } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import type { Child } from '../types/database.types';
import ChildCard from '../components/children/ChildCard';

const AGE_RANGES = [
  { value: '0-2', label: '0-2 ans' },
  { value: '3-5', label: '3-5 ans' },
  { value: '6-8', label: '6-8 ans' },
  { value: '9-11', label: '9-11 ans' },
  { value: '12-14', label: '12-14 ans' },
  { value: '15-17', label: '15-17 ans' },
] as const;

// Helper function to calculate age from birth date
const calculateAge = (birthDate: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
};

const AvailableChildren = () => {
  const [children, setChildren] = useState<Child[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [ageRange, setAgeRange] = useState<string>('');
  const [showUrgentOnly, setShowUrgentOnly] = useState(false);

  useEffect(() => {
    fetchChildren();
  }, []);

  const fetchChildren = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data, error: supabaseError } = await supabase
        .from('children')
        .select(`
          *,
          story,
          comments,
          description,
          needs
        `)
        .eq('status', 'available');

      if (supabaseError) throw supabaseError;
      setChildren(data || []);
    } catch (error) {
      console.error('Error fetching children:', error);
      setError('Impossible de charger les enfants. Veuillez réessayer plus tard.');
      toast.error('Erreur lors du chargement des enfants');
    } finally {
      setLoading(false);
    }
  };

  const filteredChildren = children.filter((child) => {
    // Search filter
    const matchesSearch = child.name.toLowerCase().includes(searchTerm.toLowerCase());

    // Age range filter based on birth_date
    let matchesAge = true;
    if (ageRange) {
      const [min, max] = ageRange.split('-').map(Number);
      const birthDate = new Date(child.birth_date);
      const age = calculateAge(birthDate);
      matchesAge = age >= min && age <= max;
    }

    // Urgent needs filter
    let matchesUrgent = true;
    if (showUrgentOnly) {
      matchesUrgent = Array.isArray(child.needs) && child.needs.some(need => need.is_urgent);
    }

    return matchesSearch && matchesAge && matchesUrgent;
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-havana-400 to-tobacco-400 py-6 sm:py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-6">
          <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4">
            Enfants en Attente de Parrainage
          </h1>
          <p className="max-w-2xl mx-auto text-lg text-white/90">
            Ces enfants attendent votre soutien. Chaque parrainage change une vie et crée un lien spécial entre vous et un enfant cubain.
          </p>
        </div>

        {/* Filters Section */}
        <div className="bg-white/90 backdrop-blur-sm rounded-xl shadow-lg p-4 sm:p-6 mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Search Bar */}
            <div className="sm:col-span-2">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Rechercher un enfant..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-full focus:ring-tobacco-500 focus:border-tobacco-500"
                />
                <Search className="absolute left-3 top-3.5 h-5 w-5 text-gray-400" />
              </div>
            </div>

            {/* Age Range Filter */}
            <div>
              <select
                value={ageRange}
                onChange={(e) => setAgeRange(e.target.value)}
                className="w-full rounded-full border-gray-300 focus:ring-tobacco-500 focus:border-tobacco-500"
              >
                <option value="">Tous les âges</option>
                {AGE_RANGES.map((range) => (
                  <option key={range.value} value={range.value}>
                    {range.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Urgent Needs Filter */}
            <div className="flex items-center justify-start">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={showUrgentOnly}
                  onChange={(e) => setShowUrgentOnly(e.target.checked)}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-tobacco-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-tobacco-500"></div>
                <span className="ms-3 text-sm font-medium text-gray-700">
                  Besoins urgents uniquement
                </span>
              </label>
            </div>
          </div>
        </div>

        {/* Children Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredChildren.map((child) => (
            <ChildCard key={child.id} child={child} />
          ))}
        </div>

        {filteredChildren.length === 0 && !loading && (
          <div className="text-center py-12 bg-white/90 backdrop-blur-sm rounded-xl shadow-lg">
            <Heart className="mx-auto h-16 w-16 text-tobacco-200" />
            <h3 className="mt-4 text-lg font-medium text-gray-900">
              Aucun enfant disponible
            </h3>
            <p className="mt-2 text-gray-500 max-w-sm mx-auto">
              Il n'y a actuellement aucun enfant correspondant à vos critères. Essayez de modifier vos filtres.
            </p>
          </div>
        )}

        {/* Error Handling */}
        {error && (
          <div className="text-center py-12 bg-white/90 backdrop-blur-sm rounded-xl shadow-lg">
            <p className="text-red-500">{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableChildren;
import React from 'react';
import { Gift } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import DonationTable from '../components/donations/DonationTable';
import ShareButton from '../components/shared/ShareButton';

const DonationTracker = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-havana-50 to-tobacco-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-havana-900">
              Dons
            </h1>
            <p className="mt-2 text-havana-600">
              Découvrez l'impact de la générosité de notre communauté
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <Gift className="h-8 w-8 text-havana-500" />
            <ShareButton
              url={window.location.href}
              title="Découvrez l'impact de nos dons à Cuba"
              description="Grâce à la générosité de notre communauté, nous transformons la vie des enfants cubains. Rejoignez-nous dans cette belle aventure !"
              hashtags={['TousPourCuba', 'DonsCuba', 'Solidarité']}
            />
          </div>
        </div>

        {/* Donations Table */}
        <div className="mt-8">
          <DonationTable />
        </div>
      </div>
    </div>
  );
};

export default DonationTracker;
import React, { useState, useEffect } from 'react';
import PhotoAlbumComponent from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';

interface Photo {
  src: string;
  width: number;
  height: number;
  title?: string;
}

interface PhotoAlbumProps {
  child: Child;
  canEdit?: boolean;
}

const PhotoAlbum: React.FC<PhotoAlbumProps> = ({ child, canEdit = false }) => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(-1);

  useEffect(() => {
    fetchPhotos();
  }, [child.id]);

  const fetchPhotos = async () => {
    try {
      const { data, error } = await supabase
        .from('album_media')
        .select('*')
        .eq('child_id', child.id)
        .eq('type', 'image')
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Transform data for react-photo-album
      const transformedPhotos = await Promise.all(
        (data || []).map(async (media) => {
          // Get image dimensions
          const img = new Image();
          img.src = media.url;
          await new Promise((resolve) => {
            img.onload = resolve;
          });

          return {
            src: media.url,
            width: img.width,
            height: img.height,
            title: media.title
          };
        })
      );

      setPhotos(transformedPhotos);
    } catch (error) {
      console.error('Error fetching photos:', error);
      toast.error('Erreur lors du chargement des photos');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      <PhotoAlbumComponent
        photos={photos}
        layout="masonry"
        columns={(containerWidth) => {
          if (containerWidth < 640) return 1;
          if (containerWidth < 1024) return 2;
          return 3;
        }}
        spacing={8}
        onClick={({ index }) => setCurrentImage(index)}
      />

      <Lightbox
        open={currentImage >= 0}
        index={currentImage}
        close={() => setCurrentImage(-1)}
        slides={photos.map(photo => ({
          src: photo.src,
          title: photo.title
        }))}
      />
    </div>
  );
};

export default PhotoAlbum;
import React, { useState, useEffect } from 'react';
import { format, addYears, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Gift, Bell, Calendar, MapPin, Heart } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useSponsorStore } from '../../stores/useSponsorStore';
import { toast } from 'sonner';
import { motion } from 'framer-motion';
import type { Child } from '../../types/database.types';
import BirthdayCalendar from './BirthdayCalendar';
import ReminderSettings from './ReminderSettings';
import ReminderPreferences from './ReminderPreferences';
import { calculateAge } from '../../utils/dateUtils';

interface UpcomingBirthday {
  child: Child;
  nextBirthday: Date;
  daysUntil: number;
  newAge: number;
}

const BirthdayManager = () => {
  const [upcomingBirthdays, setUpcomingBirthdays] = useState<UpcomingBirthday[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const { sponsor } = useSponsorStore();

  useEffect(() => {
    if (sponsor?.id) {
      fetchSponsoredChildrenBirthdays();
    }
  }, [sponsor?.id]);

  const fetchSponsoredChildrenBirthdays = async () => {
    if (!sponsor?.id) return;

    try {
      const { data: sponsorships, error: sponsorshipError } = await supabase
        .from('sponsorships')
        .select(`
          child:children(
            id,
            name,
            birth_date,
            age,
            photo_url,
            city
          )
        `)
        .eq('sponsor_id', sponsor.id)
        .eq('status', 'active');

      if (sponsorshipError) throw sponsorshipError;

      const today = new Date();
      const birthdays = sponsorships
        .map(s => s.child)
        .filter(Boolean)
        .map(child => {
          const birthDate = new Date(child.birth_date);
          const currentAge = calculateAge(child.birth_date);
          const nextBirthday = addYears(birthDate, currentAge + 1);
          const daysUntil = differenceInDays(nextBirthday, today);
          
          return {
            child,
            nextBirthday,
            daysUntil,
            newAge: currentAge + 1
          };
        })
        .filter(b => b.daysUntil >= 0)
        .sort((a, b) => a.daysUntil - b.daysUntil);

      setUpcomingBirthdays(birthdays);
    } catch (error) {
      console.error('Error fetching birthdays:', error);
      toast.error('Erreur lors du chargement des anniversaires');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-havana-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Header Actions */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-900 flex items-center">
          <Gift className="h-6 w-6 text-havana-500 mr-2" />
          Anniversaires de mes filleuls
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setShowCalendar(true)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-havana-600 bg-havana-50 hover:bg-havana-100"
          >
            <Calendar className="h-4 w-4 mr-1.5" />
            Calendrier
          </button>
          <button
            onClick={() => setShowPreferences(true)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-havana-600 bg-havana-50 hover:bg-havana-100"
          >
            <Bell className="h-4 w-4 mr-1.5" />
            Préférences
          </button>
        </div>
      </div>

      {/* Upcoming Birthdays */}
      <div className="space-y-4">
        {upcomingBirthdays.length > 0 ? (
          upcomingBirthdays.map(({ child, nextBirthday, daysUntil, newAge }) => (
            <motion.div
              key={child.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <img
                      src={child.photo_url || 'https://via.placeholder.com/40'}
                      alt={child.name}
                      className="h-12 w-12 rounded-full object-cover"
                    />
                    <Gift className="absolute -bottom-1 -right-1 h-5 w-5 text-havana-500" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">{child.name}</h3>
                    <p className="text-sm text-gray-500">
                      Fêtera ses {newAge} ans le {format(nextBirthday, 'dd MMMM', { locale: fr })}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="text-right">
                    <div className="text-2xl font-bold text-havana-600">{daysUntil}</div>
                    <div className="text-xs text-gray-500">
                      {daysUntil === 0 ? "C'est aujourd'hui !" :
                       daysUntil === 1 ? 'Demain !' :
                       `jours restants`}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setSelectedChild(child);
                      setShowSettings(true);
                    }}
                    className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
                  >
                    <Bell className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))
        ) : (
          <div className="text-center py-12 bg-white rounded-lg shadow-lg">
            <Gift className="mx-auto h-12 w-12 text-havana-200" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              Aucun anniversaire à venir
            </h3>
            <p className="mt-1 text-gray-500">
              Il n'y a pas d'anniversaire prévu dans les 30 prochains jours.
            </p>
          </div>
        )}
      </div>

      {/* Modals */}
      {showCalendar && (
        <BirthdayCalendar
          isOpen={showCalendar}
          onClose={() => setShowCalendar(false)}
          onSelectChild={(child) => {
            setSelectedChild(child);
            setShowSettings(true);
            setShowCalendar(false);
          }}
          sponsorId={sponsor?.id}
        />
      )}

      {showSettings && selectedChild && (
        <ReminderSettings
          child={selectedChild}
          isOpen={showSettings}
          onClose={() => {
            setShowSettings(false);
            setSelectedChild(null);
          }}
        />
      )}

      {showPreferences && (
        <ReminderPreferences
          isOpen={showPreferences}
          onClose={() => setShowPreferences(false)}
        />
      )}
    </div>
  );
};

export default BirthdayManager;
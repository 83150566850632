import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';
import { createNotification } from '../../utils/notifications';
import { sendMessage } from '../../utils/messages';

interface AlbumUploaderProps {
  child?: Child;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AlbumUploader: React.FC<AlbumUploaderProps> = ({
  child,
  isOpen,
  onClose,
  onSuccess
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp'],
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov']
    },
    maxSize: 15 * 1024 * 1024,
    onDrop: acceptedFiles => {
      setFiles(prev => [...prev, ...acceptedFiles]);
    },
    onDropRejected: fileRejections => {
      fileRejections.forEach(rejection => {
        toast.error(
          rejection.errors[0].code === 'file-too-large'
            ? 'Le fichier est trop volumineux (max 15MB)'
            : 'Format de fichier non supporté'
        );
      });
    }
  });

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      toast.error('Veuillez sélectionner au moins un fichier');
      return;
    }

    setUploading(true);
    setProgress(0);

    try {
      const totalFiles = files.length;
      let uploadedFiles = 0;

      for (const file of files) {
        const fileExt = file.name.split('.').pop()?.toLowerCase();
        const isVideo = ['mp4', 'mov'].includes(fileExt || '');
        const fileName = `${child?.id || 'donations'}/${Date.now()}-${Math.random().toString(36).substring(2)}.${fileExt}`;

        const { error: uploadError } = await supabase.storage
          .from('child-albums')
          .upload(fileName, file);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('child-albums')
          .getPublicUrl(fileName);

        const { error: dbError } = await supabase
          .from('album_media')
          .insert({
            child_id: child?.id || null,
            url: publicUrl,
            type: isVideo ? 'video' : 'image'
          });

        if (dbError) throw dbError;

        uploadedFiles++;
        setProgress((uploadedFiles / totalFiles) * 100);
      }

      // Send notification and message to sponsor if uploading to child's album
      if (child?.sponsorships?.[0]?.sponsor?.id) {
        const sponsorId = child.sponsorships[0].sponsor.id;
        
        // Create notification
        await createNotification({
          recipientId: sponsorId,
          type: 'photo_added',
          title: 'Nouvelles photos ajoutées',
          content: `De nouvelles photos ont été ajoutées à l'album de ${child.name}.`,
          link: `/child/${child.id}`
        });

        // Send message
        await sendMessage({
          recipientId: sponsorId,
          subject: 'Nouvelles photos ajoutées',
          content: `
            <p>Bonjour,</p>
            <p>De nouvelles photos ont été ajoutées à l'album de ${child.name}.</p>
            <p>Vous pouvez les consulter dans votre espace parrain.</p>
            <p>Cordialement,<br>L'équipe TousPourCuba</p>
          `,
          senderRole: 'assistant'
        });
      }

      toast.success('Fichiers ajoutés avec succès');
      onSuccess();
      onClose();
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.error('Erreur lors du téléchargement des fichiers');
    } finally {
      setUploading(false);
      setProgress(0);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-lg w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-medium text-gray-900">
            {child ? `Ajouter des photos pour ${child.name}` : 'Ajouter des photos aux dons'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            disabled={uploading}
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="space-y-4">
          <div
            {...getRootProps()}
            className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-blue-500 transition-colors cursor-pointer"
          >
            <input {...getInputProps()} />
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-600">
              Glissez des fichiers ici ou cliquez pour en sélectionner
            </p>
            <p className="text-xs text-gray-500 mt-1">
              Images (JPG, PNG, WebP) ou Vidéos (MP4, MOV) • Max 15MB par fichier
            </p>
          </div>

          {files.length > 0 && (
            <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
              {files.map((file, index) => (
                <div key={index} className="relative">
                  {file.type.startsWith('image/') ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Preview ${index + 1}`}
                      className="w-full h-24 object-cover rounded-lg"
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(file)}
                      className="w-full h-24 object-cover rounded-lg"
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => removeFile(index)}
                    className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-600 hover:bg-red-200"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          )}

          {uploading && (
            <div className="relative pt-1">
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                <div
                  style={{ width: `${progress}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-300"
                />
              </div>
              <p className="text-xs text-gray-600 text-center">
                Upload en cours... {Math.round(progress)}%
              </p>
            </div>
          )}

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              disabled={uploading}
            >
              Annuler
            </button>
            <button
              onClick={handleUpload}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
              disabled={uploading || files.length === 0}
            >
              {uploading ? 'Upload en cours...' : 'Uploader'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlbumUploader;
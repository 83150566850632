import React from 'react';
import { Share2 } from 'lucide-react';

interface ShareButtonProps {
  url: string;
  title: string;
  description?: string;
  hashtags?: string[];
  className?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  url,
  title,
  description = '',
  hashtags = ['TousPourCuba'],
  className = ''
}) => {
  const handleShare = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(`${title}\n\n${description}\n\n${hashtags.map(tag => `#${tag}`).join(' ')}`)}`;
    window.open(shareUrl, '_blank', 'width=600,height=400');
  };

  return (
    <button
      onClick={handleShare}
      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-havana-600 hover:bg-havana-700 transition-colors ${className}`}
      title="Partager sur Facebook"
    >
      <Share2 className="h-4 w-4 mr-2" />
      Partager
    </button>
  );
};

export default ShareButton;
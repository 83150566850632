import React from 'react';
import { Camera, Mail, Phone, MapPin } from 'lucide-react';
import { motion } from 'framer-motion';
import type { Sponsor } from '../../types/database.types';

interface ProfileHeaderProps {
  sponsor: Sponsor;
  onPhotoClick: () => void;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ sponsor, onPhotoClick }) => {
  return (
    <div className="px-6 py-8">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="flex items-center">
          <motion.div 
            className="relative"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <img
              src={sponsor.photo_url || 'https://via.placeholder.com/150'}
              alt={sponsor.name}
              className="h-24 w-24 rounded-full object-cover border-4 border-white shadow-lg"
            />
            <button
              onClick={onPhotoClick}
              className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors"
            >
              <Camera className="h-5 w-5 text-gray-600" />
            </button>
          </motion.div>
          
          <div className="ml-6">
            <motion.h1 
              className="text-2xl font-bold text-white"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              {sponsor.name}
            </motion.h1>
            <div className="mt-2 space-y-1">
              <div className="flex items-center text-white/90">
                <Mail className="h-4 w-4 mr-2" />
                {sponsor.email}
              </div>
              {sponsor.phone && (
                <div className="flex items-center text-white/90">
                  <Phone className="h-4 w-4 mr-2" />
                  {sponsor.phone}
                </div>
              )}
              <div className="flex items-center text-white/90">
                <MapPin className="h-4 w-4 mr-2" />
                {sponsor.city}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
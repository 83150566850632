import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Plus, Minus, Upload, X } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

interface Location {
  id: string;
  city_name: string;
  latitude: number;
  longitude: number;
}

const donationSchema = z.object({
  assistant_name: z.string().min(1, 'L\'assistant est requis'),
  city: z.string().min(1, 'La ville est requise'),
  donation_date: z.string().min(1, 'La date est requise'),
  people_helped: z.number().min(1, 'Le nombre de personnes aidées est requis'),
  comments: z.string().optional(),
  items: z.array(z.object({
    category_id: z.string().min(1, 'La catégorie est requise'),
    quantity: z.number().min(1, 'La quantité est requise'),
    description: z.string().optional()
  })).min(1, 'Au moins un article est requis'),
  donors: z.array(z.object({
    name: z.string().min(1, 'Le nom du donateur est requis'),
    is_anonymous: z.boolean().default(false)
  })).min(1, 'Au moins un donateur est requis')
});

type DonationFormData = z.infer<typeof donationSchema>;

const DonationForm = () => {
  const [categories, setCategories] = useState<Array<{ id: string; name: string }>>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const { register, control, handleSubmit, formState: { errors } } = useForm<DonationFormData>({
    resolver: zodResolver(donationSchema),
    defaultValues: {
      donation_date: new Date().toISOString().split('T')[0],
      items: [{ category_id: '', quantity: 1, description: '' }],
      donors: [{ name: '', is_anonymous: false }]
    }
  });

  const { fields: itemFields, append: appendItem, remove: removeItem } = useFieldArray({
    control,
    name: 'items'
  });

  const { fields: donorFields, append: appendDonor, remove: removeDonor } = useFieldArray({
    control,
    name: 'donors'
  });

  useEffect(() => {
    fetchCategories();
    fetchLocations();
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('aid_categories')
        .select('*')
        .order('name');

      if (error) throw error;
      setCategories(data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Erreur lors du chargement des catégories');
    }
  };

  const fetchLocations = async () => {
    try {
      const { data, error } = await supabase
        .from('locations')
        .select('*')
        .order('city_name');

      if (error) throw error;
      setLocations(data || []);
    } catch (error) {
      console.error('Error fetching locations:', error);
      toast.error('Erreur lors du chargement des villes');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxSize: 5 * 1024 * 1024,
    onDrop: acceptedFiles => {
      setFiles(prev => [...prev, ...acceptedFiles]);
    },
    onDropRejected: fileRejections => {
      fileRejections.forEach(rejection => {
        toast.error(
          rejection.errors[0].code === 'file-too-large'
            ? 'Le fichier est trop volumineux (max 5MB)'
            : 'Format de fichier non supporté'
        );
      });
    }
  });

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const onSubmit = async (data: DonationFormData) => {
    setUploading(true);

    try {
      // Create donation
      const { data: donation, error: donationError } = await supabase
        .from('donations')
        .insert({
          assistant_name: data.assistant_name,
          city: data.city,
          people_helped: data.people_helped,
          donation_date: data.donation_date,
          comments: data.comments
        })
        .select()
        .single();

      if (donationError) throw donationError;

      // Upload photos
      for (const file of files) {
        const fileName = `${donation.id}/${Date.now()}-${file.name}`;
        const { error: uploadError } = await supabase.storage
          .from('donation-photos')
          .upload(fileName, file);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('donation-photos')
          .getPublicUrl(fileName);

        const { error: photoError } = await supabase
          .from('donation_photos')
          .insert({
            donation_id: donation.id,
            url: publicUrl,
            title: file.name
          });

        if (photoError) throw photoError;
      }

      // Create donation items
      const { error: itemsError } = await supabase
        .from('donation_items')
        .insert(
          data.items.map(item => ({
            donation_id: donation.id,
            ...item
          }))
        );

      if (itemsError) throw itemsError;

      // Create donors
      const { error: donorsError } = await supabase
        .from('donors')
        .insert(
          data.donors.map(donor => ({
            donation_id: donation.id,
            ...donor
          }))
        );

      if (donorsError) throw donorsError;

      toast.success('Don enregistré avec succès');
      navigate('/assistant-dashboard');
    } catch (error) {
      console.error('Error saving donation:', error);
      toast.error('Erreur lors de l\'enregistrement du don');
    } finally {
      setUploading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Assistant
          </label>
          <input
            type="text"
            {...register('assistant_name')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.assistant_name && (
            <p className="mt-1 text-sm text-red-600">{errors.assistant_name.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ville
          </label>
          <select
            {...register('city')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Sélectionner une ville</option>
            {locations.map(location => (
              <option key={location.id} value={location.city_name}>
                {location.city_name}
              </option>
            ))}
          </select>
          {errors.city && (
            <p className="mt-1 text-sm text-red-600">{errors.city.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date du don
          </label>
          <input
            type="date"
            {...register('donation_date')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.donation_date && (
            <p className="mt-1 text-sm text-red-600">{errors.donation_date.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Personnes aidées
          </label>
          <input
            type="number"
            min="1"
            {...register('people_helped', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.people_helped && (
            <p className="mt-1 text-sm text-red-600">{errors.people_helped.message}</p>
          )}
        </div>
      </div>

      {/* Items */}
      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Articles donnés
          </label>
          <button
            type="button"
            onClick={() => appendItem({ category_id: '', quantity: 1, description: '' })}
            className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
          >
            <Plus className="h-4 w-4 mr-1" />
            Ajouter un article
          </button>
        </div>

        {itemFields.map((field, index) => (
          <div key={field.id} className="flex items-center gap-2 mb-2">
            <select
              {...register(`items.${index}.category_id`)}
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Sélectionner une catégorie</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              min="1"
              {...register(`items.${index}.quantity`, { valueAsNumber: true })}
              className="w-24 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {index > 0 && (
              <button
                type="button"
                onClick={() => removeItem(index)}
                className="p-2 text-red-600 hover:text-red-800"
              >
                <Minus className="h-4 w-4" />
              </button>
            )}
          </div>
        ))}
        {errors.items && (
          <p className="mt-1 text-sm text-red-600">{errors.items.message}</p>
        )}
      </div>

      {/* Donors */}
      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Donateurs
          </label>
          <button
            type="button"
            onClick={() => appendDonor({ name: '', is_anonymous: false })}
            className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
          >
            <Plus className="h-4 w-4 mr-1" />
            Ajouter un donateur
          </button>
        </div>

        {donorFields.map((field, index) => (
          <div key={field.id} className="flex items-center gap-2 mb-2">
            <input
              {...register(`donors.${index}.name`)}
              placeholder="Nom du donateur"
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <div className="flex items-center">
              <input
                type="checkbox"
                {...register(`donors.${index}.is_anonymous`)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label className="ml-2 text-sm text-gray-600">
                Anonyme
              </label>
            </div>
            {index > 0 && (
              <button
                type="button"
                onClick={() => removeDonor(index)}
                className="p-2 text-red-600 hover:text-red-800"
              >
                <Minus className="h-4 w-4" />
              </button>
            )}
          </div>
        ))}
        {errors.donors && (
          <p className="mt-1 text-sm text-red-600">{errors.donors.message}</p>
        )}
      </div>

      {/* Photo Upload */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Photos
        </label>
        <div
          {...getRootProps()}
          className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-blue-500 transition-colors cursor-pointer"
        >
          <input {...getInputProps()} />
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-600">
            Glissez des photos ici ou cliquez pour en sélectionner
          </p>
          <p className="text-xs text-gray-500 mt-1">
            JPG, PNG ou WebP • Max 5MB par photo
          </p>
        </div>

        {files.length > 0 && (
          <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
            {files.map((file, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index + 1}`}
                  className="w-full h-24 object-cover rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-600 hover:bg-red-200"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Commentaires
        </label>
        <textarea
          {...register('comments')}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={() => navigate('/assistant-dashboard')}
          disabled={uploading}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
        >
          Annuler
        </button>
        <button
          type="submit"
          disabled={uploading}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
        >
          {uploading ? 'Envoi en cours...' : 'Enregistrer'}
        </button>
      </div>
    </form>
  );
};

export default DonationForm;
import React from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Mail, Check, X, Clock } from 'lucide-react';

interface EmailHistoryProps {
  history?: Array<{
    id: string;
    recipient_email: string;
    subject: string;
    status: 'pending' | 'sent' | 'failed';
    created_at: string;
  }>;
}

const EmailHistory: React.FC<EmailHistoryProps> = ({ history = [] }) => {
  return (
    <div className="space-y-4">
      {history.length === 0 && (
        <div className="text-center py-8">
          <Mail className="mx-auto h-12 w-12 text-gray-300" />
          <p className="mt-2 text-gray-500">Aucun email dans l'historique</p>
        </div>
      )}
      {history.map((email) => (
        <div
          key={email.id}
          className="bg-white rounded-lg shadow-sm p-4 flex items-center justify-between"
        >
          <div className="flex items-center space-x-4">
            <div className={`p-2 rounded-full ${
              email.status === 'sent' ? 'bg-green-100 text-green-600' :
              email.status === 'failed' ? 'bg-red-100 text-red-600' :
              'bg-yellow-100 text-yellow-600'
            }`}>
              {email.status === 'sent' ? <Check className="h-5 w-5" /> :
               email.status === 'failed' ? <X className="h-5 w-5" /> :
               <Clock className="h-5 w-5" />}
            </div>
            <div>
              <h4 className="font-medium text-gray-900">{email.subject}</h4>
              <p className="text-sm text-gray-500">{email.recipient_email}</p>
              <p className="text-xs text-gray-400">
                {format(new Date(email.created_at), 'PPp', { locale: fr })}
              </p>
            </div>
          </div>
          <span className={`px-2 py-1 text-xs font-medium rounded-full ${
            email.status === 'sent' ? 'bg-green-100 text-green-800' :
            email.status === 'failed' ? 'bg-red-100 text-red-800' :
            'bg-yellow-100 text-yellow-800'
          }`}>
            {email.status === 'sent' ? 'Envoyé' :
             email.status === 'failed' ? 'Échec' :
             'En attente'}
          </span>
        </div>
      ))}
    </div>
  );
};

export default EmailHistory;
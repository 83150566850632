import React from 'react';
import { motion } from 'framer-motion';
import type { Sponsor } from '../../types/database.types';

interface ProfileFormProps {
  sponsor: Sponsor;
  loading: boolean;
  onUpdate: (data: Partial<Sponsor>) => void;
  onPasswordChange: () => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  sponsor,
  loading,
  onUpdate,
  onPasswordChange
}) => {
  return (
    <motion.form 
      className="space-y-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nom complet
          </label>
          <input
            type="text"
            value={sponsor.name}
            onChange={(e) => onUpdate({ name: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 transition-colors"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={sponsor.email}
            onChange={(e) => onUpdate({ email: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 transition-colors"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Téléphone
          </label>
          <input
            type="tel"
            value={sponsor.phone || ''}
            onChange={(e) => onUpdate({ phone: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 transition-colors"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ville
          </label>
          <input
            type="text"
            value={sponsor.city}
            onChange={(e) => onUpdate({ city: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 transition-colors"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Facebook URL
        </label>
        <input
          type="url"
          value={sponsor.facebook_url || ''}
          onChange={(e) => onUpdate({ facebook_url: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 transition-colors"
          placeholder="https://facebook.com/profile"
        />
      </div>

      <div className="space-y-4 bg-gray-50 rounded-lg p-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={sponsor.is_anonymous}
            onChange={(e) => onUpdate({ is_anonymous: e.target.checked })}
            className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded transition-colors"
          />
          <label className="ml-2 block text-sm text-gray-700">
            Rester anonyme
          </label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={sponsor.show_name_publicly}
            onChange={(e) => onUpdate({ show_name_publicly: e.target.checked })}
            className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded transition-colors"
          />
          <label className="ml-2 block text-sm text-gray-700">
            Afficher mon nom publiquement
          </label>
        </div>
      </div>

      <div className="flex justify-between pt-6">
        <motion.button
          type="button"
          onClick={onPasswordChange}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Changer le mot de passe
        </motion.button>

        <motion.button
          type="submit"
          disabled={loading}
          className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md transition-colors disabled:opacity-50"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          {loading ? 'Enregistrement...' : 'Enregistrer'}
        </motion.button>
      </div>
    </motion.form>
  );
};

export default ProfileForm;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Heart, Gift, Film, Users, Settings, Shield, LogOut } from 'lucide-react';
import { useAuthStore } from '../../stores/useAuthStore';
import { usePermissions } from '../../hooks/usePermissions';
import { toast } from 'sonner';

const MobileNavigation = () => {
  const { sponsor, isAuthenticated, logout } = useAuthStore();
  const { hasRole } = usePermissions();
  const location = useLocation();

  // Don't show mobile navigation on admin/assistant pages
  if (location.pathname.startsWith('/admin') || location.pathname.startsWith('/assistant')) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Déconnexion réussie');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Erreur lors de la déconnexion');
    }
  };

  return (
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50">
      {/* Main Navigation */}
      <div className="grid grid-cols-4 gap-1 p-2">
        <Link
          to="/available-children"
          className="flex flex-col items-center justify-center p-2 text-gray-600 hover:text-havana-500"
        >
          <Heart className="h-6 w-6" />
          <span className="text-xs mt-1">Enfants</span>
        </Link>

        <Link
          to="/donations"
          className="flex flex-col items-center justify-center p-2 text-gray-600 hover:text-havana-500"
        >
          <Gift className="h-6 w-6" />
          <span className="text-xs mt-1">Dons</span>
        </Link>

        <Link
          to="/donation-videos"
          className="flex flex-col items-center justify-center p-2 text-gray-600 hover:text-havana-500"
        >
          <Film className="h-6 w-6" />
          <span className="text-xs mt-1">Vidéos</span>
        </Link>

        {isAuthenticated ? (
          <button
            onClick={handleLogout}
            className="flex flex-col items-center justify-center p-2 text-gray-600 hover:text-havana-500"
          >
            <LogOut className="h-6 w-6" />
            <span className="text-xs mt-1">Déconnexion</span>
          </button>
        ) : (
          <Link
            to="/login"
            className="flex flex-col items-center justify-center p-2 text-gray-600 hover:text-havana-500"
          >
            <Users className="h-6 w-6" />
            <span className="text-xs mt-1">Connexion</span>
          </Link>
        )}
      </div>

      {/* Secondary Navigation for Authenticated Users */}
      {isAuthenticated && (
        <div className="border-t border-gray-200 p-2">
          {/* Profile Link */}
          <Link
            to="/profile"
            className="flex items-center justify-center p-2 text-havana-600 hover:text-havana-800 bg-havana-50 rounded-lg mb-2"
          >
            <Settings className="h-5 w-5 mr-2" />
            <span className="text-sm">Mon Profil</span>
          </Link>

          {/* Admin/Assistant Links */}
          <div className="grid grid-cols-2 gap-2">
            {hasRole('admin') && (
              <Link
                to="/admin"
                className="flex items-center justify-center p-2 text-purple-600 hover:text-purple-800 bg-purple-50 rounded-lg"
              >
                <Shield className="h-5 w-5 mr-2" />
                <span className="text-sm">Admin</span>
              </Link>
            )}
            {hasRole('assistant') && (
              <Link
                to="/assistant"
                className="flex items-center justify-center p-2 text-blue-600 hover:text-blue-800 bg-blue-50 rounded-lg"
              >
                <Shield className="h-5 w-5 mr-2" />
                <span className="text-sm">Assistant</span>
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNavigation;
import React from 'react';
import { motion } from 'framer-motion';
import { HelpCircle, ChevronDown } from 'lucide-react';

const FAQ = () => {
  const [openItem, setOpenItem] = React.useState<number | null>(null);

  const faqItems = [
    {
      question: "En quoi consiste le parrainage d'un enfant à Cuba ?",
      answer: "Le parrainage permet de soutenir un enfant cubain en répondant à ses besoins essentiels (vêtements, fournitures scolaires, etc.). Vous pouvez acheminer vos dons personnellement lors d'un voyage ou via des achats en ligne directement livrés sur place."
    },
    {
      question: "Suis-je obligé d'acheminer les dons moi-même ?",
      answer: "Oui, en tant que parrain, vous êtes responsable d'acheminer les dons, soit en les apportant lors de votre voyage à Cuba, soit en utilisant des plateformes d'achat en ligne qui livrent directement à l'enfant."
    },
    {
      question: "Puis-je parrainer plusieurs enfants ?",
      answer: "Absolument ! Vous pouvez parrainer autant d'enfants que vous le souhaitez, selon vos moyens et vos envies."
    },
    {
      question: "Y a-t-il un engagement à long terme ?",
      answer: "Non, il n'y a aucune obligation de long terme. Vous pouvez mettre fin à votre parrainage à tout moment, sans justification."
    },
    {
      question: "Quels types de besoins les enfants peuvent-ils avoir ?",
      answer: "Les besoins varient en fonction des enfants, mais incluent généralement des vêtements, des chaussures, des fournitures scolaires, des produits d'hygiène et, parfois, des aliments spécifiques."
    },
    {
      question: "Y a-t-il des frais administratifs pour le parrainage ?",
      answer: "Non, aucun frais administratif n'est demandé."
    },
    {
      question: "Que se passe-t-il si je décide de mettre fin à mon parrainage ?",
      answer: "Si vous arrêtez votre parrainage, l'enfant sera réintégré dans le programme pour trouver un nouveau parrain. Vous pouvez arrêter à tout moment."
    },
    {
      question: "Puis-je ajouter des suggestions ou personnaliser mon aide ?",
      answer: "Oui, vous pouvez discuter des besoins spécifiques de l'enfant avec son assistant, qui vous aidera à personnaliser vos dons en fonction de ses besoins actuels."
    },
    {
      question: "Comment fonctionne le parrainage ?",
      answer: "Le parrainage est un engagement pour soutenir un enfant cubain. Vous recevez régulièrement des nouvelles, des photos et pouvez échanger des messages avec votre filleul."
    },
    {
      question: "Quels sont les critères pour devenir parrain ?",
      answer: "Pour devenir parrain, vous devez être majeur. Aucune autre condition particulière n'est requise... ah oui, avoir un grand cœur !"
    },
    {
      question: "Puis-je choisir l'enfant que je souhaite parrainer ?",
      answer: "Oui, vous pouvez choisir l'enfant que vous souhaitez parrainer parmi ceux disponibles sur notre plateforme."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 to-red-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <HelpCircle className="mx-auto h-12 w-12 text-red-500" />
          <h1 className="mt-4 text-3xl font-bold text-gray-900">Questions Fréquentes</h1>
          <p className="mt-2 text-lg text-gray-600">
            Trouvez les réponses à vos questions sur le parrainage d'enfants à Cuba
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="divide-y divide-gray-200">
            {faqItems.map((item, index) => (
              <div key={index} className="p-6">
                <button
                  onClick={() => setOpenItem(openItem === index ? null : index)}
                  className="w-full flex justify-between items-center text-left focus:outline-none"
                >
                  <h3 className="text-lg font-medium text-gray-900 pr-8">
                    {item.question}
                  </h3>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform duration-200 ${
                      openItem === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                
                <motion.div
                  initial={false}
                  animate={{ height: openItem === index ? 'auto' : 0 }}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden"
                >
                  <p className="mt-4 text-gray-600">
                    {item.answer}
                  </p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-8 text-center">
          <p className="text-gray-600">
            Vous avez d'autres questions ? N'hésitez pas à{' '}
            <a href="mailto:admin@passionvaradero.ca" className="text-red-600 hover:text-red-700">
              nous contacter
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
import { useEffect } from 'react';
import { useTranslation } from './useTranslation';

export const useLanguageDetection = () => {
  const { setLanguage } = useTranslation();

  useEffect(() => {
    // Get browser language
    const browserLang = navigator.language.split('-')[0];
    
    // Only switch if browser language is Spanish
    if (browserLang === 'es') {
      setLanguage('es');
    }
  }, []);
};
import React, { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface FeaturedPhoto {
  id: string;
  url: string;
  title?: string;
  description?: string;
  child_name: string;
  sponsor_name: string;
}

const FeaturedGallery = () => {
  const [photos, setPhotos] = useState<FeaturedPhoto[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFeaturedPhotos();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((current) => 
        current === photos.length - 1 ? 0 : current + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [photos.length]);

  const fetchFeaturedPhotos = async () => {
    try {
      const { data, error } = await supabase
        .from('album_media')
        .select(`
          id,
          url,
          title,
          description,
          children!inner (
            id,
            name,
            sponsorships!inner (
              sponsors (
                name,
                is_anonymous,
                show_name_publicly
              )
            )
          )
        `)
        .eq('type', 'image')
        .eq('is_featured', true)
        .order('created_at', { ascending: false });

      if (error) throw error;

      const transformedPhotos = (data || []).map(photo => {
        const sponsor = photo.children?.sponsorships?.[0]?.sponsors;
        let sponsorName = 'Anonyme';
        
        if (sponsor && !sponsor.is_anonymous && sponsor.show_name_publicly) {
          sponsorName = sponsor.name;
        }

        return {
          id: photo.id,
          url: photo.url,
          title: photo.title,
          description: photo.description,
          child_name: photo.children?.name || 'Enfant',
          sponsor_name: sponsorName
        };
      });

      setPhotos(transformedPhotos);
    } catch (error) {
      console.error('Error fetching featured photos:', error);
      toast.error('Erreur lors du chargement des photos');
    } finally {
      setLoading(false);
    }
  };

  const goToPrevious = () => {
    setCurrentIndex((current) => 
      current === 0 ? photos.length - 1 : current - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((current) => 
      current === photos.length - 1 ? 0 : current + 1
    );
  };

  if (loading) {
    return (
      <div className="relative w-full h-[500px] bg-gray-100 rounded-xl flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (photos.length === 0) {
    return (
      <div className="relative w-full h-[500px] bg-gray-100 rounded-xl flex items-center justify-center">
        <p className="text-gray-500">Aucune photo en vedette pour le moment</p>
      </div>
    );
  }

  const currentPhoto = photos[currentIndex];

  return (
    <div className="relative w-full h-[500px] overflow-hidden rounded-xl">
      <div className="absolute inset-0">
        <img
          src={currentPhoto.url}
          alt={currentPhoto.title || 'Photo en vedette'}
          className="w-full h-full object-cover transition-opacity duration-500"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent">
          <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
            {currentPhoto.title && (
              <h3 className="text-2xl font-semibold mb-2 text-shadow">
                {currentPhoto.title}
              </h3>
            )}
            {currentPhoto.description && (
              <p className="text-lg text-white/90 mb-2 text-shadow">
                {currentPhoto.description}
              </p>
            )}
            <p className="text-sm text-white/80 text-shadow">
              Photo de {currentPhoto.child_name}, partagée par {currentPhoto.sponsor_name}
            </p>
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={goToPrevious}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/30 text-white hover:bg-black/50 transition-colors"
      >
        <ChevronLeft className="h-6 w-6" />
      </button>
      <button
        onClick={goToNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/30 text-white hover:bg-black/50 transition-colors"
      >
        <ChevronRight className="h-6 w-6" />
      </button>

      {/* Dots Navigation */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
        {photos.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-2 h-2 rounded-full transition-colors ${
              index === currentIndex ? 'bg-white' : 'bg-white/50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturedGallery;
import { format, formatDistance, formatRelative, differenceInYears } from 'date-fns';
import { fr } from 'date-fns/locale';

export const formatDate = (date: string | Date) => {
  return format(new Date(date), 'PPP', { locale: fr });
};

export const formatDateTime = (date: string | Date) => {
  return format(new Date(date), 'PPp', { locale: fr });
};

export const formatRelativeTime = (date: string | Date) => {
  return formatDistance(new Date(date), new Date(), { 
    locale: fr,
    addSuffix: true 
  });
};

export const formatBirthday = (date: string | Date) => {
  return format(new Date(date), 'dd MMMM', { locale: fr });
};

export const calculateAge = (birthDate: string | Date): number => {
  return differenceInYears(new Date(), new Date(birthDate));
};

export const getAge = (birthDate: string | Date) => {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  
  return age;
};
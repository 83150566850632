import React from 'react';
import ProfileHeader from './ProfileHeader';
import ProfileTabs from './ProfileTabs';
import type { TabType } from './ProfileTabs';
import type { Sponsor } from '../../types/database.types';

interface ProfileLayoutProps {
  sponsor: Sponsor;
  activeTab: TabType;
  onTabChange: (tab: TabType) => void;
  onPhotoClick: () => void;
  children: React.ReactNode;
}

const ProfileLayout: React.FC<ProfileLayoutProps> = ({
  sponsor,
  activeTab,
  onTabChange,
  onPhotoClick,
  children
}) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="bg-gradient-to-br from-havana-500 to-tobacco-500 text-white">
        <ProfileHeader 
          sponsor={sponsor}
          onPhotoClick={onPhotoClick}
        />

        <ProfileTabs 
          activeTab={activeTab}
          onTabChange={onTabChange}
        />
      </div>

      <div className="p-6 bg-white">
        {children}
      </div>
    </div>
  );
};

export default ProfileLayout;
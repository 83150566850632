import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X, Save, Eye, EyeOff } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Sponsor } from '../../types/database.types';

const sponsorSchema = z.object({
  name: z.string().min(2, 'Le nom doit contenir au moins 2 caractères'),
  email: z.string().email('Email invalide'),
  phone: z.string().optional(),
  facebook_url: z.string()
    .url('URL Facebook invalide')
    .regex(/facebook\.com/, 'Doit être une URL Facebook valide')
    .optional()
    .or(z.literal('')),
  city: z.string().min(2, 'La ville est requise'),
  password: z.string()
    .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
    .regex(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
    .regex(/[a-z]/, 'Le mot de passe doit contenir au moins une minuscule')
    .regex(/[0-9]/, 'Le mot de passe doit contenir au moins un chiffre')
    .optional()
    .or(z.literal('')),
  is_anonymous: z.boolean().default(false),
  show_name_publicly: z.boolean().default(true)
});

type SponsorFormData = z.infer<typeof sponsorSchema>;

interface EditSponsorFormProps {
  sponsor?: Sponsor;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const EditSponsorForm: React.FC<EditSponsorFormProps> = ({
  sponsor,
  isOpen,
  onClose,
  onSuccess
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm<SponsorFormData>({
    resolver: zodResolver(sponsorSchema),
    defaultValues: {
      name: sponsor?.name || '',
      email: sponsor?.email || '',
      phone: sponsor?.phone || '',
      facebook_url: sponsor?.facebook_url || '',
      city: sponsor?.city || '',
      password: '',
      is_anonymous: sponsor?.is_anonymous || false,
      show_name_publicly: sponsor?.show_name_publicly ?? true
    }
  });

  const onSubmit = async (data: SponsorFormData) => {
    setLoading(true);

    try {
      const updateData: Partial<Sponsor> = {
        name: data.name,
        email: data.email,
        phone: data.phone || null,
        facebook_url: data.facebook_url || null,
        city: data.city,
        is_anonymous: data.is_anonymous,
        show_name_publicly: data.show_name_publicly
      };

      // Only include password if it was changed
      if (data.password) {
        updateData.password_hash = data.password;
      }

      if (sponsor) {
        // Update existing sponsor
        const { error } = await supabase
          .from('sponsors')
          .update(updateData)
          .eq('id', sponsor.id);

        if (error) throw error;
        toast.success('Parrain mis à jour avec succès');
      } else {
        // Create new sponsor
        const { error } = await supabase
          .from('sponsors')
          .insert({
            ...updateData,
            password_hash: data.password || 'touspourcuba', // Set default password if none provided
            role: 'sponsor'
          });

        if (error) throw error;
        toast.success('Parrain créé avec succès');
      }

      onSuccess();
      onClose();
    } catch (error) {
      console.error('Error saving sponsor:', error);
      toast.error('Erreur lors de l\'enregistrement');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900">
            {sponsor ? 'Modifier' : 'Ajouter'} un parrain
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom
            </label>
            <input
              type="text"
              {...register('name')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              {...register('email')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Téléphone
            </label>
            <input
              type="tel"
              {...register('phone')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Facebook URL
            </label>
            <input
              type="url"
              {...register('facebook_url')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
              placeholder="https://facebook.com/profile"
            />
            {errors.facebook_url && (
              <p className="mt-1 text-sm text-red-600">{errors.facebook_url.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Ville
            </label>
            <input
              type="text"
              {...register('city')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
            />
            {errors.city && (
              <p className="mt-1 text-sm text-red-600">{errors.city.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {sponsor ? 'Nouveau mot de passe' : 'Mot de passe'}
            </label>
            <div className="relative mt-1">
              <input
                type={showPassword ? 'text' : 'password'}
                {...register('password')}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 pr-10"
                placeholder={sponsor ? '(Laisser vide pour ne pas changer)' : ''}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {errors.password && (
              <p className="mt-1 text-sm text-red-600">{errors.password.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <div className="flex items-center">
              <input
                type="checkbox"
                {...register('is_anonymous')}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700">
                Rester anonyme
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                {...register('show_name_publicly')}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700">
                Afficher le nom publiquement
              </label>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-md"
            >
              {loading ? (
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
              ) : (
                <>
                  <Save className="h-4 w-4 mr-2" />
                  {sponsor ? 'Mettre à jour' : 'Créer'}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSponsorForm;
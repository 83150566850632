import React, { useState, useRef } from 'react';
import { Upload, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { motion, AnimatePresence } from 'framer-motion';

interface PhotoUploadProps {
  onSuccess: (url: string) => Promise<void>;
  onClose?: () => void;
  currentPhotoUrl?: string;
  inline?: boolean;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  onSuccess,
  onClose,
  currentPhotoUrl,
  inline = false
}) => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>(currentPhotoUrl || '');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error('L\'image ne doit pas dépasser 5MB');
        return;
      }

      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (!imageFile) {
      toast.error('Veuillez sélectionner une image');
      return;
    }

    setLoading(true);

    try {
      const fileExt = imageFile.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('children-photos')
        .upload(fileName, imageFile);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('children-photos')
        .getPublicUrl(fileName);

      await onSuccess(publicUrl);
      toast.success('Photo mise à jour avec succès');
      
      if (onClose) onClose();
    } catch (error) {
      console.error('Error updating photo:', error);
      toast.error('Erreur lors de la mise à jour de la photo');
    } finally {
      setLoading(false);
    }
  };

  const UploadContent = () => (
    <div className="space-y-6">
      <div className="flex justify-center">
        <div className="relative">
          <img
            src={previewUrl || 'https://via.placeholder.com/256'}
            alt="Preview"
            className="w-64 h-64 object-cover rounded-lg"
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="absolute bottom-2 right-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100"
            disabled={loading}
          >
            <Upload className="w-5 h-5 text-havana-600" />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            accept="image/jpeg,image/png,image/webp"
            className="hidden"
            disabled={loading}
          />
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        {onClose && (
          <button
            type="button"
            onClick={onClose}
            disabled={loading}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
          >
            Annuler
          </button>
        )}
        <button
          onClick={handleSubmit}
          disabled={loading || !imageFile}
          className="px-4 py-2 text-sm font-medium text-white bg-tobacco-400 hover:bg-tobacco-500 rounded-md"
        >
          {loading ? 'Envoi en cours...' : 'Enregistrer'}
        </button>
      </div>
    </div>
  );

  if (inline) {
    return <UploadContent />;
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
      >
        <motion.div
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.95 }}
          className="bg-white rounded-lg max-w-lg w-full p-6"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-gray-900">
              Ajouter une photo
            </h2>
            {onClose && (
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
                disabled={loading}
              >
                <X className="h-6 w-6" />
              </button>
            )}
          </div>

          <UploadContent />
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PhotoUpload;
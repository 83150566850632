import React, { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = ['#3B82F6', '#10B981', '#8B5CF6', '#F59E0B', '#EF4444'];

interface DonationAnalyticsProps {
  dateRange: {
    start: string;
    end: string;
  };
}

const DonationAnalytics: React.FC<DonationAnalyticsProps> = ({ dateRange }) => {
  const [analytics, setAnalytics] = useState({
    monthlyTrends: [] as any[],
    categoryDistribution: [] as any[],
    impactMetrics: [] as any[]
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnalytics();
  }, [dateRange]);

  const fetchAnalytics = async () => {
    try {
      // Fetch monthly trends
      const { data: monthlyData, error: monthlyError } = await supabase
        .from('donations')
        .select('donation_date, people_helped')
        .gte('donation_date', dateRange.start)
        .lte('donation_date', dateRange.end);

      if (monthlyError) throw monthlyError;

      // Group by month
      const monthlyTrends = monthlyData.reduce((acc: any, curr) => {
        const month = new Date(curr.donation_date).toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'long'
        });
        if (!acc[month]) {
          acc[month] = { month, donations: 0, peopleHelped: 0 };
        }
        acc[month].donations++;
        acc[month].peopleHelped += curr.people_helped;
        return acc;
      }, {});

      // Fetch category distribution
      const { data: categoryData, error: categoryError } = await supabase
        .from('donation_items')
        .select(`
          quantity,
          category:aid_categories(name)
        `)
        .gte('created_at', dateRange.start)
        .lte('created_at', dateRange.end);

      if (categoryError) throw categoryError;

      const categoryDistribution = categoryData.reduce((acc: any, curr) => {
        const category = curr.category?.name || 'Autre';
        if (!acc[category]) {
          acc[category] = { name: category, value: 0 };
        }
        acc[category].value += curr.quantity;
        return acc;
      }, {});

      // Calculate impact metrics
      const impactMetrics = [
        {
          name: 'Total Dons',
          value: Object.values(monthlyTrends).reduce((acc: any, curr: any) => acc + curr.donations, 0)
        },
        {
          name: 'Personnes Aidées',
          value: Object.values(monthlyTrends).reduce((acc: any, curr: any) => acc + curr.peopleHelped, 0)
        }
      ];

      setAnalytics({
        monthlyTrends: Object.values(monthlyTrends),
        categoryDistribution: Object.values(categoryDistribution),
        impactMetrics
      });
    } catch (error) {
      console.error('Error fetching analytics:', error);
      toast.error('Erreur lors du chargement des analyses');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Monthly Trends */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Tendances Mensuelles
        </h2>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={analytics.monthlyTrends}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="donations"
                stroke="#3B82F6"
                name="Dons"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="peopleHelped"
                stroke="#10B981"
                name="Personnes Aidées"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Category Distribution */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Distribution par Catégorie
        </h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={analytics.categoryDistribution}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {analytics.categoryDistribution.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Impact Metrics */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Métriques d'Impact
        </h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={analytics.impactMetrics}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#3B82F6">
                {analytics.impactMetrics.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DonationAnalytics;
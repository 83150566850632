import React, { useState, useEffect } from 'react';
import { Gift, Users, TrendingUp, Package } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';

interface DonationStats {
  totalDonations: number;
  totalPeopleHelped: number;
  monthlyGrowth: number;
  successRate: number;
}

const DonationStats = () => {
  const [stats, setStats] = useState<DonationStats>({
    totalDonations: 0,
    totalPeopleHelped: 0,
    monthlyGrowth: 0,
    successRate: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      // Get overall statistics
      const { data: donations, error: donationsError } = await supabase
        .from('donations')
        .select('people_helped, donation_date');

      if (donationsError) throw donationsError;

      // Calculate statistics
      const total = donations?.length || 0;
      const totalHelped = donations?.reduce((sum, d) => sum + (d.people_helped || 0), 0) || 0;

      // Calculate monthly growth
      const now = new Date();
      const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const thisMonth = donations?.filter(d => new Date(d.donation_date) >= lastMonth).length || 0;
      const prevMonth = donations?.filter(d => {
        const date = new Date(d.donation_date);
        return date >= new Date(lastMonth.getFullYear(), lastMonth.getMonth() - 1, 1) &&
               date < lastMonth;
      }).length || 0;

      const growth = prevMonth ? ((thisMonth - prevMonth) / prevMonth) * 100 : 0;

      setStats({
        totalDonations: total,
        totalPeopleHelped: totalHelped,
        monthlyGrowth: growth,
        successRate: total ? (totalHelped / total) * 100 : 0
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
      toast.error('Erreur lors du chargement des statistiques');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center">
          <div className="p-2 bg-purple-100 rounded-lg mr-4">
            <Gift className="h-6 w-6 text-purple-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Total Dons</h3>
            <p className="text-2xl font-bold text-purple-600">{stats.totalDonations}</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center">
          <div className="p-2 bg-blue-100 rounded-lg mr-4">
            <Users className="h-6 w-6 text-blue-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Personnes Aidées</h3>
            <p className="text-2xl font-bold text-blue-600">{stats.totalPeopleHelped}</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center">
          <div className="p-2 bg-green-100 rounded-lg mr-4">
            <TrendingUp className="h-6 w-6 text-green-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Croissance Mensuelle</h3>
            <p className="text-2xl font-bold text-green-600">
              {stats.monthlyGrowth > 0 ? '+' : ''}{stats.monthlyGrowth.toFixed(1)}%
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center">
          <div className="p-2 bg-yellow-100 rounded-lg mr-4">
            <Package className="h-6 w-6 text-yellow-600" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Taux de Réussite</h3>
            <p className="text-2xl font-bold text-yellow-600">{stats.successRate.toFixed(1)}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationStats;
import React, { useEffect, useRef, useState } from 'react';
import { supabase } from '../../lib/supabase';
import { Film } from 'lucide-react';
import ShareButton from '../shared/ShareButton';

interface VideoPlayerProps {
  onError?: (error: any) => void;
  onReady?: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ onError, onReady }) => {
  const [videos, setVideos] = useState<Array<{ url: string; title?: string; thumbnail?: string }>>([]);
  const [currentVideo, setCurrentVideo] = useState<{ url: string; title?: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  // Function to generate thumbnails dynamically
  const generateThumbnail = (videoUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = videoUrl;
      video.crossOrigin = 'anonymous';
      video.currentTime = 5;

      video.onloadeddata = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 300;
        canvas.height = 200;
        const context = canvas.getContext('2d');
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL('image/png'));
      };

      video.onerror = reject;
    });
  };

  // Function to fetch featured videos
  const fetchFeaturedVideos = async () => {
    try {
      const { data, error } = await supabase
        .from('donation_videos')
        .select('url, title')
        .eq('is_featured', true)
        .order('created_at', { ascending: false });

      if (error) throw error;

      if (data && data.length > 0) {
        const videosWithThumbnails = await Promise.all(
          data.map(async (video) => ({
            ...video,
            thumbnail: await generateThumbnail(video.url),
          }))
        );
        setVideos(videosWithThumbnails);
        setCurrentVideo(videosWithThumbnails[0]);
      }
    } catch (error) {
      console.error('Error fetching videos:', error);
      if (onError) onError(error);
    } finally {
      setLoading(false);
      if (onReady) onReady();
    }
  };

  useEffect(() => {
    fetchFeaturedVideos();
  }, []);

  if (loading) {
    return (
      <div className="relative aspect-video bg-gray-900 rounded-lg overflow-hidden flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  if (!currentVideo) {
    return (
      <div className="relative aspect-video bg-gray-900 rounded-lg overflow-hidden flex items-center justify-center">
        <div className="text-center text-gray-400">
          <Film className="h-12 w-12 mx-auto mb-2" />
          <p>Aucune vidéo disponible</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Video Player */}
      <div className="relative aspect-video bg-black rounded-lg overflow-hidden">
        <video
          ref={videoRef}
          src={currentVideo.url}
          className="w-full h-full"
          controls
          playsInline
          onError={(e) => {
            console.error('Video playback error:', e);
            if (onError) onError(e);
          }}
        >
          Votre navigateur ne supporte pas la lecture de vidéos.
        </video>
      </div>

      {/* Video Info & Share */}
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-900">{currentVideo.title || 'Sans titre'}</h3>
        </div>
        <ShareButton
          url={window.location.href}
          title="Regardez l'impact de nos dons à Cuba"
          description="Découvrez comment nos dons transforment la vie des enfants cubains. Votre aide peut faire la différence !"
          hashtags={['TousPourCuba', 'DonsCuba', 'ImpactPositif']}
        />
      </div>

      {/* Video Thumbnails */}
      {videos.length > 1 && (
        <div className="mt-4 flex gap-4 overflow-x-auto scrollbar-hide">
          {videos.map((video) => (
            <div
              key={video.url}
              className={`flex-shrink-0 w-36 cursor-pointer border-2 rounded-lg overflow-hidden transition-transform ${
                currentVideo.url === video.url ? 'border-havana-500 scale-105' : 'border-transparent'
              }`}
              onClick={() => setCurrentVideo(video)}
            >
              <img
                src={video.thumbnail || 'https://via.placeholder.com/300x200'}
                alt={video.title || 'Vidéo'}
                className="w-full h-20 object-cover"
              />
              <div className="bg-black text-white text-xs text-center py-1 truncate">
                {video.title || 'Sans titre'}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
import React, { useState, useEffect } from 'react';
import { Mail, Users, Send, History, Settings, Search, Bell, Key } from 'lucide-react';
import { toast } from 'sonner';
import { testEmailConfiguration, sendEmail, EMAIL_TEMPLATES } from '../../lib/emailService';
import AdminNavigation from '../../components/navigation/AdminNavigation';
import EmailTemplates from '../../components/email/EmailTemplates';
import EmailHistory from '../../components/email/EmailHistory';
import type { Sponsor } from '../../types/database.types';
import { supabase } from '../../lib/supabase';

const AdminEmailTesting = () => {
  const [testingEmail, setTestingEmail] = useState(false);
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [selectedSponsors, setSelectedSponsors] = useState<string[]>([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [emailHistory, setEmailHistory] = useState<Array<{
    id: string;
    recipient_email: string;
    subject: string;
    status: 'pending' | 'sent' | 'failed';
    created_at: string;
  }>>([]);
  const [activeTab, setActiveTab] = useState<'compose' | 'templates' | 'history' | 'settings'>('compose');

  useEffect(() => {
    fetchSponsors();
    if (activeTab === 'history') {
      fetchEmailHistory();
    }
  }, [activeTab]);

  const fetchSponsors = async () => {
    try {
      const { data, error } = await supabase
        .from('sponsors')
        .select('*')
        .order('name');

      if (error) throw error;
      setSponsors(data || []);
    } catch (error) {
      console.error('Error fetching sponsors:', error);
      toast.error('Erreur lors du chargement des parrains');
    }
  };

  const fetchEmailHistory = async () => {
    try {
      const { data, error } = await supabase
        .from('email_queue')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setEmailHistory(data || []);
    } catch (error) {
      console.error('Error fetching email history:', error);
      toast.error('Erreur lors du chargement de l\'historique');
    }
  };

  const handleTestEmail = async () => {
    if (testingEmail) return;
    
    setTestingEmail(true);
    try {
      await testEmailConfiguration();
      toast.success('Email de test envoyé avec succès');
    } catch (error) {
      console.error('Error testing email:', error);
      toast.error('Erreur lors du test email');
    } finally {
      setTestingEmail(false);
    }
  };

  const handleSendEmail = async () => {
    if (!emailSubject || !emailContent || selectedSponsors.length === 0) {
      toast.error('Veuillez remplir tous les champs');
      return;
    }

    try {
      const selectedSponsorEmails = sponsors
        .filter(s => selectedSponsors.includes(s.id))
        .map(s => s.email);

      await Promise.all(
        selectedSponsorEmails.map(email =>
          sendEmail({
            to_email: email,
            to_name: 'Cher parrain',
            subject: emailSubject,
            message: emailContent
          })
        )
      );

      setEmailSubject('');
      setEmailContent('');
      setSelectedSponsors([]);
      setSearchTerm('');

      toast.success('Emails envoyés avec succès');
      fetchEmailHistory();
    } catch (error) {
      console.error('Error sending emails:', error);
      toast.error('Erreur lors de l\'envoi des emails');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />
      
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Gestion des Emails</h1>
        <p className="mt-2 text-gray-600">
          Gérez et envoyez des emails aux parrains
        </p>
      </div>

      <div className="mb-8">
        <nav className="flex space-x-4 border-b border-gray-200">
          <button
            onClick={() => setActiveTab('compose')}
            className={`px-4 py-2 text-sm font-medium border-b-2 -mb-px ${
              activeTab === 'compose'
                ? 'border-gold-500 text-gold-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <Send className="h-4 w-4 inline-block mr-2" />
            Composer
          </button>
          <button
            onClick={() => setActiveTab('templates')}
            className={`px-4 py-2 text-sm font-medium border-b-2 -mb-px ${
              activeTab === 'templates'
                ? 'border-gold-500 text-gold-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <Mail className="h-4 w-4 inline-block mr-2" />
            Modèles
          </button>
          <button
            onClick={() => setActiveTab('history')}
            className={`px-4 py-2 text-sm font-medium border-b-2 -mb-px ${
              activeTab === 'history'
                ? 'border-gold-500 text-gold-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <History className="h-4 w-4 inline-block mr-2" />
            Historique
          </button>
          <button
            onClick={() => setActiveTab('settings')}
            className={`px-4 py-2 text-sm font-medium border-b-2 -mb-px ${
              activeTab === 'settings'
                ? 'border-gold-500 text-gold-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <Settings className="h-4 w-4 inline-block mr-2" />
            Configuration
          </button>
        </nav>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6">
        {activeTab === 'compose' && (
          <div className="space-y-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium text-gray-900">Envoyer un Email</h3>
              <div className="flex space-x-4">
                <button
                  onClick={() => setActiveTab('templates')}
                  className="text-gold-600 hover:text-gold-800"
                >
                  Utiliser un modèle
                </button>
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Destinataires
              </label>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Rechercher des parrains..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-gold-500 focus:border-gold-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              <div className="mt-2 space-y-2 max-h-40 overflow-y-auto">
                {sponsors
                  .filter(s => 
                    s.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    s.email.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map(sponsor => (
                    <label key={sponsor.id} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={selectedSponsors.includes(sponsor.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedSponsors([...selectedSponsors, sponsor.id]);
                          } else {
                            setSelectedSponsors(selectedSponsors.filter(id => id !== sponsor.id));
                          }
                        }}
                        className="h-4 w-4 text-gold-600 focus:ring-gold-500 border-gray-300 rounded"
                      />
                      <span className="ml-2 text-sm text-gray-700">
                        {sponsor.name} ({sponsor.email})
                      </span>
                    </label>
                  ))}
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Sujet
              </label>
              <input
                type="text"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-gold-500 focus:border-gold-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Contenu
              </label>
              <textarea
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
                rows={6}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-gold-500 focus:border-gold-500"
              />
            </div>

            <button
              onClick={handleSendEmail}
              className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gold-500 hover:bg-gold-600 transition-colors"
            >
              <Send className="h-4 w-4 mr-2" />
              Envoyer
            </button>
          </div>
        )}

        {activeTab === 'templates' && (
          <EmailTemplates
            onSelect={(template) => {
              setEmailSubject(template.subject);
              setEmailContent(template.content);
              setActiveTab('compose');
            }}
          />
        )}

        {activeTab === 'history' && (
          <EmailHistory history={emailHistory} />
        )}

        {activeTab === 'settings' && (
          <div className="space-y-6">
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <h4 className="font-medium text-gray-900">Test de Configuration</h4>
                <p className="text-sm text-gray-500">
                  Envoyer un email de test pour vérifier la configuration
                </p>
              </div>
              <button
                onClick={handleTestEmail}
                disabled={testingEmail}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gold-500 hover:bg-gold-600 disabled:opacity-50"
              >
                {testingEmail ? (
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                ) : (
                  <>
                    <Mail className="h-4 w-4 mr-2" />
                    Envoyer un test
                  </>
                )}
              </button>
            </div>

            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <h4 className="font-medium text-gray-900">Notifications Email</h4>
                <p className="text-sm text-gray-500">
                  Configurer les notifications automatiques
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <Bell className="h-5 w-5 text-gray-400" />
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gold-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gold-500"></div>
                </label>
              </div>
            </div>

            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <h4 className="font-medium text-gray-900">Réinitialisation des mots de passe</h4>
                <p className="text-sm text-gray-500">
                  Configurer le modèle d'email de réinitialisation
                </p>
              </div>
              <button className="text-gold-600 hover:text-gold-800">
                <Key className="h-5 w-5" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminEmailTesting;
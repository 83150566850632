import React from 'react';
import { useNavigate } from 'react-router-dom';

const Messages = () => {
  const navigate = useNavigate();
  
  // Redirect to profile since messaging is removed
  React.useEffect(() => {
    navigate('/profile');
  }, [navigate]);

  return null;
};

export default Messages;
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Plus, Search, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';

const requestSchema = z.object({
  childId: z.string().min(1, 'Veuillez sélectionner un enfant'),
  reason: z.string().min(10, 'La raison doit contenir au moins 10 caractères'),
  additionalInfo: z.string().optional()
});

type RequestFormData = z.infer<typeof requestSchema>;

interface AddChildRequestProps {
  sponsorId: string;
  onClose: () => void;
}

const AddChildRequest: React.FC<AddChildRequestProps> = ({ sponsorId, onClose }) => {
  const [availableChildren, setAvailableChildren] = useState<Child[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm<RequestFormData>({
    resolver: zodResolver(requestSchema)
  });

  useEffect(() => {
    fetchAvailableChildren();
  }, []);

  const fetchAvailableChildren = async () => {
    try {
      const { data, error } = await supabase
        .from('children')
        .select('*')
        .eq('status', 'available')
        .order('name');

      if (error) throw error;
      setAvailableChildren(data || []);
    } catch (error) {
      console.error('Error fetching children:', error);
      toast.error('Erreur lors du chargement des enfants');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: RequestFormData) => {
    setSubmitting(true);
    try {
      // Create child request
      const { error: requestError } = await supabase
        .from('child_requests')
        .insert({
          sponsor_id: sponsorId,
          child_id: data.childId,
          reason: data.reason,
          additional_info: data.additionalInfo,
          status: 'pending'
        });

      if (requestError) throw requestError;

      // Notify admins
      const { error: notifError } = await supabase
        .from('notifications')
        .insert({
          type: 'child_request',
          title: 'Nouvelle demande d\'ajout d\'enfant',
          content: `Un parrain souhaite ajouter un enfant à son profil. Raison : ${data.reason}`,
          recipient_role: 'admin'
        });

      if (notifError) throw notifError;

      toast.success('Demande envoyée avec succès');
      onClose();
    } catch (error) {
      console.error('Error submitting request:', error);
      toast.error('Erreur lors de l\'envoi de la demande');
    } finally {
      setSubmitting(false);
    }
  };

  const filteredChildren = availableChildren.filter(child =>
    child.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    child.city.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-lg w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900">
            Demander l'ajout d'un enfant
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher un enfant..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-red-500 focus:border-red-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Sélectionner un enfant
            </label>
            <select
              {...register('childId')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
            >
              <option value="">Choisir un enfant</option>
              {filteredChildren.map((child) => (
                <option key={child.id} value={child.id}>
                  {child.name} ({child.age} ans) - {child.city}
                </option>
              ))}
            </select>
            {errors.childId && (
              <p className="mt-1 text-sm text-red-600">{errors.childId.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Raison de la demande
            </label>
            <textarea
              {...register('reason')}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              placeholder="Expliquez pourquoi vous souhaitez parrainer cet enfant..."
            />
            {errors.reason && (
              <p className="mt-1 text-sm text-red-600">{errors.reason.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Informations complémentaires (optionnel)
            </label>
            <textarea
              {...register('additionalInfo')}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              placeholder="Ajoutez des informations supplémentaires si nécessaire..."
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              disabled={submitting}
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md flex items-center"
            >
              {submitting ? (
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
              ) : (
                <>
                  <Plus className="h-4 w-4 mr-2" />
                  Envoyer la demande
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddChildRequest;
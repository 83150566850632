import React from 'react';
import { Book, Gift, ShoppingBag, Apple, Pill, Droplets, HelpCircle, AlertTriangle } from 'lucide-react';
import type { Need } from '../../types/database.types';

interface NeedsListProps {
  childId: string;
  needs: Need[];
}

const NeedsList: React.FC<NeedsListProps> = ({ needs }) => {
  const getNeedIcon = (category: string) => {
    switch (category) {
      case 'education':
        return <Book className="h-4 w-4 text-blue-500" />;
      case 'jouet':
        return <Gift className="h-4 w-4 text-purple-500" />;
      case 'vetement':
        return <ShoppingBag className="h-4 w-4 text-green-500" />;
      case 'nourriture':
        return <Apple className="h-4 w-4 text-red-500" />;
      case 'medicament':
        return <Pill className="h-4 w-4 text-pink-500" />;
      case 'hygiene':
        return <Droplets className="h-4 w-4 text-cyan-500" />;
      default:
        return <HelpCircle className="h-4 w-4 text-gray-500" />;
    }
  };

  if (!Array.isArray(needs) || needs.length === 0) {
    return (
      <div className="text-sm text-gray-500 italic">
        Aucun besoin spécifique
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {needs.map((need, index) => (
        <div
          key={index}
          className={`flex items-center p-3 rounded-lg ${
            need.is_urgent ? 'bg-red-50 border border-red-100' : 'bg-gray-50 border border-gray-100'
          }`}
        >
          <div className="flex-shrink-0">
            {getNeedIcon(need.category)}
          </div>
          <div className="ml-3 flex-grow">
            <p className="text-sm font-medium text-gray-900">
              {need.category === 'education' ? 'Education' :
               need.category === 'jouet' ? 'Jouets' :
               need.category === 'vetement' ? 'Vêtements' :
               need.category === 'nourriture' ? 'Alimentation' :
               need.category === 'medicament' ? 'Médicaments' :
               need.category === 'hygiene' ? 'Hygiène' :
               'Autre'}
            </p>
            {need.description && (
              <p className="text-sm text-gray-600 mt-0.5">
                {need.description}
              </p>
            )}
          </div>
          {need.is_urgent && (
            <div className="ml-4">
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                <AlertTriangle className="h-3 w-3 mr-1" />
                Urgent
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default NeedsList;
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MobileNavigation from './MobileNavigation';
import { Toaster } from 'sonner';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = React.memo<MainLayoutProps>(({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-gradient-to-br from-havana-50 via-colonial-50 to-tobacco-50 pattern-waves pb-20 md:pb-0">
        {children}
      </main>
      <Footer />
      <MobileNavigation />
      <Toaster 
        position="top-right" 
        expand={false}
        richColors 
        closeButton
        visibleToasts={3}
        duration={4000}
        closeDelay={300}
        theme={{
          success: {
            background: '#8B4513',
            textColor: 'white'
          },
          error: {
            background: '#FF7F50',
            textColor: 'white'
          },
          info: {
            background: '#DEB887',
            textColor: '#8B4513'
          }
        }}
      />
    </div>
  );
});

export default MainLayout;
import React, { useState, useEffect } from 'react';
import { Image as ImageIcon, Search } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import ShareButton from '../components/shared/ShareButton';

interface Photo {
  id: string;
  url: string;
  title?: string;
  width: number;
  height: number;
  child_name?: string;
  sponsor_name?: string;
}

const PublicAlbum = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchFeaturedPhotos();
  }, []);

  const fetchFeaturedPhotos = async () => {
    try {
      const { data, error } = await supabase
        .from('album_media')
        .select(`
          *,
          child:children(name),
          sponsor:sponsors(name, is_anonymous)
        `)
        .eq('type', 'image')
        .eq('is_featured', true)
        .order('created_at', { ascending: false });

      if (error) throw error;

      const transformedPhotos = await Promise.all(
        (data || []).map(async (photo) => {
          const img = new Image();
          img.src = photo.url;
          await new Promise((resolve) => {
            img.onload = resolve;
          });

          const sponsorName = photo.sponsor?.is_anonymous ? 'Parrain anonyme' : photo.sponsor?.name;

          return {
            id: photo.id,
            url: photo.url,
            title: photo.title,
            width: img.width || 800,
            height: img.height || 600,
            child_name: photo.child?.name,
            sponsor_name: sponsorName
          };
        })
      );

      setPhotos(transformedPhotos);
    } catch (error) {
      console.error('Error fetching featured photos:', error);
      toast.error('Erreur lors du chargement des photos');
    } finally {
      setLoading(false);
    }
  };

  const filteredPhotos = photos.filter(photo =>
    photo.child_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    photo.sponsor_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    photo.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-havana-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-havana-900">Album Photos</h1>
        <p className="mt-2 text-havana-600">
          Découvrez les moments de joie partagés par nos parrains et leurs filleuls
        </p>
      </div>

      <div className="mb-8">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-havana-200 rounded-md focus:ring-havana-500 focus:border-havana-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-havana-400" />
        </div>
      </div>

      {filteredPhotos.length > 0 ? (
        <PhotoAlbum
          photos={filteredPhotos.map(photo => ({
            src: photo.url,
            width: photo.width,
            height: photo.height,
            title: photo.title,
            id: photo.id
          }))}
          layout="masonry"
          spacing={8}
          columns={(containerWidth) => {
            if (containerWidth < 640) return 1;
            if (containerWidth < 1024) return 2;
            return 3;
          }}
          onClick={({ index }) => setCurrentImage(index)}
        />
      ) : (
        <div className="text-center py-12 bg-havana-50 rounded-lg">
          <ImageIcon className="mx-auto h-12 w-12 text-havana-300" />
          <h3 className="mt-2 text-sm font-medium text-havana-900">Aucune photo</h3>
          <p className="mt-1 text-havana-600">
            Aucune photo n'est disponible pour le moment.
          </p>
        </div>
      )}

      <Lightbox
        open={currentImage >= 0}
        index={currentImage}
        close={() => setCurrentImage(-1)}
        slides={filteredPhotos.map(photo => ({
          src: photo.url,
          title: photo.title,
          description: `${photo.child_name} - Partagé par ${photo.sponsor_name}`
        }))}
        render={{
          slide: ({ slide }) => (
            <div className="relative">
              <img src={slide.src} alt={slide.title || ''} className="w-full h-auto" />
              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
                <h3 className="text-lg font-medium">{slide.title}</h3>
                <p className="text-sm opacity-80">{slide.description}</p>
                <ShareButton
                  url={window.location.href}
                  title="Découvrez les moments de joie des enfants parrainés à Cuba"
                  description="Grâce à nos parrains, nous apportons du bonheur aux enfants cubains. Rejoignez notre communauté !"
                  hashtags={['TousPourCuba', 'ParrainageCuba', 'SouriresDEnfants']}
                  className="mt-2"
                />
              </div>
            </div>
          )
        }}
      />
    </div>
  );
};

export default PublicAlbum;
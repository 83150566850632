import React, { useState, useEffect, useCallback } from 'react';
import { Star, ThumbsUp } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface Temoignage {
  id: string;
  content: string;
  rating: number;
  created_at: string;
  is_approved: boolean;
  is_featured: boolean;
  parrain: {  // Changed from sponsor to parrain
    name: string;
  };
}

interface TestimonialListProps {
  childId?: string;
  showAll?: boolean;
  isAdmin?: boolean;
}

const TestimonialList: React.FC<TestimonialListProps> = ({
  childId,
  showAll = false,
  isAdmin = false
}) => {
  const [temoignages, setTemoignages] = useState<Temoignage[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTemoignages = useCallback(async () => {
    try {
      let query = supabase
        .from('temoignage')  // Using the correct table name
        .select(`
          id,
          content,
          rating,
          created_at,
          is_approved,
          is_featured,
          parrain (
            name
          )
        `)
        .order('created_at', { ascending: false });

      if (childId) {
        query = query.eq('child_id', childId);
      }

      if (!isAdmin && !showAll) {
        query = query.eq('is_approved', true);
      }

      const { data, error } = await query;

      if (error) throw error;
      setTemoignages(data || []);
    } catch (error) {
      console.error('Error fetching temoignages:', error);
      requestAnimationFrame(() => {
        toast.error('Erreur lors du chargement des témoignages');
      });
    } finally {
      setLoading(false);
    }
  }, [childId, showAll, isAdmin]);

  useEffect(() => {
    fetchTemoignages();
  }, [fetchTemoignages]);

  const handleApprove = async (id: string) => {
    try {
      const { error } = await supabase
        .from('temoignage')
        .update({ is_approved: true })
        .eq('id', id);

      if (error) throw error;

      setTemoignages(prev => 
        prev.map(t => t.id === id ? { ...t, is_approved: true } : t)
      );

      requestAnimationFrame(() => {
        toast.success('Témoignage approuvé');
      });
    } catch (error) {
      console.error('Error approving testimonial:', error);
      requestAnimationFrame(() => {
        toast.error('Erreur lors de l\'approbation');
      });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500" />
      </div>
    );
  }

  if (temoignages.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">Aucun témoignage disponible</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {temoignages.map((temoignage) => (
        <div
          key={temoignage.id}
          className="bg-white shadow rounded-lg p-6"
        >
          <div className="flex justify-between items-start">
            <div>
              <h3 className="font-medium text-gray-900">
                {temoignage.parrain?.name}
              </h3>
              <div className="flex items-center mt-1">
                {Array.from({ length: 5 }).map((_, i) => (
                  <Star
                    key={i}
                    className={`h-4 w-4 ${
                      i < temoignage.rating
                        ? 'text-yellow-400 fill-yellow-400'
                        : 'text-gray-300'
                    }`}
                  />
                ))}
                <span className="ml-2 text-sm text-gray-500">
                  {format(new Date(temoignage.created_at), 'dd MMMM yyyy', { locale: fr })}
                </span>
              </div>
            </div>
            {isAdmin && !temoignage.is_approved && (
              <button
                onClick={() => handleApprove(temoignage.id)}
                className="flex items-center text-sm text-indigo-600 hover:text-indigo-500"
              >
                <ThumbsUp className="h-4 w-4 mr-1" />
                Approuver
              </button>
            )}
          </div>

          <p className="mt-4 text-gray-600">{temoignage.content}</p>

          {temoignage.is_featured && (
            <div className="mt-4 flex items-center text-sm text-yellow-600">
              <Star className="h-4 w-4 mr-1 fill-yellow-600" />
              Témoignage mis en avant
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TestimonialList;
import React, { useState, useEffect } from 'react';
import { Heart, Calendar, MapPin, Star, Camera } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { motion } from 'framer-motion';
import PhotoAlbumComponent from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

interface Testimonial {
  id: string;
  content: string;
  rating: number;
  created_at: string;
  sponsor: {
    name: string;
    photo_url: string | null;
    is_anonymous: boolean;
  };
  child: {
    id: string;
    name: string;
    age: number;
    city: string;
    photo_url: string | null;
  };
  photos?: Array<{
    url: string;
    width: number;
    height: number;
  }>;
}

const SponsorshipHistory = () => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTestimonial, setSelectedTestimonial] = useState<string | null>(null);
  const [currentImage, setCurrentImage] = useState(-1);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const { data, error } = await supabase
        .from('testimonials')
        .select(`
          *,
          sponsor:sponsors(
            name,
            photo_url,
            is_anonymous
          ),
          child:children(
            id,
            name,
            age,
            city,
            photo_url
          )
        `)
        .eq('is_approved', true)
        .eq('is_featured', true)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Fetch photos for each testimonial
      const testimonialsWithPhotos = await Promise.all(
        (data || []).map(async (testimonial) => {
          const { data: photos } = await supabase
            .from('album_media')
            .select('url')
            .eq('child_id', testimonial.child.id)
            .eq('type', 'image')
            .limit(6);

          // Transform photos for the photo album
          const transformedPhotos = await Promise.all(
            (photos || []).map(async (photo) => {
              const img = new Image();
              img.src = photo.url;
              await new Promise((resolve) => {
                img.onload = resolve;
              });
              return {
                url: photo.url,
                width: img.width || 800,
                height: img.height || 600
              };
            })
          );

          return {
            ...testimonial,
            photos: transformedPhotos
          };
        })
      );

      setTestimonials(testimonialsWithPhotos);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      toast.error('Erreur lors du chargement des histoires');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-orange-50 to-red-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 to-red-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Histoires de Parrainage
          </h1>
          <p className="max-w-2xl mx-auto text-lg text-gray-600">
            Découvrez l'impact positif de nos parrainages à travers ces belles histoires de partage et de solidarité.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={testimonial.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden"
            >
              {/* Child Info */}
              <div className="relative">
                <img
                  src={testimonial.child.photo_url || 'https://via.placeholder.com/400x300'}
                  alt={testimonial.child.name}
                  className="w-full h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                  <h2 className="text-2xl font-bold">{testimonial.child.name}</h2>
                  <div className="flex items-center mt-2">
                    <Calendar className="h-4 w-4 mr-2" />
                    <span>{testimonial.child.age} ans</span>
                    <span className="mx-2">•</span>
                    <MapPin className="h-4 w-4 mr-2" />
                    <span>{testimonial.child.city}</span>
                  </div>
                </div>
              </div>

              {/* Testimonial Content */}
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <p className="text-sm text-gray-500">Parrainé par</p>
                    <p className="font-medium text-gray-900">
                      {testimonial.sponsor.is_anonymous ? 'Parrain anonyme' : testimonial.sponsor.name}
                    </p>
                  </div>
                  <div className="flex space-x-1">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className={`h-4 w-4 ${
                          star <= testimonial.rating
                            ? 'text-yellow-400 fill-current'
                            : 'text-gray-300'
                        }`}
                      />
                    ))}
                  </div>
                </div>

                <p className="text-gray-600 text-sm italic mb-6">
                  "{testimonial.content}"
                </p>

                {/* Photos */}
                {testimonial.photos && testimonial.photos.length > 0 && (
                  <div>
                    <button
                      onClick={() => setSelectedTestimonial(testimonial.id)}
                      className="inline-flex items-center text-blue-600 hover:text-blue-800"
                    >
                      <Camera className="h-4 w-4 mr-2" />
                      Voir les photos ({testimonial.photos.length})
                    </button>

                    {selectedTestimonial === testimonial.id && (
                      <div className="mt-4">
                        <PhotoAlbumComponent
                          photos={testimonial.photos.map(p => ({
                            src: p.url,
                            width: p.width,
                            height: p.height
                          }))}
                          layout="rows"
                          spacing={4}
                          onClick={({ index }) => setCurrentImage(index)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        {testimonials.length === 0 && (
          <div className="text-center py-12 bg-white rounded-xl shadow-lg">
            <Heart className="mx-auto h-16 w-16 text-red-200" />
            <h3 className="mt-4 text-lg font-medium text-gray-900">
              Aucune histoire à afficher
            </h3>
            <p className="mt-2 text-gray-500 max-w-sm mx-auto">
              Revenez bientôt pour découvrir de belles histoires de parrainage !
            </p>
          </div>
        )}

        {/* Lightbox for photos */}
        <Lightbox
          open={currentImage >= 0}
          index={currentImage}
          close={() => setCurrentImage(-1)}
          slides={selectedTestimonial ? 
            testimonials
              .find(t => t.id === selectedTestimonial)
              ?.photos?.map(p => ({ src: p.url })) || []
            : []
          }
        />
      </div>
    </div>
  );
};

export default SponsorshipHistory;
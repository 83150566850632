import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Gift } from 'lucide-react';
import DonationForm from '../components/donations/DonationForm';
import { usePermissions } from '../hooks/usePermissions';
import { toast } from 'sonner';

const NewDonation = () => {
  const { hasRole } = usePermissions();
  const navigate = useNavigate();

  if (!hasRole('admin') && !hasRole('assistant')) {
    toast.error('Accès non autorisé');
    navigate('/');
    return null;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Nouveau Don</h1>
            <p className="mt-2 text-gray-600">
              Enregistrer un nouveau don pour la communauté
            </p>
          </div>
          <Gift className="h-8 w-8 text-blue-500" />
        </div>

        <DonationForm />
      </div>
    </div>
  );
};

export default NewDonation;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Search, Users, AlertTriangle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useSponsorStore } from '../stores/useSponsorStore';
import { toast } from 'sonner';
import AdminNavigation from '../components/navigation/AdminNavigation';

interface Sponsor {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  city: string;
  phone?: string;
}

type UserRole = 'visitor' | 'sponsor' | 'assistant' | 'admin';

const ROLES: { value: UserRole; label: string; description: string }[] = [
  {
    value: 'admin',
    label: 'Administrateur',
    description: 'Accès complet au système et à toutes les fonctionnalités'
  },
  {
    value: 'assistant',
    label: 'Assistant',
    description: 'Peut gérer les enfants et les dons, voir les parrains'
  },
  {
    value: 'sponsor',
    label: 'Parrain',
    description: 'Peut gérer son profil et ses filleuls'
  },
  {
    value: 'visitor',
    label: 'Visiteur',
    description: 'Accès limité au contenu public'
  }
];

const RoleManagement = () => {
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { sponsor: currentUser } = useSponsorStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser?.role === 'admin') {
      navigate('/');
      return;
    }
    fetchSponsors();
  }, [currentUser, navigate]);

  const fetchSponsors = async () => {
    try {
      const { data, error } = await supabase
        .from('sponsors')
        .select('*')
        .order('name');

      if (error) throw error;
      setSponsors(data || []);
    } catch (error) {
      console.error('Error fetching sponsors:', error);
      toast.error('Erreur lors du chargement des utilisateurs');
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = async (sponsorId: string, newRole: UserRole) => {
    try {
      // Prevent changing own role
      if (sponsorId === currentUser?.id) {
        toast.error('Vous ne pouvez pas modifier votre propre rôle');
        return;
      }

      const { error } = await supabase
        .from('sponsors')
        .update({ role: newRole })
        .eq('id', sponsorId);

      if (error) throw error;

      setSponsors(sponsors.map(s =>
        s.id === sponsorId ? { ...s, role: newRole } : s
      ));

      toast.success('Rôle mis à jour avec succès');
    } catch (error) {
      console.error('Error updating role:', error);
      toast.error('Erreur lors de la mise à jour du rôle');
    }
  };

  const filteredSponsors = sponsors.filter(sponsor =>
    sponsor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sponsor.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />
      
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Gestion des Rôles</h1>
          <p className="mt-2 text-gray-600">
            Gérez les rôles et permissions des utilisateurs
          </p>
        </div>
      </div>

      {/* Role Descriptions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {ROLES.map(role => (
          <div key={role.value} className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <Shield className={`h-6 w-6 ${
                role.value === 'admin' ? 'text-purple-500' :
                role.value === 'assistant' ? 'text-blue-500' :
                role.value === 'sponsor' ? 'text-red-500' :
                'text-gray-500'
              }`} />
              <h3 className="ml-2 text-lg font-medium text-gray-900">
                {role.label}
              </h3>
            </div>
            <p className="text-sm text-gray-600">{role.description}</p>
          </div>
        ))}
      </div>

      {/* Search */}
      <div className="mb-8">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher un utilisateur..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      {/* Users Table */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Utilisateur
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rôle Actuel
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Changer le Rôle
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredSponsors.map((sponsor) => (
                <tr key={sponsor.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <Users className="h-10 w-10 text-gray-400" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {sponsor.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {sponsor.city}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{sponsor.email}</div>
                    {sponsor.phone && (
                      <div className="text-sm text-gray-500">{sponsor.phone}</div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      sponsor.role === 'admin' ? 'bg-purple-100 text-purple-800' :
                      sponsor.role === 'assistant' ? 'bg-blue-100 text-blue-800' :
                      sponsor.role === 'sponsor' ? 'bg-red-100 text-red-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {ROLES.find(r => r.value === sponsor.role)?.label || 'Visiteur'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {sponsor.id === currentUser?.id ? (
                      <div className="flex items-center text-sm text-yellow-600">
                        <AlertTriangle className="h-4 w-4 mr-1" />
                        Votre compte
                      </div>
                    ) : (
                      <select
                        value={sponsor.role}
                        onChange={(e) => handleRoleChange(sponsor.id, e.target.value as UserRole)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm"
                      >
                        {ROLES.map(role => (
                          <option key={role.value} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
import React from 'react';
import { Gift } from 'lucide-react';
import AdminNavigation from '../../components/navigation/AdminNavigation';
import DonationStats from '../../components/donations/DonationStats';
import DonationTable from '../../components/donations/DonationTable';

const AdminDonations = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />
      
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Gestion des Dons</h1>
            <p className="mt-2 text-gray-600">
              Gérez et suivez tous les dons enregistrés
            </p>
          </div>
          <Gift className="h-8 w-8 text-purple-600" />
        </div>
      </div>

      <DonationStats />
      <div className="mt-8">
        <DonationTable isAdmin={true} />
      </div>
    </div>
  );
};

export default AdminDonations;
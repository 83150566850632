import React from 'react';
import AssistantDashboard from '../pages/assistant/AssistantDashboard';
import AssistantChildren from '../pages/assistant/AssistantChildren';
import AssistantDonations from '../pages/assistant/AssistantDonations';
import AssistantMedia from '../pages/assistant/AssistantMedia';

export const assistantRoutes = [
  { path: '/assistant', element: <AssistantDashboard /> },
  { path: '/assistant/children', element: <AssistantChildren /> },
  { path: '/assistant/donations', element: <AssistantDonations /> },
  { path: '/assistant/media', element: <AssistantMedia /> }
];
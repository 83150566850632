import React from 'react';
import { Shield, Users, Gift, MessageSquare, BarChart } from 'lucide-react';
import AdminNavigation from '../../components/navigation/AdminNavigation';

const AdminDashboard = () => {
  const stats = [
    {
      title: 'Total Parrains',
      value: '150',
      icon: <Users className="h-6 w-6 text-purple-600" />
    },
    {
      title: 'Enfants Parrainés',
      value: '120',
      icon: <Shield className="h-6 w-6 text-purple-600" />
    },
    {
      title: 'Dons ce Mois',
      value: '5.2k €',
      icon: <Gift className="h-6 w-6 text-purple-600" />
    },
    {
      title: 'Messages non lus',
      value: '24',
      icon: <MessageSquare className="h-6 w-6 text-purple-600" />
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />
      
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Tableau de Bord Admin</h1>
            <p className="mt-2 text-gray-600">
              Vue d'ensemble des activités et statistiques
            </p>
          </div>
          <BarChart className="h-8 w-8 text-purple-600" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat) => (
          <div key={stat.title} className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center">
              <div className="p-2 bg-purple-100 rounded-lg mr-4">
                {stat.icon}
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">{stat.title}</h3>
                <p className="text-2xl font-bold text-gray-900">{stat.value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Activités Récentes</h2>
          <div className="space-y-4">
            {/* Add recent activities here */}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Tâches en Attente</h2>
          <div className="space-y-4">
            {/* Add pending tasks here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
import React, { useState, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronDown, Upload, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child } from '../../types/database.types';
import AlbumViewer from './AlbumViewer';
import AlbumUploader from './AlbumUploader';
import { usePermissions } from '../../hooks/usePermissions';

interface AlbumManagerProps {
  language?: 'fr' | 'es';
}

const translations = {
  fr: {
    selectChild: "Sélectionner un enfant",
    generalAlbum: "Album général des dons",
    addMedia: "Ajouter des photos/vidéos",
    loadingError: "Erreur lors du chargement des enfants",
    uploadSuccess: "Médias ajoutés avec succès",
    newPhotos: "Nouvelles photos ajoutées",
    photosAddedTo: (name: string) => `De nouvelles photos ont été ajoutées à l'album de ${name}`
  },
  es: {
    selectChild: "Seleccionar un niño",
    generalAlbum: "Álbum general de donaciones",
    addMedia: "Agregar fotos/videos",
    loadingError: "Error al cargar los niños",
    uploadSuccess: "Medios agregados con éxito",
    newPhotos: "Nuevas fotos agregadas",
    photosAddedTo: (name: string) => `Se han agregado nuevas fotos al álbum de ${name}`
  }
};

const AlbumManager: React.FC<AlbumManagerProps> = ({ language = 'fr' }) => {
  const [children, setChildren] = useState<Child[]>([]);
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const [showUploader, setShowUploader] = useState(false);
  const [loading, setLoading] = useState(true);
  const { hasRole } = usePermissions();
  const t = translations[language];

  useEffect(() => {
    fetchChildren();
  }, []);

  const fetchChildren = async () => {
    try {
      const { data: sponsoredChildren, error: childrenError } = await supabase
        .from('children')
        .select(`
          *,
          sponsorships(
            id,
            sponsor:sponsors(
              id,
              name,
              email
            )
          )
        `)
        .eq('status', 'sponsored')
        .order('name');

      if (childrenError) throw childrenError;
      setChildren(sponsoredChildren || []);
      if (sponsoredChildren?.length > 0) {
        setSelectedChild(sponsoredChildren[0]);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(t.loadingError);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadSuccess = async () => {
    if (selectedChild?.sponsorships?.[0]?.sponsor?.id) {
      try {
        await supabase.from('notifications').insert({
          recipient_id: selectedChild.sponsorships[0].sponsor.id,
          type: 'photo_added',
          title: t.newPhotos,
          content: t.photosAddedTo(selectedChild.name),
          link: `/child/${selectedChild.id}`
        });
      } catch (error) {
        console.error('Notification error:', error);
      }
    }
    setShowUploader(false);
    toast.success(t.uploadSuccess);
    fetchChildren();
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-48">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  const canEdit = hasRole('admin') || hasRole('assistant');

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="w-full md:w-64">
          <Listbox value={selectedChild} onChange={setSelectedChild}>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                <span className="block truncate">
                  {selectedChild?.name || t.selectChild}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDown className="h-5 w-5 text-gray-400" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {children.map((child) => (
                  <Listbox.Option
                    key={child.id}
                    value={child}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                      }`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {child.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>

        {canEdit && (
          <button
            onClick={() => setShowUploader(true)}
            className="w-full md:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <Upload className="h-4 w-4 mr-2" />
            {t.addMedia}
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-lg p-4">
        {selectedChild ? (
          <AlbumViewer child={selectedChild} canEdit={canEdit} />
        ) : (
          <AlbumViewer canEdit={canEdit} isDonationAlbum={true} />
        )}
      </div>

      {showUploader && (
        <AlbumUploader
          child={selectedChild}
          isOpen={showUploader}
          onClose={() => setShowUploader(false)}
          onSuccess={handleUploadSuccess}
        />
      )}
    </div>
  );
};

export default AlbumManager;
import React from 'react';
import { Link } from 'react-router-dom';
import { X, Heart, Users, Gift, Film, Shield, MessageSquare, Calendar, Camera } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuthStore } from '../../stores/useAuthStore';
import { usePermissions } from '../../hooks/usePermissions';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
  const { sponsor, isAuthenticated } = useAuthStore();
  const { hasRole } = usePermissions();

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={onClose}
          />
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed inset-y-0 right-0 w-full max-w-sm bg-white shadow-xl z-50 overflow-y-auto"
          >
            <div className="flex flex-col h-full">
              {/* Header */}
              <div className="p-4 border-b border-gray-200 flex items-center justify-between">
                <h2 className="text-lg font-semibold text-gray-900">Menu</h2>
                <button
                  onClick={onClose}
                  className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>

              {/* Navigation Links */}
              <div className="flex-1 px-4 py-6 space-y-1">
                <Link
                  to="/available-children"
                  className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                  onClick={onClose}
                >
                  <Heart className="h-5 w-5 mr-3" />
                  Enfants Disponibles
                </Link>

                <Link
                  to="/sponsored-children"
                  className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                  onClick={onClose}
                >
                  <Users className="h-5 w-5 mr-3" />
                  Enfants Parrainés
                </Link>

                <Link
                  to="/donations"
                  className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                  onClick={onClose}
                >
                  <Gift className="h-5 w-5 mr-3" />
                  Dons
                </Link>

                <Link
                  to="/donation-videos"
                  className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                  onClick={onClose}
                >
                  <Film className="h-5 w-5 mr-3" />
                  Vidéos
                </Link>

                {isAuthenticated && sponsor && (
                  <>
                    <div className="border-t border-gray-200 my-4" />
                    
                    <Link
                      to="/profile"
                      className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                      onClick={onClose}
                    >
                      <Users className="h-5 w-5 mr-3" />
                      Mon Profil
                    </Link>

                    <Link
                      to="/messages"
                      className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                      onClick={onClose}
                    >
                      <MessageSquare className="h-5 w-5 mr-3" />
                      Messages
                    </Link>

                    <Link
                      to="/photos"
                      className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                      onClick={onClose}
                    >
                      <Camera className="h-5 w-5 mr-3" />
                      Photos
                    </Link>

                    <Link
                      to="/birthdays"
                      className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-red-500 hover:bg-red-50 rounded-md"
                      onClick={onClose}
                    >
                      <Calendar className="h-5 w-5 mr-3" />
                      Anniversaires
                    </Link>

                    {hasRole('admin') && (
                      <>
                        <div className="border-t border-gray-200 my-4" />
                        <Link
                          to="/admin"
                          className="flex items-center px-3 py-2 text-base font-medium text-purple-600 hover:text-purple-800 hover:bg-purple-50 rounded-md"
                          onClick={onClose}
                        >
                          <Shield className="h-5 w-5 mr-3" />
                          Administration
                        </Link>
                      </>
                    )}

                    {hasRole('assistant') && (
                      <>
                        <div className="border-t border-gray-200 my-4" />
                        <Link
                          to="/assistant"
                          className="flex items-center px-3 py-2 text-base font-medium text-blue-600 hover:text-blue-800 hover:bg-blue-50 rounded-md"
                          onClick={onClose}
                        >
                          <Shield className="h-5 w-5 mr-3" />
                          Espace Assistant
                        </Link>
                      </>
                    )}
                  </>
                )}

                {!isAuthenticated && (
                  <Link
                    to="/login"
                    className="flex items-center px-3 py-2 text-base font-medium text-red-600 hover:text-red-800 hover:bg-red-50 rounded-md"
                    onClick={onClose}
                  >
                    <Users className="h-5 w-5 mr-3" />
                    Se connecter
                  </Link>
                )}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default MobileMenu;
import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Gift, Film, Users } from 'lucide-react';
import UserMenu from '../navigation/UserMenu';

const Navigation = () => {
  return (
    <nav className="hidden md:flex items-center space-x-4">
      <Link
        to="/available-children"
        className="text-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium flex items-center"
      >
        <Heart className="h-4 w-4 mr-2" />
        Enfants Disponibles
      </Link>
      <Link
        to="/sponsored-children"
        className="text-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium flex items-center"
      >
        <Users className="h-4 w-4 mr-2" />
        Enfants Parrainés
      </Link>
      <Link
        to="/donations"
        className="text-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium flex items-center"
      >
        <Gift className="h-4 w-4 mr-2" />
        Dons
      </Link>
      <Link
        to="/donation-videos"
        className="text-gray-700 hover:text-red-500 px-3 py-2 rounded-md text-sm font-medium flex items-center"
      >
        <Film className="h-4 w-4 mr-2" />
        Vidéos
      </Link>
      <UserMenu />
    </nav>
  );
};

export default Navigation;
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSponsorStore } from '../../stores/useSponsorStore';
import { usePermissions } from '../../hooks/usePermissions';

const AssistantRoute = () => {
  const { isAuthenticated } = useSponsorStore();
  const { hasRole } = usePermissions();

  if (!isAuthenticated || !hasRole('assistant')) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default AssistantRoute;
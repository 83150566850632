import React from 'react';
import { Image } from 'lucide-react';
import AssistantNavigation from '../../components/navigation/AssistantNavigation';
import AlbumManager from '../../components/album/AlbumManager';

const AssistantMedia = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AssistantNavigation />
      
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Gestion des Médias</h1>
            <p className="mt-2 text-gray-600">
              Gérez les photos et vidéos des enfants
            </p>
          </div>
          <Image className="h-8 w-8 text-blue-600" />
        </div>

        <AlbumManager />
      </div>
    </div>
  );
};

export default AssistantMedia;
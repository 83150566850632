import React, { useState, useEffect } from 'react';
import { Film, Plus, Trash2, Star, Search } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import VideoPlayer from '../../components/video/VideoPlayer';
import { usePermissions } from '../../hooks/usePermissions';
import VideoUploader from '../../components/video/VideoUploader';
import AdminNavigation from '../../components/navigation/AdminNavigation';

interface Video {
  id: string;
  url: string;
  title?: string;
  description?: string;
  is_featured: boolean;
  created_at: string;
}

const AdminVideoManagement = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState(true);
  const [showUploader, setShowUploader] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const { hasRole } = usePermissions();
  const canEdit = hasRole('admin') || hasRole('assistant');

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      const { data, error } = await supabase
        .from('donation_videos')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setVideos(data || []);
    } catch (error) {
      console.error('Error fetching videos:', error);
      toast.error('Erreur lors du chargement des vidéos');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (videoId: string) => {
    if (!confirm('Êtes-vous sûr de vouloir supprimer cette vidéo ?')) return;

    try {
      const { error } = await supabase
        .from('donation_videos')
        .delete()
        .eq('id', videoId);

      if (error) throw error;

      setVideos(videos.filter(v => v.id !== videoId));
      toast.success('Vidéo supprimée avec succès');
    } catch (error) {
      console.error('Error deleting video:', error);
      toast.error('Erreur lors de la suppression');
    }
  };

  const toggleFeatured = async (videoId: string, currentValue: boolean) => {
    try {
      const { error } = await supabase
        .from('donation_videos')
        .update({ is_featured: !currentValue })
        .eq('id', videoId);

      if (error) throw error;

      setVideos(videos.map(v =>
        v.id === videoId ? { ...v, is_featured: !currentValue } : v
      ));

      toast.success(
        currentValue ? 'Vidéo retirée de la page d\'accueil' : 'Vidéo ajoutée à la page d\'accueil'
      );
    } catch (error) {
      console.error('Error featuring video:', error);
      toast.error('Erreur lors de la mise à jour');
    }
  };

  const filteredVideos = videos.filter(video =>
    video.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    video.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <AdminNavigation />
      
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Gestion des Vidéos</h1>
          <p className="mt-2 text-gray-600">
            Gérez les vidéos des dons et des parrainages
          </p>
        </div>
        {canEdit && (
          <button
            onClick={() => setShowUploader(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
          >
            <Plus className="h-4 w-4 mr-2" />
            Ajouter une vidéo
          </button>
        )}
      </div>

      <div className="mb-8">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher une vidéo..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredVideos.map((video) => (
          <div key={video.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="relative aspect-video">
              <VideoPlayer
                src={video.url}
                onError={(error) => {
                  console.error('Video playback error:', error);
                  toast.error('Erreur lors de la lecture de la vidéo');
                }}
              />
              {canEdit && (
                <div className="absolute top-2 right-2 flex space-x-2">
                  <button
                    onClick={() => toggleFeatured(video.id, video.is_featured)}
                    className={`p-2 rounded-full ${
                      video.is_featured
                        ? 'bg-yellow-500 text-white'
                        : 'bg-white text-gray-700'
                    }`}
                    title={video.is_featured ? 'Retirer de la page d\'accueil' : 'Ajouter à la page d\'accueil'}
                  >
                    <Star className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(video.id)}
                    className="p-2 bg-white text-red-600 rounded-full hover:bg-red-50"
                    title="Supprimer"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
            <div className="p-4">
              <h3 className="font-medium text-gray-900">{video.title || 'Sans titre'}</h3>
              {video.description && (
                <p className="mt-1 text-sm text-gray-500">{video.description}</p>
              )}
            </div>
          </div>
        ))}
      </div>

      {filteredVideos.length === 0 && (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <Film className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-gray-500">Aucune vidéo trouvée</p>
        </div>
      )}

      {showUploader && (
        <VideoUploader
          isOpen={showUploader}
          onClose={() => setShowUploader(false)}
          onSuccess={() => {
            setShowUploader(false);
            fetchVideos();
          }}
        />
      )}
    </div>
  );
};

export default AdminVideoManagement;
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';

interface VideoUploaderProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  maxSize?: number;
}

// Define allowed MIME types and their extensions
const ALLOWED_VIDEO_TYPES = {
  'video/mp4': ['.mp4'],
  'video/quicktime': ['.mov'],
  'video/x-m4v': ['.m4v']
};

const VideoUploader: React.FC<VideoUploaderProps> = ({
  isOpen,
  onClose,
  onSuccess,
  maxSize = 100 * 1024 * 1024 // 100MB default
}) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;

    setUploading(true);
    setProgress(0);

    try {
      // Create a unique filename with proper extension
      const fileExt = file.name.split('.').pop()?.toLowerCase() || 'mp4';
      const fileName = `${Date.now()}-${Math.random().toString(36).substring(2)}.${fileExt}`;

      // Create FormData to properly set content type
      const formData = new FormData();
      formData.append('file', file);

      // Upload file with proper content type
      const { error: uploadError } = await supabase.storage
        .from('donation-videos')
        .upload(fileName, file, {
          contentType: file.type || 'video/mp4',
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('donation-videos')
        .getPublicUrl(fileName);

      // Create video record
      const { error: dbError } = await supabase
        .from('donation_videos')
        .insert({
          url: publicUrl,
          title: file.name.replace(/\.[^/.]+$/, ''),
          is_featured: false,
          mime_type: file.type || 'video/mp4'
        });

      if (dbError) throw dbError;

      toast.success('Vidéo téléchargée avec succès');
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error uploading video:', error);
      
      let errorMessage = 'Erreur lors du téléchargement de la vidéo';
      if (error.message?.includes('mime type')) {
        errorMessage = 'Format de fichier non supporté (MP4, MOV ou M4V uniquement)';
      } else if (error.message?.includes('too large')) {
        errorMessage = `La vidéo ne doit pas dépasser ${Math.round(maxSize / (1024 * 1024))}MB`;
      }
      
      toast.error(errorMessage);
    } finally {
      setUploading(false);
      setProgress(0);
    }
  }, [onSuccess, onClose, maxSize]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ALLOWED_VIDEO_TYPES,
    maxSize,
    multiple: false,
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        const error = rejection.errors[0];
        if (error.code === 'file-too-large') {
          toast.error(`La vidéo ne doit pas dépasser ${Math.round(maxSize / (1024 * 1024))}MB`);
        } else if (error.code === 'file-invalid-type') {
          toast.error('Format de fichier non supporté (MP4, MOV ou M4V uniquement)');
        } else {
          toast.error('Erreur lors de la sélection du fichier');
        }
      });
    }
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-lg w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900">
            Ajouter une vidéo
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            disabled={uploading}
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="space-y-4">
          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center transition-colors cursor-pointer ${
              isDragActive
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-300 hover:border-blue-500'
            }`}
          >
            <input {...getInputProps()} />
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-600">
              Glissez une vidéo ici ou cliquez pour en sélectionner une
            </p>
            <p className="text-xs text-gray-500 mt-1">
              MP4, MOV ou M4V • Max {Math.round(maxSize / (1024 * 1024))}MB
            </p>
          </div>

          {uploading && (
            <div className="space-y-2">
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-500 transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
              <p className="text-sm text-gray-600 text-center">
                Upload en cours... {Math.round(progress)}%
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoUploader;
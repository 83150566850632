import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Bell, Mail, Phone, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import { useSponsorStore } from '../../stores/useSponsorStore';

const preferencesSchema = z.object({
  default_email_enabled: z.boolean(),
  default_sms_enabled: z.boolean(),
  default_days_before: z.array(z.number()).min(1, 'Sélectionnez au moins un rappel par défaut'),
  default_email: z.string().email('Email invalide').optional().nullable(),
  default_phone: z.string().min(10, 'Numéro de téléphone invalide').optional().nullable(),
  notification_sound: z.boolean(),
  browser_notifications: z.boolean()
});

type PreferencesFormData = z.infer<typeof preferencesSchema>;

interface ReminderPreferencesProps {
  isOpen: boolean;
  onClose: () => void;
}

const REMINDER_OPTIONS = [
  { value: 1, label: '1 jour avant' },
  { value: 3, label: '3 jours avant' },
  { value: 7, label: '1 semaine avant' },
  { value: 14, label: '2 semaines avant' },
  { value: 30, label: '1 mois avant' }
];

const ReminderPreferences: React.FC<ReminderPreferencesProps> = ({
  isOpen,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const { sponsor } = useSponsorStore();
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<PreferencesFormData>({
    resolver: zodResolver(preferencesSchema),
    defaultValues: {
      default_email_enabled: true,
      default_sms_enabled: false,
      default_days_before: [7],
      notification_sound: true,
      browser_notifications: false
    }
  });

  const emailEnabled = watch('default_email_enabled');
  const smsEnabled = watch('default_sms_enabled');

  useEffect(() => {
    if (sponsor) {
      fetchPreferences();
    }
  }, [sponsor]);

  const fetchPreferences = async () => {
    if (!sponsor) return;
    
    try {
      const { data, error } = await supabase
        .from('reminder_preferences')
        .select('*')
        .eq('sponsor_id', sponsor.id)
        .single();

      if (error) {
        if (error.code !== 'PGRST116') throw error; // PGRST116 = not found
        return;
      }

      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          setValue(key as keyof PreferencesFormData, value);
        });
      }
    } catch (error) {
      console.error('Error fetching preferences:', error);
      toast.error('Erreur lors du chargement des préférences');
    }
  };

  const onSubmit = async (data: PreferencesFormData) => {
    if (!sponsor) return;
    setLoading(true);

    try {
      // Use upsert operation to handle both insert and update
      const { error } = await supabase
        .from('reminder_preferences')
        .upsert({
          sponsor_id: sponsor.id,
          ...data,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'sponsor_id'
        });

      if (error) throw error;

      toast.success('Préférences enregistrées avec succès');
      onClose();
    } catch (error) {
      console.error('Error saving preferences:', error);
      toast.error('Erreur lors de la sauvegarde des préférences');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center">
            <Bell className="h-5 w-5 text-red-500 mr-2" />
            Préférences de rappel
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Mail className="h-5 w-5 text-gray-400" />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  Rappels par email par défaut
                </span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register('default_email_enabled')}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-600"></div>
              </label>
            </div>

            {emailEnabled && (
              <input
                type="email"
                {...register('default_email')}
                placeholder="Email par défaut"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              />
            )}

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Phone className="h-5 w-5 text-gray-400" />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  Rappels par SMS par défaut
                </span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register('default_sms_enabled')}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-600"></div>
              </label>
            </div>

            {smsEnabled && (
              <input
                type="tel"
                {...register('default_phone')}
                placeholder="Numéro de téléphone par défaut"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500"
              />
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Rappels par défaut
            </label>
            <div className="space-y-2">
              {REMINDER_OPTIONS.map((option) => (
                <label key={option.value} className="flex items-center">
                  <input
                    type="checkbox"
                    value={option.value}
                    {...register('default_days_before')}
                    className="rounded border-gray-300 text-red-600 focus:ring-red-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    {option.label}
                  </span>
                </label>
              ))}
            </div>
            {errors.default_days_before && (
              <p className="mt-1 text-sm text-red-600">{errors.default_days_before.message}</p>
            )}
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium text-gray-700">
                Son de notification
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register('notification_sound')}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-600"></div>
              </label>
            </div>

            <div className="flex items-center justify-between">
              <span className="text-sm font-medium text-gray-700">
                Notifications du navigateur
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  {...register('browser_notifications')}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-600"></div>
              </label>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              disabled={loading}
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md"
            >
              {loading ? 'Enregistrement...' : 'Enregistrer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReminderPreferences;
import { z } from 'zod';

export const sponsorshipSchema = z.object({
  name: z.string().min(2, 'Le nom doit contenir au moins 2 caractères'),
  city: z.string().min(2, 'La ville est requise'),
  phone: z.string().min(10, 'Le numéro de téléphone doit contenir au moins 10 chiffres'),
  facebook_url: z.string()
    .min(1, 'Le profil Facebook est requis')
    .regex(/facebook\.com/, 'Doit être une URL Facebook valide'),
  email: z.string().email('Email invalide').optional().or(z.literal('')),
  is_anonymous: z.boolean().default(false),
  show_name_publicly: z.boolean().default(true)
});
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X, Crop, Save } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface MediaItem {
  id: string;
  url: string;
  type: string;
  metadata: any;
  version: number;
}

const MediaManager = () => {
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<MediaItem | null>(null);
  const [cropper, setCropper] = useState<any>(null);

  useEffect(() => {
    fetchMedia();
  }, []);

  const fetchMedia = async () => {
    try {
      const { data, error } = await supabase
        .from('media_items')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setMedia(data || []);
    } catch (error) {
      console.error('Error fetching media:', error);
      toast.error('Erreur lors du chargement des médias');
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxSize: 5 * 1024 * 1024,
    onDrop: async (acceptedFiles) => {
      try {
        for (const file of acceptedFiles) {
          const fileName = `${Date.now()}-${file.name}`;
          const { error: uploadError } = await supabase.storage
            .from('media')
            .upload(fileName, file);

          if (uploadError) throw uploadError;

          const { data: { publicUrl } } = supabase.storage
            .from('media')
            .getPublicUrl(fileName);

          const { error: dbError } = await supabase
            .from('media_items')
            .insert({
              url: publicUrl,
              type: file.type,
              metadata: {
                size: file.size,
                lastModified: file.lastModified
              },
              version: 1
            });

          if (dbError) throw dbError;
        }

        fetchMedia();
        toast.success('Médias ajoutés avec succès');
      } catch (error) {
        console.error('Error uploading media:', error);
        toast.error('Erreur lors du téléchargement');
      }
    }
  });

  const handleCrop = async () => {
    if (!cropper || !selectedItem) return;

    try {
      const canvas = cropper.getCroppedCanvas({
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: '#fff',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });

      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((b) => resolve(b!), 'image/jpeg', 0.9);
      });

      const fileName = `cropped-${Date.now()}.jpg`;
      const { error: uploadError } = await supabase.storage
        .from('media')
        .upload(fileName, blob);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('media')
        .getPublicUrl(fileName);

      const { error: updateError } = await supabase
        .from('media_items')
        .update({
          url: publicUrl,
          metadata: {
            ...selectedItem.metadata,
            cropData: cropper.getData(),
            edited: true
          },
          version: selectedItem.version + 1
        })
        .eq('id', selectedItem.id);

      if (updateError) throw updateError;

      setSelectedItem(null);
      fetchMedia();
      toast.success('Image recadrée avec succès');
    } catch (error) {
      console.error('Error cropping image:', error);
      toast.error('Erreur lors du recadrage');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-purple-500 transition-colors cursor-pointer"
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-600">
          Glissez des fichiers ici ou cliquez pour en sélectionner
        </p>
      </div>

      {selectedItem ? (
        <div className="space-y-4">
          <Cropper
            src={selectedItem.url}
            style={{ height: 400, width: '100%' }}
            aspectRatio={1}
            guides={true}
            viewMode={1}
            dragMode="move"
            scalable={true}
            cropBoxMovable={true}
            cropBoxResizable={true}
            onInitialized={(instance) => setCropper(instance)}
          />
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setSelectedItem(null)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
            >
              Annuler
            </button>
            <button
              onClick={handleCrop}
              className="px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-md"
            >
              <Save className="h-4 w-4 mr-2 inline-block" />
              Appliquer
            </button>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {media.map((item) => (
            <div key={item.id} className="relative group">
              <img
                src={item.url}
                alt=""
                className="w-full aspect-square object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-opacity flex items-center justify-center space-x-2 opacity-0 group-hover:opacity-100">
                <button
                  onClick={() => setSelectedItem(item)}
                  className="p-2 bg-white rounded-full text-purple-600 hover:text-purple-700"
                  title="Recadrer"
                >
                  <Crop className="h-5 w-5" />
                </button>
                <button
                  onClick={() => {
                    if (confirm('Êtes-vous sûr de vouloir supprimer ce média ?')) {
                      supabase
                        .from('media_items')
                        .delete()
                        .eq('id', item.id)
                        .then(() => {
                          setMedia(media.filter(m => m.id !== item.id));
                          toast.success('Média supprimé avec succès');
                        })
                        .catch((error) => {
                          console.error('Error deleting media:', error);
                          toast.error('Erreur lors de la suppression');
                        });
                    }
                  }}
                  className="p-2 bg-white rounded-full text-red-600 hover:text-red-700"
                  title="Supprimer"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MediaManager;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Heart, Star, Image as ImageIcon, MapPin, Calendar, User, Info, Book, Gift, ShoppingBag, Apple, Pill, Droplets } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import type { Child } from '../types/database.types';
import PhotoAlbum from '../components/album/PhotoAlbum';
import TestimonialList from '../components/testimonials/TestimonialList';
import { SponsorshipRequestModal } from '../components/sponsorship/SponsorshipRequestModal';
import ShareButton from '../components/shared/ShareButton';
import { calculateAge } from '../utils/dateUtils';

const ChildProfile = () => {
  const { id } = useParams<{ id: string }>();
  const [child, setChild] = useState<Child | null>(null);
  const [loading, setLoading] = useState(true);
  const [showSponsorshipModal, setShowSponsorshipModal] = useState(false);

  useEffect(() => {
    if (id) {
      fetchChild();
    }
  }, [id]);

  const fetchChild = async () => {
    try {
      const { data, error } = await supabase
        .from('children')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      setChild(data);
    } catch (error) {
      console.error('Error fetching child:', error);
      toast.error('Erreur lors du chargement des informations');
    } finally {
      setLoading(false);
    }
  };

  const getNeedIcon = (category: string) => {
    switch (category) {
      case 'education': return <Book className="h-5 w-5 text-blue-500" />;
      case 'jouet': return <Gift className="h-5 w-5 text-purple-500" />;
      case 'vetement': return <ShoppingBag className="h-5 w-5 text-green-500" />;
      case 'nourriture': return <Apple className="h-5 w-5 text-red-500" />;
      case 'medicament': return <Pill className="h-5 w-5 text-pink-500" />;
      case 'hygiene': return <Droplets className="h-5 w-5 text-cyan-500" />;
      default: return null;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-havana-500"></div>
      </div>
    );
  }

  if (!child) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">
          <p className="text-gray-500">Enfant non trouvé</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-havana-50 to-tobacco-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main Profile Card */}
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden mb-8">
          <div className="md:flex">
            {/* Photo Section */}
            <div className="md:w-1/3 bg-gradient-to-br from-havana-400 to-tobacco-400 p-6">
              <div className="relative aspect-[3/4]">
                <img
                  src={child.photo_url || 'https://via.placeholder.com/800x1200'}
                  alt={child.name}
                  className="absolute inset-0 w-full h-full object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>

            {/* Info Section */}
            <div className="md:w-2/3 p-6 md:p-8">
              <div className="flex flex-col h-full">
                {/* Basic Info */}
                <div className="mb-6">
                  <div className="flex justify-between items-start">
                    <h1 className="text-3xl font-bold text-gray-900">{child.name}</h1>
                    <ShareButton
                      url={window.location.href}
                      title={`Aidez ${child.name}, ${calculateAge(child.birth_date)} ans, à trouver un parrain`}
                      description={`${child.name} attend votre soutien. Ensemble, nous pouvons faire la différence dans la vie d'un enfant cubain.`}
                      hashtags={['TousPourCuba', 'ParrainageCuba', 'AidezUnEnfant']}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center text-gray-600">
                      <User className="h-5 w-5 mr-2 text-havana-500" />
                      <span>{calculateAge(child.birth_date)} ans • {child.gender === 'male' ? 'Garçon' : 'Fille'}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <MapPin className="h-5 w-5 mr-2 text-havana-500" />
                      <span>{child.city}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Calendar className="h-5 w-5 mr-2 text-havana-500" />
                      <span>Né(e) le {format(new Date(child.birth_date), 'PPP', { locale: fr })}</span>
                    </div>
                    <div className="flex items-center">
                      <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                        child.status === 'sponsored' ? 'bg-green-100 text-green-800' :
                        child.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {child.status === 'sponsored' ? 'Parrainé' :
                         child.status === 'pending' ? 'En attente' : 'Disponible'}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Description */}
                {child.description && (
                  <div className="mb-6 p-4 bg-havana-50 rounded-lg">
                    <div className="flex items-start">
                      <Info className="h-5 w-5 text-havana-500 mt-1 mr-3" />
                      <p className="text-havana-800">{child.description}</p>
                    </div>
                  </div>
                )}

                {/* Story */}
                {child.story && (
                  <div className="mb-6 p-4 bg-tobacco-50 rounded-lg">
                    <h3 className="text-lg font-semibold text-tobacco-800 mb-2">Histoire</h3>
                    <p className="text-tobacco-700">{child.story}</p>
                  </div>
                )}

                {/* Comments */}
                {child.comments && (
                  <div className="mb-6 p-4 bg-colonial-50 rounded-lg">
                    <h3 className="text-lg font-semibold text-colonial-800 mb-2">Commentaires</h3>
                    <p className="text-colonial-700">{child.comments}</p>
                  </div>
                )}

                {/* Needs Section */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">Besoins</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {Array.isArray(child.needs) && child.needs.map((need, index) => (
                      <div
                        key={index}
                        className="flex items-center p-4 bg-sunset-50 rounded-lg border border-sunset-100"
                      >
                        <div className="flex-shrink-0 mr-3">
                          {getNeedIcon(need.category)}
                        </div>
                        <div>
                          <p className="font-medium text-sunset-900">
                            {need.category}
                          </p>
                          {need.description && (
                            <p className="text-sm text-sunset-700">{need.description}</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Sponsorship Button */}
                {child.status === 'available' && (
                  <div className="mt-auto">
                    <button
                      onClick={() => setShowSponsorshipModal(true)}
                      className="w-full md:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg bg-tobacco-400 hover:bg-tobacco-500 text-white transition-colors"
                    >
                      <Heart className="h-5 w-5 mr-2" />
                      Parrainer {child.name}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Photos Section - Only shown for sponsored children */}
        {child.status === 'sponsored' && (
          <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-xl p-6 mb-8">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Photos</h2>
                <p className="mt-1 text-sm text-gray-500">
                  Découvrez les moments de vie de {child.name}
                </p>
              </div>
            </div>
            <PhotoAlbum child={child} />
          </div>
        )}

        {/* Testimonials Section - Only shown for sponsored children */}
        {child.status === 'sponsored' && (
          <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-xl p-6">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">Témoignages</h2>
                <p className="mt-1 text-sm text-gray-500">
                  Ce que disent les parrains et marraines
                </p>
              </div>
            </div>
            <TestimonialList childId={child.id} />
          </div>
        )}

        {/* Sponsorship Modal */}
        {showSponsorshipModal && (
          <SponsorshipRequestModal
            child={child}
            isOpen={showSponsorshipModal}
            onClose={() => setShowSponsorshipModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ChildProfile;
import React, { useState } from 'react';
import { Edit2, Trash2, Book, Gift, ShoppingBag, Apple, Pill, Droplets, HelpCircle, Camera, Calendar, MapPin } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { toast } from 'sonner';
import type { Child, Need } from '../../types/database.types';
import { DEFAULT_PROFILE_IMAGE } from '../../constants/images';
import NeedsList from '../needs/NeedsList';
import PhotoUpload from '../shared/PhotoUpload';
import AlbumViewer from '../album/AlbumViewer';
import AddChildModal from './AddChildModal';

interface ChildDetailsProps {
  child: Child;
  onUpdate: (updatedChild: Child) => void;
  onDelete: (childId: string) => void;
}

const ChildDetails: React.FC<ChildDetailsProps> = ({
  child,
  onUpdate,
  onDelete
}) => {
  console.log("Initial render of ChildDetails with child:", child);
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditClick = () => {
    console.log("Edit button clicked");
    setShowEditModal(true);
    console.log("ShowEditModal set to:", true);
  };

  const handleNeedsUpdate = async (updatedNeeds: Need[]) => {
    try {
      const { error } = await supabase
        .from('child_needs')
        .upsert(
          updatedNeeds.map(need => ({
            child_id: child.id,
            category: need.category,
            description: need.description,
            is_urgent: need.is_urgent
          }))
        );

      if (error) throw error;

      onUpdate({
        ...child,
        needs: updatedNeeds
      });

      toast.success('Besoins mis à jour avec succès');
    } catch (error) {
      console.error('Error updating needs:', error);
      toast.error('Erreur lors de la mise à jour des besoins');
    }
  };

  const handlePhotoSuccess = async (photoUrl: string) => {
    try {
      const { error } = await supabase
        .from('children')
        .update({ photo_url: photoUrl })
        .eq('id', child.id);

      if (error) throw error;

      onUpdate({
        ...child,
        photo_url: photoUrl
      });

      setShowPhotoUpload(false);
      toast.success('Photo mise à jour avec succès');
    } catch (error) {
      console.error('Error updating photo:', error);
      toast.error('Erreur lors de la mise à jour de la photo');
    }
  };

  const handleSaveChild = async (updatedData: Partial<Child>) => {
    console.log("Saving child with data:", updatedData);
    try {
      const { error } = await supabase
        .from('children')
        .update(updatedData)
        .eq('id', child.id);

      if (error) throw error;

      onUpdate({
        ...child,
        ...updatedData
      });

      setShowEditModal(false);
      toast.success('Enfant mis à jour avec succès');
    } catch (error) {
      console.error('Error updating child:', error);
      toast.error('Erreur lors de la mise à jour');
      throw error;
    }
  };

  const handleCloseModal = () => {
    console.log("Closing edit modal");
    setShowEditModal(false);
  };

  console.log("Current showEditModal state:", showEditModal);

  return (
    <>
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-6">
              <div className="relative">
                <img
                  src={child.photo_url || DEFAULT_PROFILE_IMAGE}
                  alt={child.name}
                  className="h-24 w-24 rounded-lg object-cover"
                />
                <button
                  onClick={() => setShowPhotoUpload(true)}
                  className="absolute -bottom-2 -right-2 p-2 bg-white rounded-full shadow-md hover:bg-gray-100"
                >
                  <Camera className="h-4 w-4 text-gray-600" />
                </button>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  {child.name}
                </h3>
                <div className="mt-1 space-y-1">
                  <p className="text-gray-600 flex items-center">
                    <Calendar className="h-4 w-4 mr-2 text-gray-400" />
                    {new Date().getFullYear() - new Date(child.birth_date).getFullYear()} ans
                  </p>
                  <p className="text-gray-600 flex items-center">
                    <MapPin className="h-4 w-4 mr-2 text-gray-400" />
                    {child.city}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <button
                onClick={handleEditClick}
                className="p-2 text-blue-600 hover:text-blue-800 bg-blue-100 rounded-full"
                title="Modifier"
              >
                <Edit2 className="h-5 w-5" />
              </button>
              <button
                onClick={() => onDelete(child.id)}
                className="p-2 text-red-600 hover:text-red-800 bg-red-100 rounded-full"
                title="Supprimer"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          </div>

          {child.description && (
            <div className="mt-6">
              <h4 className="text-sm font-medium text-gray-700 mb-2">Description :</h4>
              <p className="text-gray-600">{child.description}</p>
            </div>
          )}

          {child.story && (
            <div className="mt-6">
              <h4 className="text-sm font-medium text-gray-700 mb-2">Histoire :</h4>
              <p className="text-gray-600 whitespace-pre-line">{child.story}</p>
            </div>
          )}

          <div className="mt-6">
            <h4 className="text-sm font-medium text-gray-700 mb-2">Besoins :</h4>
            <NeedsList
              childId={child.id}
              needs={child.needs}
              onUpdate={handleNeedsUpdate}
            />
          </div>

          <div className="mt-6">
            <h4 className="text-sm font-medium text-gray-700 mb-2">Album photos :</h4>
            <AlbumViewer child={child} canEdit={true} />
          </div>
        </div>
      </div>

      {showPhotoUpload && (
        <PhotoUpload
          currentPhotoUrl={child.photo_url}
          onPhotoSelect={handlePhotoSuccess}
          onClose={() => setShowPhotoUpload(false)}
        />
      )}

      {showEditModal && (
        <AddChildModal
          child={child}
          onClose={handleCloseModal}
          onSave={handleSaveChild}
        />
      )}
    </>
  );
};

export default ChildDetails;